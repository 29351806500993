import { Component, OnInit } from '@angular/core';
import { LedgerService } from 'src/app/services/ledger.service';
import { HeaderComponent } from 'src/app/dashboard/header/header.component';
import { Router } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-ledger',
  templateUrl: './view-ledger.component.html',
  styleUrls: ['./view-ledger.component.css']
})
export class ViewLedgerComponent implements OnInit {

  showNLedger: FormGroup;
  searchPartyForm: FormGroup;
  searchLedgerTypeForm: FormGroup;
  collection: any;
  pageNo:number = 0;
  pageSize:number = 25;
  count:number = 0
  partyNameList: any = [];

  ledger_name:string = '';
  ledger_ledgertype_id: number = 0;
  ledger_id: number = 0;

  pageData = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    ledger_name: this.ledger_name,
    ledger_ledgertype_id: this.ledger_ledgertype_id
  }

  constructor(
    private viewLedgerService: LedgerService,
    private router: Router,
    private fb : FormBuilder,
    private toastr: ToastrService) {
      this.loadPartyName();
     }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
    this.showNLedger = this.fb.group({
      selectNLedger : ['25']
    })
    this.searchPartyForm = this.fb.group({
      partyList : ['']
    })
    this.searchLedgerTypeForm = this.fb.group({
      ledgerType : ['0']
    })
    this.getLedgerData();
  }
  //loading party names in partyList dropdown on component loading
  loadPartyName() {
    this.viewLedgerService.getPartyName().subscribe((data: any) => {
      this.partyNameList = data;
    })
  }

  getLedgerData() {
    this.viewLedgerService.getPaginatedLedger(this.pageData).subscribe((result) => {
      this.count = result['count']
      this.collection = result['rows'];
      // console.warn(result);
      // this.collection = result
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
        this.router.navigateByUrl('/dashboard');
      }
    })
  }

  SearchL() {
    if (this.ledger_name == "") {
      this.ngOnInit();
    } else {
      // this.collection = this.collection.filter(res => {
      //   console.log(this.ledger_name.toLocaleLowerCase())
      //   return res.ledger_name.toLocaleLowerCase().match(this.ledger_name.toLocaleLowerCase());
      // })
      this.pageData.ledger_name = this.ledger_name;
      this.getLedgerData()
    }
  }

  public lcUpdateLedger(item) {
    this.viewLedgerService.lid = item;
    this.router.navigateByUrl('/dashboard/updateLedger');
  }

  //onClick pagination function
  handlePageChange(event) {
    console.log(event)
    this.pageNo = event;
    this.pageData.pageNo = this.pageNo-1;
    this.getLedgerData();
  }

  handlePageSize(val: Int16Array) {
    console.log(val)
    this.pageSize = Number(val);
    this.pageData.pageSize = this.pageSize;
    this.getLedgerData();
  }

  // partyCall() {
  //   // console.log(val);
  //   this.pageData.ledger_name = this.ledger_name;
  //   this.getLedgerData()
  //   // this.pageData.c_party_id = val
  // }

  ledgerTypeCall(val: Int16Array) {
    this.pageData.ledger_ledgertype_id = Number(val);
    this.getLedgerData();
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  // fake data for temporary deletion
  fakeData = {
    ledger_status: 0,
    ledger_is_deleted: 1
  }
  
  // deleting a Ledger
  deleteLedger(lid) {
    if(confirm("Are you sure to delete ")){
      this.viewLedgerService.doDeleteLedger(lid, this.fakeData).subscribe(result => {
        if (result['status']=== 200) {
          this.showSuccess(result['message']);
          window.location.reload(); 
        }
      }, error => {
        if (error.status === 500) {
          this.showError(error.error.message);
          window.location.reload(); 
        }
        if (error.status === 400) {
          this.showError(error.error);
        }
      })
      this.getLedgerData();
    }
    
  }

}
