import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class AppInterceptorService implements HttpInterceptor {
    constructor(
      private toastr: ToastrService,
      private router: Router,
      private authService: AuthenticationService) {
        this.handleError = this.handleError.bind(this);
      }

    
    logout() {
      this.authService.logout();
      this.router.navigate(["login"]);
    }

    private handleError(errorResponse: HttpErrorResponse) {
        if (errorResponse instanceof HttpErrorResponse) {
          //server side error
          // this.showError(errorResponse.statusText);
          if (errorResponse.status === 401) {
            this.logout(); // to call this function parent function should be bind
            this.showError(errorResponse.error.message);
          }
          console.log(errorResponse)
        } else {
          //client side error
          console.log(errorResponse)
          // this.showError(errorResponse);
        }
        return throwError(errorResponse);
    }

    //Toastr Starts
    showError(messageNotify: string) {
      this.toastr.error('Error!', messageNotify);
    }
    //Toastr Ends

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                catchError(this.handleError)
            );
    }
}