import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UnitsService } from 'src/app/services/units.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-add-units',
  templateUrl: './add-units.component.html',
  styleUrls: ['./add-units.component.css']
})
export class AddUnitsComponent implements OnInit {

  addUnits: FormGroup;
  alert: boolean = false
  public imagePath;
  imgURL: any;
  public message: string;

  userNamesList:any = [];
  stateNamesList:any = [];

  //for image setting
  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private unitService: UnitsService,
    private userService: UsersService,
    private toastr: ToastrService) { }
  getValues() {
    // console.warn(this.addUnits.value);
    this.unitService.saveUnits(this.addUnits.value).subscribe((result) => {
      if (result['status']=== 200) {
        this.showSuccess(result['message']);
        window.location.reload(); 
      }
      if (result['status']=== 208) {
        this.showWarning(result['message']);
        window.location.reload(); 
      }
      console.log(result)
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
        window.location.reload(); 
      }
    })
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showWarning(messageNotify: string) {
    this.toastr.warning('Failed!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  closeAlert() {
    this.alert = false
  }

  userNamesCall() {
    this.userService.getIdAndUsername().subscribe(result => {
      this.userNamesList = result;
    })
  }

  stateNamesCall() {
    this.unitService.getStatesName().subscribe(result => {
      this.stateNamesList = result;
    })
  }

  ngOnInit(): void {

    this.addUnits = this.fb.group({
      add_photo: [''],
      user: [''],
      unit_name: ['', Validators.required],
      address_1: ['', Validators.required],
      address_2: [''],
      address_3: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postcode: [''],
      com_email: [''],
      contact_person: ['', Validators.required],
      mobile_1: ['', Validators.required],
      mobile_2: [''],
      pan_no: [''],
      gst: [''],
      bank_name: [''],
      branch_name: [''],
      account_no: [''],
      ifsc_code: [''],
    });

    this.userNamesCall();
    this.stateNamesCall();
  }
  //for vaildators
  // get add_photo() { return this.addUsers.get('add_photo') };
  // get user() { return this.addUnits.get('user') };
  get unit_name() { return this.addUnits.get('unit_name') };
  get address_1() { return this.addUnits.get('address_1') };
  // get address_2() { return this.addUnits.get('address_2') };
  // get address_3() { return this.addUnits.get('address_3') };
  get city() { return this.addUnits.get('city') };
  get state() { return this.addUnits.get('state') };
  // get postcode() { return this.addUnits.get('postcode') };
  // get com_email() { return this.addUnits.get('com_email') };
  get contact_person() { return this.addUnits.get('contact_person') };
  get mobile_1() { return this.addUnits.get('mobile_1') };
  // get mobile_2() { return this.addUnits.get('mobile_2') };
  // get pan_no() { return this.addUnits.get('pan_no') };
  // get gst() { return this.addUnits.get('gst') };
  // get bank_name() { return this.addUnits.get('bank_name') };
  // get branch_name() { return this.addUnits.get('branch_name') };
  // get account_no() { return this.addUnits.get('account_no') };
  // get ifsc_code() { return this.addUnits.get('ifsc_code') };

}
