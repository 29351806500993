<breadcrumb #parent>
  <ol class="breadcrumb">
    <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
      <li *ngIf="!route.terminal" class="breadcrumb-item">
        <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]"
          style="color: black;">&nbsp;{{ route.displayName }}</a>
      </li>
      <li *ngIf="route.terminal" class="breadcrumb-item active">
        {{ route.displayName}}
      </li>
    </ng-template>
  </ol>
</breadcrumb>


<div class="container">
  <!-- success alert message start -->
  <div *ngIf="alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Success</strong> Your data has been entered.
    <button (click)="closeAlert()" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- success alert message end -->

  <div class="container-fluid" style="border: 1px solid lightgrey;">
    <form [formGroup]="setting" (ngSubmit)="getValues()">
      <!--------------------------form heading------------------------->
      <div class="panel panel-default">
        <div class="panel-body pull-left">
          <!-- <button mat-flat-button disabled btn-block style="background-color: white; color: black;">
                  <mat-icon>search</mat-icon>&nbsp; search for the mislleneous report
              </button> -->
          <mat-icon [inline]="true">settings</mat-icon><span style="color: gray; font-size: larger;">Change
            Password</span>
          <hr style="padding-top: 0; margin-top: 0;">
          <!-- <h3 class="panel-title">search for the mislleneous report</h3> -->
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-3">
          <label id="labelcolor">Old Password</label><br>
        </div>
        <div class="form-group col-md-8">
          <input type="password" class="form-control" pattern=".{6,}" required title="6 characters minimum"
            formControlName="old_password" required />
          <span class="error" *ngIf="old_password.invalid && old_password.touched">
            <span *ngIf="old_password.errors.required"> Old password is required. </span>
            <span *ngIf="old_password.errors.pattern"> Must contain at least 6 or more characters.</span>
          </span>
        </div>

        <div class="form-group col-md-3">
          <label>New Password</label><br>
        </div>
        <div class="form-group col-md-8">
          <input type="password" class="form-control" pattern=".{6,}" required title="6 characters minimum"
            formControlName="new_password" />
          <span class="error" *ngIf="new_password.invalid && new_password.touched">
            <span *ngIf="new_password.errors.required"> New password is required. </span>
            <span *ngIf="new_password.errors.pattern"> Must contain at least 6 or more characters.</span>
          </span>
        </div>
        <div class="form-group col-md-3">
          <label>Repeat Password</label><br>
        </div>
        <div class="form-group col-md-8">
          <input type="password" class="form-control" pattern="{{ new_password.value }}" required
            title="6 characters minimum" formControlName="repeat_password" />
          <span class="error" *ngIf="repeat_password.invalid && repeat_password.touched">
            <span *ngIf="repeat_password.errors.required"> Repeat password is required. </span>
            <span *ngIf="repeat_password.errors.pattern"> Password & Repeat Password does not match.</span>
          </span>
        </div>
        <div class="form-group col-md-3"></div>
        <div class="form-group col-md-4">
          <button class="btn btn-primary">Update</button>
          <button type="reset" class="btn btn-danger" placeholder="Reset">Reset</button>
        </div>
      </div>
    </form>
  </div>
</div>
