import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LedgerService } from 'src/app/services/ledger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-ledger',
  templateUrl: './add-ledger.component.html',
  styleUrls: ['./add-ledger.component.css']
})
export class AddLedgerComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;

  public imagePath;
  imgURL: any;
  public message: string;
  show:boolean = false;
  showl:boolean = false;

  constructor(
    private ledgerService: LedgerService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.addLedger = this.fb.group({
      add_photo: [''],
      ledger_name: ['', Validators.required],
      ledger_type: ['', Validators.required],
      address_1: [''],
      address_2: [''],
      address_3: [''],
      address_4: [''],
      driver_name: [''],
      mobile: ['', [Validators.pattern('[0-9]*')]],
      mobile2: ['', [Validators.pattern('[0-9]*')]],
      mobile3: ['', [Validators.pattern('[0-9]*')]],
      mobile4: ['', [Validators.pattern('[0-9]*')]],
      gstin: [''],
    })

    this.addLedger.controls.ledger_type.valueChanges.subscribe(result=>{
      if (this.addLedger.controls.ledger_type.value == 2){
        this.show = true;
        this.showl = false;
      } else {
        this.show = false;
        this.showl = true;
      }
    })
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showWarning(messageNotify: string) {
    this.toastr.warning('Failed!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  //for image setting
  preview(event) {
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      // reader.readAsDataURL(file);
      // reader.onload = () => {
      //   this.addLedger.get('add_photo').setValue()
      // };
      console.log(file)
      this.addLedger.get('add_photo').setValue(file)
    }
  }

  //for image setting
  // preview(files) {
  //   if (files.length === 0)
  //     return;
  //   var mimeType = files[0].type;
  //   if (mimeType.match(/image\/*/) == null) {
  //     this.message = "Only images are supported.";
  //     return;
  //   }
  //   var reader = new FileReader();
  //   this.imagePath = files;
  //   console.log(this.imagePath)
  //   reader.readAsDataURL(files[0]);
  //   reader.onload = (_event) => {
  //     this.imgURL = reader.result;
  //   }
  // }

  addLedger: FormGroup;
  alert: boolean = false

  saveValues() {
    const formData = new FormData();
    formData.append('add_photo', this.addLedger.get('add_photo').value);
    formData.append('ledger_name', this.addLedger.get('ledger_name').value);
    formData.append('ledger_type', this.addLedger.get('ledger_type').value);
    formData.append('address_1', this.addLedger.get('address_1').value);
    formData.append('address_2', this.addLedger.get('address_2').value);
    formData.append('address_3', this.addLedger.get('address_3').value);
    formData.append('address_4', this.addLedger.get('address_4').value);
    formData.append('driver_name', this.addLedger.get('driver_name').value);
    formData.append('mobile', this.addLedger.get('mobile').value);
    formData.append('mobile2', this.addLedger.get('mobile2').value);
    formData.append('mobile3', this.addLedger.get('mobile3').value);
    formData.append('mobile4', this.addLedger.get('mobile4').value);
    formData.append('gstin', this.addLedger.get('gstin').value);
    console.warn(formData);
    this.ledgerService.saveLedger(formData).subscribe((result) => {
      // this.alert = true
      if (result['status']=== 200) {
        this.showSuccess(result['message']);
         window.location.reload(); 
      }
      if (result['status']=== 208) {
        this.showWarning(result['message']);
        window.location.reload(); 
      }
      console.log(result)
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
        window.location.reload(); 
      }
    })
  }
  closeAlert() {
    this.alert = false
  }

  //for vaildators
  // get add_photo() { return this.addLedger.get('add_photo') };
  get ledger_name() { return this.addLedger.get('ledger_name') };
  get ledger_type() { return this.addLedger.get('ledger_type') };
  // get address_1() { return this.addLedger.get('address_1') };
  // get address_2() { return this.addLedger.get('address_2') };
  // get driver_name() { return this.addLedger.get('driver_name') };
  // get mobile() { return this.addLedger.get('mobile') };
  // get mobile2() { return this.addLedger.get('mobile2') };
  // get mobile3() { return this.addLedger.get('mobile3') };
  // get mobile4() { return this.addLedger.get('mobile4') };
  // get gstin() { return this.addLedger.get('gstin') };

}
