<breadcrumb #parent>
  <ol class="breadcrumb">
      <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <li *ngIf="!route.terminal" class="breadcrumb-item">
            <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]" style="color: black;">&nbsp;{{ route.displayName }}</a>
          </li>
          <li *ngIf = "route.terminal" class="breadcrumb-item active">
              {{ route.displayName}}
          </li>
      </ng-template>
  </ol>
</breadcrumb>

<div class="container">
  <h4>Update Product</h4>

  <!-- success alert message start -->
  <div *ngIf="alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Success</strong> Your data has been entered.
    <button (click)="closeAlert()" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- success alert message end -->

  <div class="container-fluid" style="border: 1px solid rgb(112, 105, 105); border-radius: 2px;">
    <form [formGroup]="updateProduct" (ngSubmit)="getValues()">
      <div class="panel panel-primary" style="color: blue;">
        <div class="panel-heading">
          <h3 class="panel-title">Update Product</h3>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-3">
          <label id="labelcolor">Code *</label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="code" required readonly/>
          <span class="error" *ngIf="code.invalid && code.touched">Please enter a valid code</span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Name *</label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="name" required />
          <span class="error" *ngIf="name.invalid && name.touched">Please enter a valid name</span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Product Rate *</label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="product_rate" required />
          <span class="error" *ngIf="product_rate.invalid && product_rate.touched">Please enter a valid
            product_rate</span>
        </div>
        <div class="col-md-3"></div>
        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
          <div class="btn-group" role="group" aria-label="First group">
            <button class="btn btn-primary" [disabled]="updateProduct.invalid" data-toggle="tooltip"
              data-placement="top" title="">
              Update
            </button>
            &nbsp; &nbsp;
            <!-- <button type="submit" [disabled]="updateProduct.invalid" class="btn btn-secondary"
              placeholder="Submit & Print">
              Submit & Print
            </button> -->
            &nbsp; &nbsp;
            <button class="btn btn-danger" mat-flat-button [routerLink]="['../viewProduct']">Cancel</button>
          </div>
        </div>
        <small style="color: red;">Submit button will be enabled when all the mandatory fields are
          filled.</small>
      </div>

    </form>
  </div>
</div>
