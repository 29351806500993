<breadcrumb #parent>
  <ol class="breadcrumb">
      <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <li *ngIf="!route.terminal" class="breadcrumb-item">
            <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]" style="color: black;">&nbsp;{{ route.displayName }}</a>
          </li>
          <li *ngIf = "route.terminal" class="breadcrumb-item active">
              {{ route.displayName}}
          </li>
      </ng-template>
  </ol>
</breadcrumb>

<div class="container">
  <h1 class="mat-h1">Ledger</h1>
  <p class="mat-caption">All registered Products</p>
  <div style="display: flex; float: right;">
    <form [formGroup]="showNLedger" class="form-group col-xs-2 float-right">
      <!-- <label for="exampleFormControlSelect1">Example select</label> -->
      <select class="form-control" id="showLedgerNum" formControlName = "selectNLedger" (ngModelChange)="handlePageSize($event)">
        <option value="25" >25</option>
        <option value="50">50</option>
        <option value="75">75</option>
        <option value="100">100</option>
      </select>
  </form>
  &nbsp;&nbsp;&nbsp;
  <button mat-flat-button class="btn float-right" style="background-color: coral; height: 40px; border-radius: 10px;" [routerLink]="['../addLedger']">Add Ledger</button>
  </div>  
  <hr>
  <!-- <button mat-flat-button class="btn float-right" style="background-color: coral;" [routerLink]="['../addLedger']">Add Ledger</button> -->
  
  <!-- <hr> -->

  <table class="table">
    <thead>
      <tr>
        <th scope="col">SNo.</th>
        <th scope="col">Ledger Name</th>
        <th scope="col">Ledger Type</th>
        <th scope="col">Creation Date</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td></td>
        <td>
          <form [formGroup]="searchPartyForm">
            <div class="form-group col-xs-2">
              <div class="col-xs-2">
                <input type="text" class="form-control input-sm" formControlName="partyList" name="ledger_name" [(ngModel)]="ledger_name"
                  (ngModelChange)="SearchL()" placeholder="Search">
              </div>
            </div>
          </form>
          <!-- <form [formGroup]="searchPartyForm">
            <div class="form-group col-xs-2">
              <select  class="form-control"  formControlName="partyList" (ngModelChange)="partyCall($event)" style="font-size: small;">
                <option value="0" selected>All</option>
                <option id="partyList"  *ngFor="let partyName of partyNameList"  [value]="partyName.ledger_id" >{{ partyName.ledger_name }}</option>
           </select>
            </div>
          </form> -->
        </td>
        <td>
          <form [formGroup]="searchLedgerTypeForm">
            <div class="form-group col-xs-2">
              <select  class="form-control"  formControlName="ledgerType" (ngModelChange)="ledgerTypeCall($event)" style="font-size: small;">
                <option value="0" selected>All</option>
                <option name="payment" value="1">Party</option>
                      <option name="payment" value="2">Vehicle</option>
           </select>
            </div>
          </form>
        </td>
        <td></td>
        <td></td>
      </tr>
      <tr *ngFor="let item of collection | paginate : {itemsPerPage:pageSize, currentPage:pageNo, totalItems: count}; let i = index">
        <th scope="row">{{i+1}}</th>
        <td>{{item.ledger_name}}</td>
        <td>
          <ng-template [ngIf]="item.ledger_type===null">
            <span>--</span>
          </ng-template>
          <ng-template [ngIf]="item.ledger_type!==null">
            <span>{{item.ledger_type.type_name}}</span>
          </ng-template>
        </td>
        <td>{{item.ledger_created | date: mmddyyyy}}</td>
        <th>
          <a (click)="deleteLedger(item.ledger_id)"><i class="fa fa-trash" style="font: size 30px; color: red;"></i></a>&nbsp;&nbsp;<span></span>
          <a (click)="lcUpdateLedger(item.ledger_id)"><i class="fa fa-edit" style="font: size 30px; color: blue;"></i></a>
        </th>
      </tr>
    </tbody>
    <tr><td colspan="5"><pagination-controls (pageChange) = "handlePageChange($event)"></pagination-controls></td></tr>
     </table>
</div>
