import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { LedgerService } from 'src/app/services/ledger.service';
import { ReportData } from 'src/models/reportData';
import { ChallanService } from 'src/app/services/challan.service';
import { ExcelService } from 'src/models/excel-service';
import { MiscReportChallan } from 'src/models/miscReport';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-m-report',
  templateUrl: './m-report.component.html',
  styleUrls: ['./m-report.component.css']
})
export class MReportComponent implements OnInit {

  collection: any = [];
  columns: any[];
  footerData: any[][] = [];
  totalCreditAmt = 0;
  totalCashAmt = 0;
  totalQty = 0;

  c_voucher_no: any;
  com_name: any;
  c_partyname: any;
  ledger_name: any;
  p_name: any;
  user_company_address: any;
  c_payment_type: any;
  c_date: any;
  c_time: any;
  p: number = 1;

  mReportForm: FormGroup;
  partyDetailsList: any = [];
  partyNameList: any = [];
  vehicleNameList: any = [];
  productNameList: any = [];
  userNameList: any = [];

  collection2: any = [];
  excelFieldNames: any = [];
  myObject2: MiscReportChallan[] = [];

  valueDate = new Date;
  startfDate = new Date();
  endfDate = new Date();

  minDate: Date;
  maxDate: Date;
  startDateSwitch:boolean = false;

  constructor(
    private rf: FormBuilder,
    private productName: ProductService,
    private ledgerName: LedgerService,
    private viewChallanService: ChallanService,
    private excelService: ExcelService,
    private userService: UsersService
  ) {
    this.loadPartyName();
    this.loadVehicleName();
    this.loadProductName();
    this.loadUserName();

    this.startfDate.setDate(this.valueDate.getDate() - 2);
    this.endfDate.setDate(this.valueDate.getDate());
    this.minDate = new Date();
    this.maxDate = new Date();
    // this.minDate.setDate(this.minDate.getDate() - 15000);
    this.maxDate.setDate(this.maxDate.getDate());
    this.setStartDate();
  }

  ngOnInit(): void {
    this.mReportForm = this.rf.group({
      partyList: ['0',Validators.required],
      vehicleList: ['0',Validators.required],
      productList: ['0',Validators.required],
      userList: ['0',Validators.required],
      startDate: ['',Validators.required],
      endDate: ['',Validators.required]
    })
    this.loadUserId();

    this.mReportForm.controls.startDate.patchValue(this.formatDate(this.startfDate));
    this.mReportForm.controls.endDate.patchValue(this.formatDate(this.endfDate));


    this.columns = ['s_no', 'Date', 'C.N.', 'Party', 'Vehicle', 'Item', 'CFT/TON', 'RATE','Credit', 'Cash', 'Remark'];
    this.excelFieldNames = ['s_no', 'Date', 'C.N.', 'Party', 'Vehicle', 'Item', 'CFT/TON', 'RATE', 'Credit', 'Cash', 'Remark'];
  }

  loadUserId() {
    this.userService.getUserID().subscribe(data => {
      console.log(data);
      let user_id = data;
      if (user_id !== undefined || user_id !== null) {
        if (Number(user_id) === 1) {
          this.startDateSwitch = true;
        }
      }
    }, error => {
      console.log(error)
    })
  }

  //to set start date value-------------
  setStartDate() {
    this.userService.getUserID().subscribe(data => {
      console.log(data);
      let user_id = data;
      if (user_id !== undefined || user_id !== null) {
        if (Number(user_id) === 1) {
          this.minDate.setDate(this.minDate.getDate() - 15000);
        } else {
          this.minDate.setDate(this.minDate.getDate() - 2);
        }
      }
    }, error => {
      console.log(error)
    })
  }

  //loading party names in partyList dropdown on component loading
  loadPartyName() {
    this.ledgerName.getPartyName().subscribe((data: any) => {
      this.partyNameList = data;
    })
  }

  //loading vehicle names in vehicleList dropdown on component loading
  loadVehicleName() {
    this.ledgerName.getVehicleName().subscribe((data: any) => {
      this.vehicleNameList = data;
    })
  }

  //loading product names in productList dropdown on component loading
  loadProductName() {
    this.productName.getProductName().subscribe((data) => {
      this.productNameList = data;
    })
  }

  //loading user names in userNameList dropdown on component loading
  loadUserName() {
    this.userService.getUsersName().subscribe((data) => {
      this.userNameList = data;
    })
  }

  //format Date function
  private formatDate(findDate:Date) {
    const d = new Date(findDate);
    // const d = findDate;
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  //Export to Excel Function
  exportToExcel() {
    this.mReportForm.patchValue({
      startDate: this.formatDate(this.mReportForm.get('startDate').value),
      endDate: this.formatDate(this.mReportForm.get('endDate').value)
    })
    this.excelService.exportAsExcelFile('::SHRI::', 'ALANKAR UDHYOG', this.mReportForm.controls.startDate.value, this.excelFieldNames, this.myObject2, this.footerData, 'AU', 'Sheet1');
  }

  fakeReportData(): MiscReportChallan {
    return {
      serial_no: 0,
      c_date: '',
      c_voucher_no: '',
      c_partyname: '',
      ledger_name: '',
      p_name: '',
      c_qty: 0,
      c_rate:0,
      credit_amt: 0,
      cash_amt: 0,
      c_remark: '',
    }
  }

  frd = this.fakeReportData()

  rd: ReportData = {
    partyId: 0,
    vehicleId: 0,
    productId: 0,
    userId: 0,
    startDate: "",
    endDate: ""
  };

  showReport() {
    this.viewChallanService.getChallanMiscReport(this.jaRahaHuTableKePaas()).subscribe(result => {
      this.collection = result
      console.log(result)
      this.collection2 = this.collection.map((value, index) => {
        value.serial_no = index + 1
        return value
      })
      console.log(this.collection2)
      if (this.myObject2.length !== 0) {
        this.myObject2 = [];
      }

      for (let i = 0; i < this.collection2.length; i++) {
        let element = this.collection2[i];
        this.frd.serial_no = element.serial_no
        this.frd.c_date = element.c_date
        this.frd.c_voucher_no = element.c_voucher_no
        this.frd.c_partyname = element.c_partyname
        this.frd.ledger_name = element.ledger.ledger_name
        this.frd.p_name = element.product.p_name
        this.frd.c_qty = element.c_qty
        this.frd.c_rate = Number(element.c_rate)
        if (Number(element.c_payment_type)===1) {
          this.frd.credit_amt = Number(element.c_total_amt);
          this.frd.cash_amt = 0;
        } else if (Number(element.c_payment_type)===2) {
          this.frd.credit_amt = 0;
          this.frd.cash_amt = Number(element.c_total_amt);
        } else if (Number(element.c_payment_type)===3) {
          this.frd.credit_amt = Number(element.c_balance_amt);
          this.frd.cash_amt = Number(element.c_advance_amt);
        } else {
          this.frd.credit_amt = 0;
          this.frd.cash_amt = 0;
        }
        this.frd.c_remark = element.c_remark
        this.myObject2.push(Object.assign({}, this.frd));
      }
      this.collection2 = [];
 
      if (this.totalCreditAmt !== 0 || this.totalCashAmt !== 0 || this.totalQty !== 0) {
        this.totalCreditAmt = 0;
        this.totalCashAmt = 0; 
        this.totalQty = 0;
      }

      for (let i = 0; i < this.collection.length; i++) {
        if (Number(this.collection[i].c_payment_type)===1) {
          this.totalCreditAmt += Number(this.collection[i].c_total_amt);
          this.totalCashAmt += 0;
        } else if (Number(this.collection[i].c_payment_type)===2) {
          this.totalCreditAmt += 0;
          this.totalCashAmt += Number(this.collection[i].c_total_amt);
        } else if (Number(this.collection[i].c_payment_type)===3) {
          this.totalCreditAmt += Number(this.collection[i].c_balance_amt);
          this.totalCashAmt += Number(this.collection[i].c_advance_amt);
        } else {
          this.totalCreditAmt += 0;
          this.totalCashAmt += 0;
        }
        this.totalQty += Number(this.collection[i].c_qty)
      }
      this.footerData.length = 0; 
      this.footerData.push(['Total', '', '', '', '', '', this.totalQty, this.totalCreditAmt, this.totalCashAmt]);
    })
  }

  jaRahaHuTableKePaas() {
    this.rd.partyId = Number(this.mReportForm.controls.partyList.value),
      this.rd.vehicleId = Number(this.mReportForm.controls.vehicleList.value),
      this.rd.productId = Number(this.mReportForm.controls.productList.value),
      this.rd.userId = Number(this.mReportForm.controls.userList.value),
      this.rd.startDate = this.formatDate(this.mReportForm.controls.startDate.value),
      this.rd.endDate = this.formatDate(this.mReportForm.controls.endDate.value)
    return this.rd;
  }

  get partyList(){return this.mReportForm.get('partyList')};
  get vehicleList(){return this.mReportForm.get('vehicleList')};
  get productList(){return this.mReportForm.get('productList')};
  get userList(){return this.mReportForm.get('userList')};
  get startDate(){return this.mReportForm.get('startDate')};
  get endDate(){return this.mReportForm.get('endDate')};

}
