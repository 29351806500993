<breadcrumb #parent>
    <ol class="breadcrumb">
        <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
            <li *ngIf="!route.terminal" class="breadcrumb-item">
                <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]" style="color: black;">&nbsp;{{ route.displayName }}</a>
            </li>
            <li *ngIf = "route.terminal" class="breadcrumb-item active">
                {{ route.displayName}}
            </li>
        </ng-template>
    </ol>
</breadcrumb>

<h4>Updated Receipt</h4>

<!-- success alert message start -->
<div *ngIf="alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Success</strong> Your data has been updated.
    <button (click)="closeAlert()" type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<!-- success alert message end -->

<div class="container-fluid" style="border:1px solid lightgray; border-radius: 2px;">
    <form [formGroup]="editReceipt" (ngSubmit)="updateReceipt()">
        <div class="row">
            <div class="form-group col-md-4">

                <label id="labelcolor">Receipt No.<span id="spancolor">*</span></label><br>
                <input type="text" class="form-control" formControlName="receipt_no" required readonly/>
            </div>

            <div class="form-group col-md-4">
                <label id="labelcolor">Date <span id="spancolor">*</span></label><br>
                <input class="form-control" id="receipt_date" formControlName="receipt_date"  [minDate]="minDate" [maxDate]="maxDate" bsDatepicker #datepickerYMD="bsDatepicker" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" required />
            </div>

            <div class="form-group col-md-4">
              <label>Paymode <span id="spancolor">*</span></label><br>
              <select class="form-control" formControlName="paymode"  required>
                <option name="" value="0" disabled selected>Select Payment Type</option>
                <option name="credit" value="1">Credit</option>
                <option name="cash" value="2">Cash</option>
                <option name="cash" value="3">Adv./Due</option>
              </select>
          </div>

          <div class="form-group col-md-4">
              <label name="labelcolor">Party <span id="spancolor">*</span></label><br>
              <ng-select  class="form-control"  formControlName="selectParty" (ngModelChange)="partyCalling($event)">
                <ng-option value="0" selected>--Select Party Name</ng-option>
                <ng-option id="challan_party"  *ngFor="let partyName of partyNameList"  [value]="partyName.ledger_id" >{{ partyName.ledger_name }}</ng-option>
              </ng-select>
          </div>

          <div class="form-group col-md-4">
              <label id="labelcolor">Party Address <span id="spancolor">*</span></label><br>
              <input type="text" class="form-control" formControlName="party_address" />
          </div>

          <div class="form-group col-md-4">
              <label id="labelcolor">Party Mobile <span id="spancolor">*</span></label><br>
              <input type="tel" class="form-control" formControlName="party_mobile" />
          </div>

          <div class="form-group col-md-4">
              <label id="labelcolor">Amount <span id="spancolor">*</span></label><br>
              <input type="text" class="form-control" formControlName="amount" required />
          </div>


          <div class="form-group col-md-8">
              <label id="labelcolor">Remark <span id="spancolor">*</span></label><br>
              <input type="text" class="form-control" formControlName="remark" required />
          </div>

            <div class="form-group col-md-4">
                <button class="btn btn-primary">Submit</button>
                <button class="btn btn-danger" mat-flat-button [routerLink]="['../viewReceipt']">Cancel</button>
            </div>
          </div>
        </form>
    </div>
