import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUnitsComponent } from './add-units/add-units.component';
import { ViewUnitsComponent } from './view-units/view-units.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UpdateUnitComponent } from './update-unit/update-unit.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BreadcrumbModule } from 'angular-crumbs';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';



@NgModule({
  declarations: [AddUnitsComponent, ViewUnitsComponent, UpdateUnitComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2SearchPipeModule,
    NgSelectModule,
    BreadcrumbModule,
    RouterModule
  ]
})
export class UnitsModule { }
