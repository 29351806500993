

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './dashboard/header/header.component';
import { AddChallanComponent } from './challan/add-challan/add-challan.component';
import { UpdateChallanComponent } from './challan/update-challan/update-challan.component';
import { LoginComponent } from './admin/login/login.component';
import { AddLedgerComponent } from './ledger/add-ledger/add-ledger.component';
import { AddProductComponent } from './product/add-product/add-product.component';

//Importing AuthGuard;
import { AuthGuard } from './admin/helper';
import { ViewLedgerComponent } from './ledger/view-ledger/view-ledger.component';
import { UpdateLedgerComponent } from './ledger/update-ledger/update-ledger.component';
import { ViewChallanComponent } from './challan/view-challan/view-challan.component';
import { DCardComponent } from './dashboard/d-card/d-card.component';
import { AddUserComponent } from './user/add-user/add-user.component';
import { ViewUserComponent } from './user/view-user/view-user.component';
import { UpdateUserComponent } from './user/update-user/update-user.component';
import { AddUnitsComponent } from './units/add-units/add-units.component';
import { UpdateUnitComponent } from './units/update-unit/update-unit.component';
import { ViewUnitsComponent } from './units/view-units/view-units.component';
import { ViewProductComponent } from './product/view-product/view-product.component';
import { UpdateProductComponent } from './product/update-product/update-product.component';
import { AddReceiptComponent } from './receipt/add-receipt/add-receipt.component';
import { ViewReceiptComponent } from './receipt/view-receipt/view-receipt.component';
import { UpdateReceiptComponent } from './receipt/update-receipt/update-receipt.component';
import { InvoiceComponent } from './challan/invoice/invoice.component';
import { MReportComponent } from './reports/m-report/m-report.component';
import { VReportComponent } from './reports/v-report/v-report.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingComponent } from './setting/setting.component';




const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  // { path: 'invoice', component: InvoiceComponent },
  {
    path: 'dashboard',
    component: HeaderComponent, data: { breadcrumb: 'Dashboard' }, canActivate: [AuthGuard],
    children: [
      //path for Dashboard Card component
      { path: 'dCard', component: DCardComponent, data: { breadcrumb: 'DCard' }, canActivate: [AuthGuard] },

      //path for Challan Component
      { path: 'addChallan', component: AddChallanComponent, data: { breadcrumb: 'Add Challan' }, canActivate: [AuthGuard] },
      { path: 'viewChallan', component: ViewChallanComponent, data: { breadcrumb: 'View Challan' }, canActivate: [AuthGuard] },
      { path: 'updateChallan', component: UpdateChallanComponent, data: { breadcrumb: 'Update Challan' }, canActivate: [AuthGuard] },

      //path for Ledger Component
      { path: 'addLedger', component: AddLedgerComponent, data: { breadcrumb: 'Add Ledger' }, canActivate: [AuthGuard] },
      { path: 'viewLedger', component: ViewLedgerComponent, data: { breadcrumb: 'View Ledger' }, canActivate: [AuthGuard] },
      { path: 'updateLedger', component: UpdateLedgerComponent, data: { breadcrumb: 'Update Ledger' }, canActivate: [AuthGuard] },

      //path for User Component
      { path: 'addUser', component: AddUserComponent, data: { breadcrumb: 'Add User' }, canActivate: [AuthGuard] },
      { path: 'viewUser', component: ViewUserComponent, data: { breadcrumb: 'View User' }, canActivate: [AuthGuard] },
      { path: 'updateUser', component: UpdateUserComponent, data: { breadcrumb: 'Update User' }, canActivate: [AuthGuard] },

      //path for Unit Component
      { path: 'addUnit', component: AddUnitsComponent, data: { breadcrumb: 'Add Unit' }, canActivate: [AuthGuard] },
      { path: 'viewUnit', component: ViewUnitsComponent, data: { breadcrumb: 'View Unit' }, canActivate: [AuthGuard] },
      { path: 'updateUnit', component: UpdateUnitComponent, data: { breadcrumb: 'Update Unit' }, canActivate: [AuthGuard] },

      //path for Product Component
      { path: 'addProduct', component: AddProductComponent, data: { breadcrumb: 'Add Product' }, canActivate: [AuthGuard] },
      { path: 'viewProduct', component: ViewProductComponent, data: { breadcrumb: 'View Product' }, canActivate: [AuthGuard] },
      { path: 'updateProduct', component: UpdateProductComponent, data: { breadcrumb: 'Update Product' }, canActivate: [AuthGuard] },

      //path for Receipt Component
      { path: 'addReceipt', component: AddReceiptComponent, data: { breadcrumb: 'Add Receipt' }, canActivate: [AuthGuard] },
      { path: 'viewReceipt', component: ViewReceiptComponent, data: { breadcrumb: 'View Receipt' }, canActivate: [AuthGuard] },
      { path: 'updateReceipt', component: UpdateReceiptComponent, data: { breadcrumb: 'Update Receipt' }, canActivate: [AuthGuard] },

      //path for Reports Component
      { path: 'mReport', component: MReportComponent, data: { breadcrumb: 'Miscellaneous Report' }, canActivate: [AuthGuard] },
      { path: 'vReport', component: VReportComponent, data: { breadcrumb: 'Vehicle Report' }, canActivate: [AuthGuard] },

      // path for Print Component
      //{ path: 'invoice', component: InvoiceComponent, canActivate: [AuthGuard] },

      // path for Profile Component
      { path: 'profile', component: ProfileComponent, data: { breadcrumb: 'Profile' }, canActivate: [AuthGuard] },
      { path: 'setting', component: SettingComponent, data: { breadcrumb: 'Setting' }, canActivate: [AuthGuard] },

    ]
  },


  { path: 'invoice', component: InvoiceComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
