import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import { ProductCode } from 'src/models/productCode';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  pid:any;

  rooturl = environment.rooturl;
  url = `${this.rooturl}/products`;

  constructor(private http:HttpClient) { }

  saveProduct(data)
  {
    // console.warn(data)
    return this.http.post(this.url+'/add',data)  
  }

  public getProduct()
  {
    // console.warn(this.http.get(this.url))
    return this.http.get(this.url)  
  }
  getPaginatedProduct(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('p', data.pageNo);
    params = params.append('s', data.pageSize);
    return this.http.get<any>(`${this.url}/pagination`,{params: params})
  }

  getProductCode(): Observable<ProductCode[]> {
    return this.http.get<ProductCode[]>(`${this.url}/p-code`);
  }

  getProductName():Observable<any[]> {
    return this.http.get<any>(this.url + '/product-name');
  }

  getCurrentProduct():Observable<any[]> {
    return this.http.get<any>(this.url+`/update/${this.pid}`);
  }

  updateProduct(id, data)
  {
    return this.http.patch(`${this.url}/${id}`, data)
  }
  doDeleteProduct(id, data) {
    return this.http.patch(`${this.url}/delete/${id}`, data);
  }
}
