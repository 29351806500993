import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCode } from 'src/models/productCode';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {
  
  addProduct: FormGroup;
  alert: boolean = false;

  productNum: ProductCode[];
  productNo:string;
  sankhyaatmakProduct: Array<number> = [];

  constructor(private productService: ProductService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    this.addProduct = this.fb.group({
      code: ['', Validators.required],
      name: ['', Validators.required],
      product_rate: ['', Validators.required],
    })

    this.productService.getProductCode().subscribe((result: ProductCode[])=> {
      this.productNum = result
      this.autoProductNo();
    })

  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showWarning(messageNotify: string) {
    this.toastr.warning('Failed!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  autoProductNo() {
    if (this.productNum === null) {
      this.addProduct.patchValue({
        code: '0001'
      })
    } else if(this.productNum.length === 0) {
      this.addProduct.patchValue({
        code: '0001'
      })
    } else {
      this.productNum.forEach((productNo) =>{
        this.productNo = productNo.p_code;
        this.sankhyaatmakProduct.push(Number(this.productNo));
      })
      this.sankhyaatmakProduct.sort((a, b) => b - a);
      this.productNo = this.sankhyaatmakProduct[0].toString();
      this.sankhyaatmakProduct.length = 0;
      switch ((Math.log(Number(this.productNo)+1) * Math.LOG10E + 1 | 0)) {
        case 1:
          this.addProduct.patchValue({
            code: `000${(Number(this.productNo)+1)}`
          })
          break;
        case 2:
          this.addProduct.patchValue({
            code: `00${(Number(this.productNo)+1)}`
          })
          break;
        case 3:
          this.addProduct.patchValue({
            code: `0${(Number(this.productNo)+1)}`
          })
          break;
        default:
          this.addProduct.patchValue({
            code: `${(Number(this.productNo)+1)}`
          })
          break;
      }
    }
  }

  getValues() {
    this.productService.saveProduct(this.addProduct.value).subscribe((result) => {
      if (result['status']=== 200) {
        this.showSuccess(result['message']);
        window.location.reload();
      }
      if (result['status']=== 208) {
        this.showWarning(result['message']);
      }
      console.log(result)
    }, error => {
      if (error.status === 400) {
        this.showError(error.error);
        // window.location.reload(); 
      }
      if (error.status === 500) {
        this.showError(error.error.message);
        // window.location.reload(); 
      }
    })
    
    this.productService.getProductCode().subscribe((result: ProductCode[])=> {
      this.productNum = result
      this.autoProductNo();
    })
  }

  reloadPage() {
    window.location.reload();
  }

  closeAlert() {
    this.alert = false;
  }

  //for vaildators
  get code() { return this.addProduct.get('code') };
  get name() { return this.addProduct.get('name') };
  get product_rate() { return this.addProduct.get('product_rate') };

}
