import {Injectable} from '@angular/core';
import {Workbook} from 'exceljs';
import * as fs from 'file-saver';
import { element } from 'protractor';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTf-8';
const EXCEL_EXTENTION = '.xlsx';

@Injectable({
    providedIn: 'root'
})

export class VExcelService {
    
    constructor() {};

    public exportAsExcelFile(
        reportHeading: string,
        reportSubHeading: string,
        headersArray:any[],
        json:any[],
        footerData: any[],
        excelFileName: string,
        sheetName: string
    ){
        const header = headersArray;
        const data = json;

        /*create workbook and worksheet */

        const workbook = new Workbook();
        workbook.creator = 'Alankar udhyog';
        workbook.lastModifiedBy = 'Alankar Udhyog';
        workbook.created = new Date();
        workbook.modified = new Date();
        const worksheet = workbook.addWorksheet(sheetName);

        /* Add Header Row */
        worksheet.addRow([]);
        // worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');
        worksheet.mergeCells('A1:J1');
        worksheet.getCell('A1').value = reportHeading;
        worksheet.getCell('A1').alignment = {horizontal: 'center'};
        worksheet.getCell('A1').font = {size: 15, bold:true};

        if(reportSubHeading !== ''){
            worksheet.addRow([]);
        worksheet.mergeCells('A2:J2');
        worksheet.getCell('A2').value = reportSubHeading;
        worksheet.getCell('A2').alignment = {horizontal: 'center'};
        worksheet.getCell('A2').font = {size: 15, bold:true};
        }

        worksheet.addRow([]);

        /*Add Header Row */
        const headerRow = worksheet.addRow(header);

        //cell Style : Fill and Border
        headerRow.eachCell((cell,index) => {
            cell.fill = {
                type: 'pattern',
                pattern : 'solid',
                fgColor: {argb: 'FFFFFF00'},
                bgColor: {argb : 'FF0000FF'}
            };

            cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
            cell.font = {size: 12, bold:true};

            // worksheet.getColumn(index).width = header[index-1].length < 15 ? 10 : header[index-1].length;
            worksheet.getColumn(1).width =  8;
            worksheet.getColumn(2).width =  12;
            worksheet.getColumn(3).width =  8;
            worksheet.getColumn(4).width =  16;
            worksheet.getColumn(5).width =  10;
            worksheet.getColumn(6).width =  10;
            worksheet.getColumn(7).width =  8;
            worksheet.getColumn(8).width =  8;
        });

        /*Get all columns from json*/
        let columnsArray : any[];
        for (const key in json){
            if(json.hasOwnProperty(key)){
                columnsArray = Object.keys(json[key]);
            }
        }

        /** Add data and Conditional formatting */
        data.forEach((element: any) => {
            const eachRow = [];
            columnsArray.forEach((column) => {
                eachRow.push(element[column])
            });

            if (element.isDeleted === 'Y') {
                const deletedRow = worksheet.addRow(eachRow);
                deletedRow.eachCell((cell) => {
                    cell.font = {name: 'calibiri', family: 4, size:11, bold:false, strike:true};
                });
            } else {
                worksheet.addRow(eachRow);
            }
        });

        worksheet.addRow([]);

        /** Add footer data row */
        if (footerData != null){
            footerData.forEach((element: any[]) => {

                const eachRow = [];
                element.forEach((val: any) => {
                    eachRow.push(val);
                });

                const footerRow = worksheet.addRow(eachRow);
                footerRow.eachCell((cell) => {
                    cell.font = {bold:true};
                });
            });
        }

        /**Save Excel file */
        workbook.xlsx.writeBuffer().then((data:ArrayBuffer) => {
            const blob = new Blob([data], { type: EXCEL_TYPE });
            fs.saveAs(blob, excelFileName + EXCEL_EXTENTION);
        });
    }

    private numToAlpha(num:number){
        let alpha = ' ';

        for (; num >=0; num = parseInt((num / 26).toString(),10) - 1){
            alpha = String.fromCharCode(num % 26 + 0*41) + alpha;
        }

        return alpha
    }
}