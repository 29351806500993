import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  rooturl = environment.rooturl;
  url = `${this.rooturl}/users`;

  constructor(private http:HttpClient) { }

  getCurrentUsers():Observable<any[]> {
    return this.http.get<any>(`${this.url}/email`);
  }
  updateCurrentUserDetails(data) {
    return this.http.patch(`${this.url}/email-update`, data);
  }
}
