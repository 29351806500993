import { Component, OnInit, ViewChild } from '@angular/core';
import {ChallanService} from '../../services/challan.service';
import { ProductService } from '../../services/product.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UnitsService } from 'src/app/services/units.service';
import { LedgerService } from 'src/app/services/ledger.service';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { ActivatedRoute, Router } from '@angular/router';
import { IchallanNo } from 'src/models/challanNo';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/services/users.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ViewChallanComponent } from '../view-challan/view-challan.component';


@Component({
  selector: 'app-add-challan',
  templateUrl: './add-challan.component.html',
  styleUrls: ['./add-challan.component.css']
})
export class AddChallanComponent implements OnInit {
  @ViewChild('viewChallan', { static: false }) viewChallan: ViewChallanComponent;
  addChallan : FormGroup;
 
  showAdd : boolean = true;
  showAddP : boolean = false;
  show1 : boolean = false;
  show2 : boolean = false;
  alert: boolean = false
  partyDetailsList: any = [];
  unitNameList:any = [];
  partyNameList:any = [];
  vehicleNameList:any = [];
  productNameList:any = [];
  ledgerAddressList:any = [];
  challanNums: IchallanNo[];
  challanNo:string;
  chalSankhya: Number;
  sankhyaatmakChallan: Array<number> = [];
  totalAmt:number; //to store the total amount
  calBal : number; //to store the balance amount
  dateString: string;
  valuedate = new Date();
  disbCondition:boolean = true;
  disable = true;

  // authDetails: AuthInterface;

  minDate: Date;
  maxDate: Date;
  
  showParty:boolean = false;
  
  constructor(
    private fb: FormBuilder,
    private addChallanService:ChallanService, 
    private productService: ProductService,
    private unitService: UnitsService,
    private ledgerService: LedgerService,
    private userService: UsersService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
    ) {
     
      this.valuedate.setDate(this.valuedate.getDate() - 1);
      this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 15000);
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit(): void { 
    
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
    // this.addChallanService.getChallanNo(this.taarikhPeCall()).subscribe((result: IchallanNo[])=> {
    //   this.challanNums = result
    //   this.autoChallanNo();
    // })
    // this.addChallanService.getChallanNo().subscribe(result => {
    //   this.chalSankhya = result;
    //   this.autoChallanNo()
    // })

    this.addChallanService.getChallanNo().subscribe((result: IchallanNo[])=> {
      this.challanNums = result
      this.autoChallanNo();
    })



    this.addChallan = this.fb.group({
      challan_no: ['',[Validators.required,Validators.minLength(4), Validators.maxLength(4)]],
      challan_date: ['',Validators.required],
      challan_time: [''],
      unit: ['0'],
      selectParty: ['',Validators.required],
      party_name: [''],
      party_address: [''],
      party_mobile: [''],
      party_name_hindi: [''],
      party_address_hindi: [''],
      vehicleInfo: ['',Validators.required],
      driver_name: [''],
      driver_mobile: [''],
      productInfo: ['',Validators.required],
      qty: [0,[Validators.required,Validators.pattern('[0-9]*\.[0-9]*')]],
      rate: [0,[Validators.required,Validators.pattern('[0-9]*\.[0-9]*')]],
      total_amount: 0.00,
      paymentMethod: ['',Validators.required],
      advance_amount: [0,[Validators.pattern('[0-9]*')]],
      balance_sheet: 0.00,
      remark: [''],
      ravana_number: 0,
      Weight: 0,
      Royalty_amount: 0
    })

    this.loadProductName();
    this.loadPartyName();
    this.loadVehicleName();
    this.loadUnitName();

    this.loadFalana();
    
    //triggers the calculate Amount function on quantity value change
    this.addChallan.controls.qty.valueChanges.subscribe(result=>{
      this.calculateAmt();
    })

    //triggers the calculate Amount function on rate value change
    this.addChallan.controls.rate.valueChanges.subscribe(result=>{
      this.calculateAmt();
    })

    //triggers the calculate Balance function on Advance amount value change
    this.addChallan.controls.advance_amount.valueChanges.subscribe(result=>{
      this.calculateBal(result,'aAmt');
    })

    //triggers the calculate Balance function on Payment Type value change
    this.addChallan.controls.paymentMethod.valueChanges.subscribe(result=>{
      this.calculateBal(result,'pType');
    })
   
    this.addChallan.controls.challan_date.patchValue(this.formatDate(new Date()));
    this.addChallan.controls.challan_time.patchValue(this.formattime(new Date()));

    // this.addChallan.controls.challan_date.valueChanges.subscribe(result=>{
    //   this.taarikhPeCall();
    //   this.addChallanService.getChallanNo(this.taarikhPeCall()).subscribe((result: IchallanNo[])=> {
    //     this.challanNums = result
    //     this.autoChallanNo();
    //   })
    // })

    // this.addChallanService.getChallanNo().subscribe(result => {
    //   this.chalSankhya = result;
    //   this.autoChallanNo()
    // })

    // this.addChallan.controls.challan_date.valueChanges.subscribe(result=>{
    //   this.taarikhPeCall();
    //   this.addChallanService.getChallanNo().subscribe((result: IchallanNo[])=> {
    //     this.challanNums = result
    //     this.autoChallanNo();
    //   })
    // })

  }

  // toastr code for notifications
  // messageNotify: string;
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showWarning(messageNotify: string) {
    this.toastr.warning('Failed!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  private formatDate(date) {
    // const d = new Date(date);
    const d = this.valuedate;
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year,month,day].join("-");
  }
  private formattime(date) {
    const t = new Date(date);
    let hour = "" + (t.getHours() );
    let minutes = "" + t.getMinutes();
    // const seconds = t.getSeconds();
    if (hour.length < 2) hour = "0" + hour;
    if (minutes.length < 2) minutes = "0" + minutes;
    return [hour, minutes].join(":");
  }

  taarikhPeCall() {
    if (this.addChallan !== undefined) {
      var d = new Date(this.addChallan.controls.challan_date.value),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    } else {
      var d = new Date();
      var yd = new Date(d.getTime());
      yd.setDate(d.getDate() - 1)
      d = yd;
       var month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
 
      return [year, month, day].join('-');
    }
  }

  loadUnitName() {
    this.unitService.getUnitName().subscribe((data:any) => {
      this.unitNameList = data;
    })
  }
  
  loadPartyName() {
    this.ledgerService.getPartyName().subscribe((data:any) => {
      this.partyNameList = data;
      this.partyNameList.sort((a, b) => a.ledger_name.localeCompare(b.ledger_name));
    })
  }

  loadVehicleName() {
    this.ledgerService.getVehicleName().subscribe((data:any) => {
      this.vehicleNameList = data;
      this.vehicleNameList.sort((a, b) => a.ledger_name.localeCompare(b.ledger_name));
    })
  }

  loadProductName() {
    this.productService.getProductName().subscribe((data) => {
      this.productNameList = data;
      this.productNameList.sort((a, b) => a.p_name.localeCompare(b.p_name));
    })
  }

  coll1: any = [];
  coll2: any = [];
  coll3: any = [];
  loadFalana() {
    // let coll1, coll2, coll3;
    this.profileService.getCurrentUsers().subscribe(data=> {
      console.log(data);
      this.coll1 = data;
      if (this.coll1.user_id === 1) {
        this.show1 = true;
        this.show2 = false;
      } else {
        this.show1 = false;
        this.show2 = true;
      }
      this.unitService.getUnitNameAddress().subscribe(data1 => {
        console.log(data1);
        this.coll2 = data1;
        this.coll2.forEach(value => {
          if (value.com_name === this.coll1.user_compnay_name) {
            this.unitService.getUnitIdByName(value.com_name).subscribe(data => {
              console.log(data)
              this.coll3 = data;
              this.addChallan.patchValue({
                unit: this.coll3.com_name
              })
              // this.addChallan.controls['unit'].disable();

            })
          }
        })
      })
    })
  }

  closeAlert()
  {
    this.alert=false
  }

  partyCall(val:Int16Array) {
    if (!this.addChallan.controls.selectParty.value){
      this.showParty = true;
      this.addChallan.controls.selectParty.invalid;
    } else {
      this.showParty = false;
      this.loadPartyDetails(val);
    }      
  }

  loadPartyDetails(id) {
    this.ledgerService.getPartyDetails(id).subscribe((data: any) => {
      this.partyDetailsList = data;
      this.pakdoPartyDetailsKo();
    })
  }

  pakdoPartyDetailsKo(){
    if (this.partyDetailsList !== null) {
      if (this.ledgerAddressList.length>0) {
        this.ledgerAddressList.length = 0;
      }
      if (this.partyDetailsList.ledger_address_line1 !== null) {
        this.ledgerAddressList.push(this.partyDetailsList.ledger_address_line1);        
      }
      if (this.partyDetailsList.ledger_address_line2 !== null) {
        this.ledgerAddressList.push(this.partyDetailsList.ledger_address_line2);        
      }
      if (this.partyDetailsList.ledger_address_line3 !== null) {
        this.ledgerAddressList.push(this.partyDetailsList.ledger_address_line3);        
      }
      if (this.partyDetailsList.ledger_address_line4 !== null) {
        this.ledgerAddressList.push(this.partyDetailsList.ledger_address_line4);        
      }
      if (this.addChallan.controls.selectParty.value === 1) {
        this.showAddP = false;
        this.showAdd = true;
        this.addChallan.patchValue({
          party_name: '',
          party_address: '',
          party_mobile: ''
        })
      } else {
        this.showAdd = false;
        this.showAddP = true;
        this.addChallan.patchValue({
          party_name: this.partyDetailsList.ledger_name,
          party_address: this.partyDetailsList.ledger_address_line1,
          party_mobile: this.partyDetailsList.ledger_mobile,
        })
      }
      if (this.partyDetailsList.challans.length !== 0) {
        this.addChallan.patchValue({
          productInfo: this.partyDetailsList.challans[0].c_product_id,
          qty: Number(this.partyDetailsList.challans[0].c_qty),
          rate: parseFloat(this.partyDetailsList.challans[0].c_rate),
          total_amount: parseFloat(this.partyDetailsList.challans[0].c_total_amt),
        })
      }
    }
  }

  autoChallanNo() {
    if (this.challanNums === null) {
      this.addChallan.patchValue({
        challan_no: '0001'
      })
    } else if(this.challanNums.length === 0) {
      this.addChallan.patchValue({
        challan_no: '0001'
      })
    } else {
      this.challanNums.forEach((challanNo) =>{
        this.challanNo = challanNo.c_voucher_no;
        this.sankhyaatmakChallan.push(Number(this.challanNo));
      })
      // this.sankhyaatmakChallan.sort((a, b) => a - b);
      
      // initiating challan number from one and checking
      console.log(this.sankhyaatmakChallan);
      let initiateChallan: number = 1;
      if (this.sankhyaatmakChallan[0] < 5000) {
        initiateChallan = this.sankhyaatmakChallan[0] + 1;
      } else {
        initiateChallan = 1;
      }
      // console.log(this.sankhyaatmakChallan[this.sankhyaatmakChallan.length-1]);
      // if (this.sankhyaatmakChallan[this.sankhyaatmakChallan.length-1]>=2000) {
      //   initiateChallan = 1;
      // } else {
      //   initiateChallan = this.sankhyaatmakChallan[0];
      // }
      // console.log(initiateChallan)
      // console.log(this.sankhyaatmakChallan)

      // searching from smallest element to largest element
      // for (let i = 0; i < this.sankhyaatmakChallan.length; i++) {
      //   if(this.sankhyaatmakChallan.indexOf(initiateChallan)>=0) {
      //     initiateChallan += 1;
      //     continue;
      //   } else{
      //     this.challanNo = initiateChallan.toString();
      //     break;
      //   }        
      // }
      // this.challanNo = this.sankhyaatmakChallan[0].toString();
      // if (this.sankhyaatmakChallan.length > 2000) {
      //   initiateChallan = 1;
      // }
      console.log("length of total challan: ", this.sankhyaatmakChallan.length)
      this.sankhyaatmakChallan.length = 0;
      console.log("after 2000 complete challan no reinitiate: ", initiateChallan);
      switch ((Math.log(Number(initiateChallan)) * Math.LOG10E + 1 | 0)) {
        case 1:
          this.addChallan.patchValue({
            challan_no: `000${(Number(initiateChallan))}`
          })
          break;
        case 2:
          this.addChallan.patchValue({
            challan_no: `00${(Number(initiateChallan))}`
          })
          break;
        case 3:
          this.addChallan.patchValue({
            challan_no: `0${(Number(initiateChallan))}`
          })
          break;
        case 4:
          this.addChallan.patchValue({
            challan_no: `${(Number(initiateChallan))}`
          })
          break;
        default:
          break;
      }
    }
  }

  //function to calculate total amount
  calculateAmt(){
    this.totalAmt = this.addChallan.controls.qty.value * this.addChallan.controls.rate.value;
    this.addChallan.patchValue({
      total_amount: this.totalAmt
    })
  }

  //Function to calculate balance amount
  calculateBal(result: any, ptype: string){
    if (ptype === 'pType') {
      if (result != 2) {
        this.calBal = this.addChallan.controls.total_amount.value - this.addChallan.controls.advance_amount.value;
        this.addChallan.patchValue({
          balance_sheet: this.calBal
        })
      } else {
        this.addChallan.patchValue({
          advance_amount: 0,
          balance_sheet: 0
        })
      }
    } else {
      this.calBal = this.addChallan.controls.total_amount.value - this.addChallan.controls.advance_amount.value;
      this.addChallan.patchValue({
        balance_sheet: this.calBal
      })
    }
  }
  
  //submit function to save the data into database 012
  getValues() {
    this.addChallan.patchValue({
      challan_date: this.taarikhPeCall()
    })
    if (this.coll3.length !== 0) {
      this.addChallan.patchValue({
        unit: this.coll3.com_id
      })
    }
    
    this.addChallanService.saveChallan(this.addChallan.value).subscribe((result)=>{
      if (result['status']=== 200) {
        this.showSuccess(result['message']);
        window.location.reload(); 
      }
      if (result['status']=== 208) {
        this.showWarning(result['message']);
        window.location.reload(); 
      }
        
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
        // window.location.reload(); 
      }
    })
    
    this.addChallan.reset({})
    this.addChallan.controls.challan_date.patchValue(this.formatDate(new Date()));
    this.addChallan.controls.challan_time.patchValue(this.formattime(new Date()));

    // this.addChallanService.getChallanNo(this.taarikhPeCall()).subscribe((result: IchallanNo[])=> {
    //   this.challanNums = result;
    //   this.autoChallanNo();
    // })

    // this.addChallanService.getChallanNo().subscribe(result => {
    //   this.chalSankhya = result;
    //   this.autoChallanNo();
    // })

    this.addChallanService.getChallanNo().subscribe((result: IchallanNo[])=> {
      this.challanNums = result;
      console.log(this.challanNums)
      this.autoChallanNo();
    })

    this.addChallan.setValue({
      challan_no: '',
      challan_date: this.formatDate(new Date()),
      challan_time: this.formattime(new Date()),
      unit: 0,
      selectParty: '',
      party_name: '',
      party_address: '',
      party_mobile: '',
      party_name_hindi: '',
      party_address_hindi: '',
      vehicleInfo: '',
      driver_name: '',
      driver_mobile: '',
      productInfo: '',
      qty: 0,
      rate: 0,
      total_amount: 0,
      paymentMethod: '',
      advance_amount: 0,
      balance_sheet: 0,
      remark: '',
      ravana_number: 0,
      Weight: 0,
      Royalty_amount: 0
    })
  }

  submitPrint(e:any) {
    this.addChallan.patchValue({
      challan_date: this.taarikhPeCall()
    })
    if (this.coll3.length !== 0) {
      this.addChallan.patchValue({
        unit: this.coll3.com_id
      })
    }
    
    this.addChallanService.saveChallan(this.addChallan.value).subscribe((result)=>{
      if (result['status']=== 200) {
        this.showSuccess(result['message']);
        // window.location.reload(); 
        let cid = result['result']['c_id'];
        // console.log(cid);
        // console.log(this.addChallan.get('submitPrint'))
        this.addChallanService.cPrintId = cid;
        console.log(this.addChallanService.cPrintId);
        localStorage.setItem('challan_id', JSON.stringify(this.addChallanService.cPrintId));
        this.router.navigate([]).then(result => {  window.open('http://alankarudhyog.com/#/invoice', '_blank'); });
        // this.router.navigateByUrl('/invoice');
        setTimeout(()=>{
          window.location.reload(); 
        },2000)

      }
      if (result['status']=== 208) {
        this.showWarning(result['message']);
        window.location.reload(); 
      }
        
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
        // window.location.reload(); 
      }
    })
    
    this.addChallan.reset({})
    this.addChallan.controls.challan_date.patchValue(this.formatDate(new Date()));
    this.addChallan.controls.challan_time.patchValue(this.formattime(new Date()));

    // this.addChallanService.getChallanNo(this.taarikhPeCall()).subscribe((result: IchallanNo[])=> {
    //   this.challanNums = result;
    //   this.autoChallanNo();
    // })

    // this.addChallanService.getChallanNo().subscribe(result => {
    //   this.chalSankhya = result;
    //   this.autoChallanNo();
    // })

    this.addChallanService.getChallanNo().subscribe((result: IchallanNo[])=> {
      this.challanNums = result;
      console.log(this.challanNums)
      this.autoChallanNo();
    })

    this.addChallan.setValue({
      challan_no: '',
      challan_date: this.formatDate(new Date()),
      challan_time: this.formattime(new Date()),
      unit: 0,
      selectParty: '',
      party_name: '',
      party_address: '',
      party_mobile: '',
      party_name_hindi: '',
      party_address_hindi: '',
      vehicleInfo: '',
      driver_name: '',
      driver_mobile: '',
      productInfo: '',
      qty: 0,
      rate: 0,
      total_amount: 0,
      paymentMethod: '',
      advance_amount: 0,
      balance_sheet: 0,
      remark: '',
      ravana_number: 0,
      Weight: 0,
      Royalty_amount: 0
    })
  }
  //fetching form control validators status
  get challan_no(){return this.addChallan.get('challan_no')};
  // get challan_date(){return this.addChallan.get('challan_date')};
  // get challan_time(){return this.addChallan.get('challan_time')};
  // get unit(){return this.addChallan.get('unit')};
  get selectParty(){return this.addChallan.get('selectParty')};
  get vehicleInfo(){return this.addChallan.get('vehicleInfo')};
  // get driver_name(){return this.addChallan.get('driver_name')};
  // get driver_mobile(){return this.addChallan.get('driver_mobile')};
  get productInfo(){return this.addChallan.get('productInfo')};
  get qty(){return this.addChallan.get('qty')};
  get rate(){return this.addChallan.get('rate')};
  get paymentMethod(){return this.addChallan.get('paymentMethod')};
  get advance_amount(){return this.addChallan.get('advance_amount')};
  // get ravana_number(){return this.addChallan.get('ravana_number')};
  // get Weight(){return this.addChallan.get('Weight')};
  // get Royalty_amount(){return this.addChallan.get('Royalty_amount')};
}
