import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { environment } from '../../environments/environment.prod';
import { IchallanNo } from 'src/models/challanNo';
import { ChallanDetails } from 'src/models/challanDetails';
import { ReturnStatement } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class ChallanService {

  cid:any;
  cPrintId: number;

  // url = "http://15.206.21.1:3000/challans"
  // rooturl = "http://15.206.21.1:3000"
  rooturl = environment.rooturl;
  url = `${this.rooturl}/challans`;

  constructor(private http:HttpClient) { }

  // private handleError(errorResponse: HttpErrorResponse) {
  //   if (errorResponse instanceof HttpErrorResponse) {
  //     //server side error
  //     console.log(errorResponse)
  //   } else {
  //     //client side error
  //     console.log(errorResponse)
  //   }
  //   return throwError(errorResponse);
  // }

  saveChallan(data)
  {
    // console.warn(data)
    return this.http.post(this.url+'/add',data);
  }

  public getChallan():Observable<any[]>
  {
    // console.warn(this.http.get(this.url))
    return this.http.get<any>(this.url);
  }

  getPaginatedChallan(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('p', data.pageNo);
    params = params.append('s', data.pageSize);
    if (data.c_party_id!== 0) {
      params= params.append('c_party_id', data.c_party_id);
    }
    if (data.c_vehicle_id!== 0) {
      params= params.append('c_vehicle_id', data.c_vehicle_id);
    }
    if (data.c_product_id!== 0) {
      params= params.append('c_product_id', data.c_product_id);
    }
    if (data.c_creator_id!== 0) {
      params= params.append('c_creator_id', data.c_creator_id);
    }
    if (data.c_payment_type!== 0) {
      params= params.append('c_payment_type', data.c_payment_type);
    }
    return this.http.get<any>(`${this.url}/pagination`,{params: params})
  }

  getTotalCountOfChallans(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('count', data);
    return this.http.get<any>(`${this.url}/calc`, {params: params});
  }
  // getChallanNo(date): Observable<IchallanNo[]> {
  //   let params1 = new HttpParams().append('date',date);
  //   return this.http.get<IchallanNo[]>(`${this.url}/challan-no`,{params: params1})
  // }

  // getChallanNo(): Observable<number> {
  //   return this.http.get<number>(`${this.url}/challan-no`);
  // }

  getChallanNo(): Observable<IchallanNo[]> {
    return this.http.get<IchallanNo[]>(`${this.url}/challan-no`)
  }

  getChallanMiscReport(data): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('partyId', data.partyId);
    params = params.append('vehicleId', data.vehicleId);
    params = params.append('productId', data.productId);
    params = params.append('userId', data.userId);
    params = params.append('startDate', data.startDate);
    params = params.append('endDate', data.endDate);
    return this.http.get<any[]>(`${this.url}/reports`, {params: params})
  }

  getChallanVehReport(data): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('partyId', data.partyId);
    params = params.append('vehicleId', data.vehicleId);
    params = params.append('productId', data.productId);
    params = params.append('startDate', data.startDate);
    params = params.append('endDate', data.endDate);
    return this.http.get<any>(`${this.url}/v-reports`, {params: params})
  }
  
  getCurrentChallan()
  {
    return this.http.get(`${this.url}/${this.cid}`)
  }
  getCurrentanotherChallan() {
    this.cPrintId = JSON.parse(localStorage.getItem('challan_id'));
    console.log(this.cPrintId)
    return this.http.get(`${this.url}/${this.cPrintId}`);
  }

  updateChallan(id, data)
  {
    console.log(data);
    return this.http.patch(`${this.url}/${id}`, data)
  }
  doDeleteChallan(id, data) {
    return this.http.patch(`${this.url}/delete/${id}`, data);
  }

}
