import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUserComponent } from './add-user/add-user.component';
import { ViewUserComponent } from './view-user/view-user.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UpdateUserComponent } from './update-user/update-user.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BreadcrumbModule } from 'angular-crumbs';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';

@NgModule({
  declarations: [AddUserComponent, ViewUserComponent, UpdateUserComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbModule,
    NgSelectModule,
    RouterModule
  ]
})
export class UserModule { }
