<breadcrumb #parent>
  <ol class="breadcrumb">
      <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <li *ngIf="!route.terminal" class="breadcrumb-item">
            <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]" style="color: black;">&nbsp;{{ route.displayName }}</a>
          </li>
          <li *ngIf = "route.terminal" class="breadcrumb-item active">
              {{ route.displayName}}
          </li>
      </ng-template>
  </ol>
</breadcrumb>

<div class="container">
  <h4>Update ledger</h4>

  <!-- success alert message start -->
  <div
    *ngIf="alert"
    class="alert alert-success alert-dismissible fade show"
    role="alert"
  >
    <strong>Success</strong> Your data has been successfully updated.
    <button
      (click)="closeAlert()"
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- success alert message end -->

  <div
    class="container-fluid"
    style="border: 1px solid rgb(112, 105, 105); border-radius: 2px;"
  >
    <form [formGroup]="updateLedger" (ngSubmit)="getValues()">
      <div class="panel panel-primary" style="color: blue;">
        <div class="panel-heading">
          <h3 class="panel-title">Update Ledger</h3>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-3">
          <label id="labelcolor">Add Photo</label>
        </div>
        <div class="form-group col-md-8">
          <!-- <input type="file" id="jcrop_fileinput" style="position: absolute; cursor: pointer; opacity: 0;width: 80px;height: 80px;"> -->
          <!-- <input type="file" id="imageUpload"   class="form-control" placeholder="" formControlName="add_photo" required /> -->

          <!-- <label for="imageUpload" class="btn btn-large">Select file</label>
          <input type="file"
            name="imageUpload"
            id="imageUpload"
            class="form-control hide"
            formControlName="add_photo"
            required
          /> -->
          
          <input #file type="file" name="imageUpload" id="imageUpload" class="form-control hide"
            accept='image/*' (change)="preview($event)" #fileInput/>
          <label for="imageUpload" class="btn btn-large">Select file</label>
          <img [src]="imgURL" height="80" *ngIf="imgURL">
          <!-- <span class="error" *ngIf="add_photo.invalid && add_photo.touched"
          >Please enter a valid Photo</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Ledger Type <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <select class="form-control" placeholder="--Select Ledger Type--" formControlName="ledger_type" required>
            <!-- <option name="party" value="0">Select Ledger Type</option> -->
            <option name="party" value="1">Party</option>
            <option name="party" value="2">Vehicle</option>
          </select>

          <span class="error" *ngIf="ledger_type.invalid && ledger_type.touched">Please enter a valid ledger type</span>
        </div>

        <!-- Ledger Name Field -->
        <div class="col-md-3" *ngIf="showl">
          <label id="labelcolor">Ledger Name <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8" *ngIf="showl">
          <input type="text" class="form-control" formControlName="ledger_name" required />
          <span class="error" *ngIf="ledger_name.invalid && ledger_name.touched">Please enter a valid name</span>
        </div>

        <!-- Vehicle Name Field -->
        <div class="col-md-3" *ngIf="show">
          <label id="labelcolor">Vehicle Number <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8" *ngIf="show">
          <input type="text" class="form-control" formControlName="ledger_name" required />
          <span class="error" *ngIf="ledger_name.invalid && ledger_name.touched">Please enter a valid name</span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Address-1</label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="address_1" />
          <!-- <span class="error" *ngIf="address_1.invalid && address_1.touched"
          >Please enter a valid address_1.</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Address-2</label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="address_2"/>
          <!-- <span class="error" *ngIf="address_2.invalid && address_2.touched"
          >Please enter a valid address_2.</span> -->
        </div>

        <!-- Address 3  -->
        <div class="col-md-3">
          <label id="labelcolor">Address-3</label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="address_3" />
          <!-- <span class="error" *ngIf="address_1.invalid && address_1.touched"
          >Please enter a valid address_1.</span> -->
        </div>

        <!-- Address 4  -->
        <div class="col-md-3">
          <label id="labelcolor">Address-4</label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="address_4"/>
          <!-- <span class="error" *ngIf="address_1.invalid && address_1.touched"
          >Please enter a valid address_1.</span> -->
        </div>
        <div class="col-md-3">
          <label id="labelcolor">Driver Name</label>
        </div>
        <div class="form-group col-md-8">
          <input
            type="text"
            class="form-control"
            formControlName="driver_name"
          />
          <!-- <span class="error" *ngIf="driver_name.invalid && driver_name.touched"
            >Please enter a valid driver_name.</span> -->
        </div>
       <!-- Mobile 1 -->
       <div class="col-md-3">
        <label id="labelcolor">Mobile-1 <span id="spancolor">*</span></label>
      </div>
      <div class="form-group col-md-8">
        <input type="text" class="form-control" formControlName="mobile"/>
        <!-- <span class="error" *ngIf="mobile.invalid && mobile.touched">Please enter a valid mobile number.</span> -->
      </div>

      <!-- Mobile 2 -->
      <div class="col-md-3">
        <label id="labelcolor">Mobile-2</label>
      </div>
      <div class="form-group col-md-8">
        <input type="text" class="form-control" formControlName="mobile2" />
      </div>

      <!-- Mobile 3 -->
      <div class="col-md-3">
        <label id="labelcolor">Mobile-3</label>
      </div>
      <div class="form-group col-md-8">
        <input type="text" class="form-control" formControlName="mobile3" />
      </div>

      <!-- Mobile 4 -->
      <div class="col-md-3">
        <label id="labelcolor">Mobile-4</label>
      </div>
      <div class="form-group col-md-8">
        <input type="text" class="form-control" formControlName="mobile4" />
      </div>
        <div class="col-md-3">
          <label id="labelcolor">GSTIN</label>
        </div>
        <div class="form-group col-md-8">
          <input
            type="text"
            class="form-control"
            formControlName="gstin"
          />
          <!-- <span class="error" *ngIf="gstin.invalid && gstin.touched"
            >Please enter a valid GSTIN number.</span> -->
        </div>

        <div class="col-md-3"></div>
        <div
          class="btn-toolbar"
          role="toolbar"
          aria-label="Toolbar with button groups"
        >
          <div class="btn-group" role="group" aria-label="First group">
            <button
              class="btn btn-primary"
              [disabled]="updateLedger.invalid"
              data-toggle="tooltip"
              data-placement="top"
              title=""
            >
              Update
            </button>
            &nbsp; &nbsp;
            <!-- <button type="submit" [disabled]="updateLedger.invalid" class="btn btn-secondary"
            placeholder="Submit & Print">
            Submit & Print
          </button> -->
            &nbsp; &nbsp;
            <button class="btn btn-danger" mat-flat-button [routerLink]="['../viewLedger']">Cancel</button>
          </div>
        </div>

        <small style="color: red;"
          >Update button will be enabled when all the mandatory fields are
          filled.</small
        >
      </div>
    </form>
  </div>
</div>
