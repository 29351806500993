import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { HeaderComponent } from 'src/app/dashboard/header/header.component';
import { UpdateUserComponent } from '../update-user/update-user.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})
export class ViewUserComponent implements OnInit {

  showNUser: FormGroup;
  collection: any;
  count = 0;

  constructor(
    private viewUserService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
    this.showNUser = this.fb.group({
      selectNUser : ['1']
    })
    this.getUserData();
  }

  getUserData() {
    this.viewUserService.getUsers().subscribe((result) => {
      // console.warn(result);
      this.collection = result
    }, error => {
      console.log(error);
    })
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  public lcUpdateUser(item) {
    this.viewUserService.uid = item;
    this.router.navigateByUrl('/dashboard/updateUser');
  }

  // fake data for temporary deletion
  fakeData = {
    user_status: 0,
    user_is_deleted: 1
  }

  deleteUser(lid) {
    if(confirm("Are you sure to delete ")){
      this.viewUserService.doDeleteUser(lid, this.fakeData).subscribe(result => {
        if (result['status']=== 200) {
          this.showSuccess(result['message']);
        }
      }, error => {
        if (error.status === 400) {
          this.showError(error.error);
        }
        if (error.status === 500) {
          this.showError(error.error.message);
          // window.location.reload(); 
        }
      })
      this.getUserData();
    }
  }

}
