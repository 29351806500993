import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UnitsService } from 'src/app/services/units.service';

@Component({
  selector: 'app-update-unit',
  templateUrl: './update-unit.component.html',
  styleUrls: ['./update-unit.component.css']
})
export class UpdateUnitComponent implements OnInit {

  updateUnits: FormGroup;
  id: any;
  alert: boolean = false

  statesList:any = [];

  public imagePath;
  imgURL: any;
  public message: string;
  //for image setting
  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private UnitService: UnitsService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    // console.warn(this.router.snapshot.params.id)
    this.updateUnits = this.fb.group({
      add_photo: [''],
      user: ['', Validators.required],
      unit_name: ['', Validators.required],
      address_1: ['', Validators.required],
      address_2: [''],
      address_3: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postcode: [''],
      com_email: [''],
      contact_person: ['', Validators.required],
      mobile_1: ['', Validators.required],
      mobile_2: [''],
      pan_no: [''],
      gst: [''],
      bank_name: [''],
      branch_name: [''],
      account_no: [''],
      ifsc_code: [''],
    });
    this.UnitService.getCurrentUnit().subscribe((result) => {
      console.warn(result)
      if (result !== null) {
        this.updateUnits.setValue({
          add_photo: '',
          user: result['com_user_id'],
          unit_name: result['com_name'],
          address_1: result['com_address1'],
          address_2: result['com_address2'],
          address_3: result['com_address3'],
          city: result['com_city'],
          state: result['com_state'],
          postcode: result['com_postcode'],
          com_email: result['com_email'],
          contact_person: result['com_contact_person'],
          mobile_1: result['com_mobile1'],
          mobile_2: result['com_mobile2'],
          pan_no: result['com_pan_no'],
          gst: result['com_gst'],
          bank_name: result['com_bank_name'],
          branch_name: result['com_branch_name'],
          account_no: result['com_account_no'],
          ifsc_code: result['com_ifsc_code']
        })
      }
    })
    this.id = this.UnitService.tid;
    this.loadStates();
  }

  loadStates() {
    this.UnitService.getStatesName().subscribe(result => {
      this.statesList = result;
    })
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications


  getValues() {
    this.UnitService.updateUnits(this.id, this.updateUnits.value).subscribe((result) => {
      if (result['status'] === 200) {
        this.showSuccess(result['message']);
      }
      this.updateUnits.reset({})
      this.router.navigateByUrl('/dashboard/viewUnit');
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
      }
      if (error.status === 400) {
        this.showError(error.error);
      }
    })
  }

  closeAlert() {
    this.alert = false
  }

  //for vaildators
  // get add_photo() { return this.updateUnits.get('add_photo') };
  get user() { return this.updateUnits.get('user') };
  get unit_name() { return this.updateUnits.get('unit_name') };
  get address_1() { return this.updateUnits.get('address_1') };
  // get address_2() { return this.updateUnits.get('address_2') };
  // get address_3() { return this.updateUnits.get('address_3') };
  get city() { return this.updateUnits.get('city') };
  get state() { return this.updateUnits.get('state') };
  // get postcode() { return this.updateUnits.get('postcode') };
  // get com_email() { return this.updateUnits.get('com_email') };
  get contact_person() { return this.updateUnits.get('contact_person') };
  get mobile_1() { return this.updateUnits.get('mobile_1') };
  // get mobile_2() { return this.updateUnits.get('mobile_2') };
  // get pan_no() { return this.updateUnits.get('pan_no') };
  // get gst() { return this.updateUnits.get('gst') };
  // get bank_name() { return this.updateUnits.get('bank_name') };
  // get branch_name() { return this.updateUnits.get('branch_name') };
  // get account_no() { return this.updateUnits.get('account_no') };
  // get ifsc_code() { return this.updateUnits.get('ifsc_code') };


}
