import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UnitsService } from 'src/app/services/units.service';
import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css']
})
export class UpdateUserComponent implements OnInit {

  updateUsers: FormGroup;
  id: any;
  alert: boolean = false
  public imagePath;
  imgURL: any;
  public message: string;

  companyNamesList:any = [];

  //for image setting
  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsersService,
    private unitService: UnitsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    // console.warn(this.router.snapshot.params.id)
    this.updateUsers = this.fb.group({
      add_photo: [''],
      user_name: ['', Validators.required],
      company_name: ['', Validators.required],
      company_address: ['', Validators.required],
      // user_login_id: ['', Validators.required],
      user_email: ['', Validators.required],
      // password: ['', Validators.required],
      // repeat_password: ['', Validators.required],
    })
    this.userService.getCurrentUsers().subscribe((result) => {
      console.warn(result)
      if (result !== null) {
        this.updateUsers.setValue({
          add_photo: result['user_image'],
          user_name: result['user_name'],
          company_name: result['user_compnay_name'],
          company_address: result['user_company_address'],
          // user_login_id: result['user_loginid'],
          user_email: result['user_email'],
        })
      }
    })
    this.id = this.userService.uid;

    this.loadCompanyNames();
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  loadCompanyNames() {
    this.unitService.getUnitNameAddress().subscribe(data => {
      this.companyNamesList = data;
    })
  }
  companyNamesCall(unitId) {
    this.companyNamesList.forEach(element => {
      if (unitId === element.com_id) {
        this.updateUsers.patchValue({
          company_name: element.com_name,
          company_address: element.com_address1
        })
      }
    });
  }

  getValues() {
    // console.warn("item", this.updateProduct.value)
    this.userService.updateUsers(this.id, this.updateUsers.value).subscribe((result) => {
      if (result['status'] === 200) {
        this.showSuccess(result['message']);
      }
      console.warn("item", result);
      this.router.navigateByUrl('/dashboard/viewUser');
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
      }
    })
  }

  closeAlert() {
    this.alert = false
  }
  //for vaildators
  // get add_photo() { return this.updateUsers.get('add_photo') };
  get user_name() { return this.updateUsers.get('user_name') };
  get company_name() { return this.updateUsers.get('company_name') };
  get company_address() { return this.updateUsers.get('company_address') };
  // get user_login_id() { return this.updateUsers.get('user_login_id') };
  get user_email() { return this.updateUsers.get('user_email') };
  // get password() { return this.updateUsers.get('password') };
  // get repeat_password() { return this.updateUsers.get('repeat_password') };


}
