<breadcrumb #parent>
  <ol class="breadcrumb">
      <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <li *ngIf="!route.terminal" class="breadcrumb-item">
            <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]" style="color: black;">&nbsp;{{ route.displayName }}</a>
          </li>
          <li *ngIf = "route.terminal" class="breadcrumb-item active">
              {{ route.displayName}}
          </li>
      </ng-template>
  </ol>
</breadcrumb>

<div class="container">
    <h1 class="mat-h1">Units</h1>
    <p class="mat-caption">All registered Units</p>

    <div style="display: flex; float: right;">
      <form [formGroup]="showNUnit" class="form-group col-xs-2 float-right">
        <!-- <label for="exampleFormControlSelect1">Example select</label> -->
        <select class="form-control" id="showUnitNum" formControlName = "selectNUnit">
          <option value="1" >25</option>
          <option value="2">50</option>
          <option value="3">75</option>
          <option value="4">100</option>
        </select>
    </form>
    &nbsp;&nbsp;&nbsp;
    <button mat-flat-button class="btn float-right" style="background-color: coral; height: 40px; border-radius: 10px;" [routerLink]="['../addUnit']">Add Unit</button>
    </div>  
    <hr>
    <!-- <button mat-flat-button class="btn float-right" style="background-color: coral;" [routerLink]="['../addUnit']">Add Unit</button>
  
  <hr> -->
  
    <table class="table table-hover">
      <thead class="thead-light">
        <tr>
          <th scope="col">SNo.</th>
          <th scope="col">Company Name</th>
          <th scope="col">User</th>
          <th scope="col">City</th>
          <th scope="col">State</th>
          <th scope="col">Contact Person</th>
          <th scope="col">Created Date</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of collection">
          <th scope="row">{{item.com_id}}</th>
          <td>{{item.com_name}}</td> 
          <td>{{item.user.user_name}}</td>
          <td>{{item.com_city}}</td>
          <td>{{item.state.state_name}}</td>
          <td>{{item.com_contact_person}}</td>
          <td>{{item.com_created | date : ddmmyyyy}}</td>
      
          <th>
            <a (click)="deleteUnit(item.com_id)"><i class="fa fa-trash" style="font: size 30px;color:red;"></i></a>
            <a (click)="lcUpdateUnit(item.com_id)"><i class="fa fa-edit" style="font: size 30px;color:blue;"></i></a>
            <a (click)="lcprintUnit(item.com_id)"><i class="fa fa-print" style="font: size 30px;color:blue;"></i></a>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
