import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-update-product',
  templateUrl: './update-product.component.html',
  styleUrls: ['./update-product.component.css']
})
export class UpdateProductComponent implements OnInit {
  updateProduct: FormGroup;
  alert: boolean = false;
  id: any;

  constructor(
    private productService: ProductService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    // console.warn(this.router.snapshot.params.id)
    this.updateProduct = this.fb.group({
      code: ['', Validators.required],
      name: ['', Validators.required],
      product_rate: ['', Validators.required],
    })
    this.productService.getCurrentProduct().subscribe((result) => {
      console.warn(result);
      if(result !== null) {
        this.updateProduct.setValue({
          code: result['p_code'],
          name: result['p_name'],
          product_rate: result['p_rate']
        })
      } 
    })
    this.id = this.productService.pid;
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  getValues() {
    // console.warn("item", this.updateProduct.value)
    this.productService.updateProduct(this.id, this.updateProduct.value).subscribe((result) => {
      // this.alert = true
      if (result['status'] === 200) {
        this.showSuccess(result['message']);
      }
      this.updateProduct.reset({})
      this.router.navigateByUrl('/dashboard/viewProduct');
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
      }
      if (error.status === 400) {
        this.showError(error.error);
      }
    })
  }

  closeAlert() {
    this.alert = false
  }

  //for vaildators
  get code() { return this.updateProduct.get('code') };
  get name() { return this.updateProduct.get('name') };
  get product_rate() { return this.updateProduct.get('product_rate') };

}
