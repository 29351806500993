import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReceiptService } from 'src/app/services/receipt.service';
import { LedgerService } from 'src/app/services/ledger.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-receipt',
  templateUrl: './update-receipt.component.html',
  styleUrls: ['./update-receipt.component.css']
})
export class UpdateReceiptComponent implements OnInit {

  alert:boolean=false
  id:any;

  editReceipt :  FormGroup;

  valuedate = new Date();

  minDate: Date;
  maxDate: Date;

  partyNameList:any = [];
  partyDetailsList:any = []
  
  constructor(
    private fb: FormBuilder,
    private editReceiptService:ReceiptService,
    private partyName: LedgerService,
    private router: Router,
    private toastr: ToastrService
    ) { 
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 15000);
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit(): void {

    // this.router.routeReuseStrategy.shouldReuseRoute = () => {
    //   return false;
    // }

    // console.warn(this.router.snapshot.params.id)
    this.editReceipt = this.fb.group({
      receipt_no: ['', Validators.required],
      receipt_date: ['', Validators.required],
      paymode: ['', Validators.required],
      selectParty: ['', Validators.required],
      party_address: [''],
      party_mobile: [''],
      amount: ['', Validators.required],
      remark: ['']
      });
    this.editReceiptService.getCurrentReceipt().subscribe((result)=>{
      console.warn(result);
      if(result !== null) {
        this.editReceipt.setValue({
          receipt_no: result['r_voucher_no'],
          receipt_date: result['r_date'],
          paymode: result['r_payment_type'],
          selectParty: result['r_party_id'],
          party_address: '',
          party_mobile: '',
          amount: result['r_amount'],
          remark: result['r_remark']
        })
        this.partyCalling(result['r_party_id']);
      }
    })
    this.id = this.editReceiptService.rid;
    this.loadPartyName();
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  //funciton to format date
  private formatDate(date) {
    // const d = new Date(date);
    const d = this.valuedate;
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  partyCalling(val:Int16Array) {
    this.loadPartyDetails(val);
  }
  
  loadPartyName() {
    this.partyName.getPartyName().subscribe((data:any) => {
      this.partyNameList = data;
    })
  }

  loadPartyDetails(id) {
    this.partyName.getPartyDetails(id).subscribe((data:any) => {
      this.partyDetailsList = data;
      this.pakdoPartyDetailsKo();
    })
  }

  pakdoPartyDetailsKo(){
    this.editReceipt.patchValue({
      // party_name: this.partyDetailsList !== null ? this.partyDetailsList.ledger_name: '',
      party_address: this.partyDetailsList !== null ? this.partyDetailsList.ledger_address_line1: '',
      party_mobile: this.partyDetailsList !== null ? this.partyDetailsList.ledger_mobile: ''
    })
  }

  updateReceipt(){
    // console.warn("item", this.editReceipt.value)
    this.editReceiptService.updateReceipt(this.id, this.editReceipt.value).subscribe((result)=>{
      // this.alert=true
      if (result['status'] === 200) {
        this.showSuccess(result['message']);
      }
      console.warn(result);
      this.router.navigateByUrl('/dashboard/viewReceipt');
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
      }
      if (error.status === 400) {
        this.showError(error.error);
      }
    })

    this.editReceipt.reset({})
    this.editReceipt.controls.receipt_date.patchValue(this.formatDate(new Date()));
  }

  closeAlert()
  {
    this.alert=false
  }

  get receipt_no(){return this.editReceipt.get('receipt_no')};
  get receipt_date(){return this.editReceipt.get('receipt_date')};
  get paymode(){return this.editReceipt.get('paymode')};
  get selectParty(){return this.editReceipt.get('selectParty')};
  get party_address(){return this.editReceipt.get('party_address')};
  get party_mobile(){return this.editReceipt.get('party_mobile')};
  get amount(){return this.editReceipt.get('amount')};
  get remark(){return this.editReceipt.get('remark')};

}
