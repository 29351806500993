import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LedgerService {

  lid:any;

  // url = "http://15.206.21.1:3000/ledgers"
  // rooturl = "http://15.206.21.1:3000"
  rooturl = environment.rooturl;
  url = `${this.rooturl}/ledgers`;

  constructor(private http:HttpClient) { }

  saveLedger(data)
  {
    // console.warn(data)
    return this.http.post(this.url+'/add',data)  
  }

  public getLedger()
  {
    // console.warn(this.http.get(this.url))
    return this.http.get(this.url)  
  }

  getPaginatedLedger(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('p', data.pageNo);
    params = params.append('s', data.pageSize);
    if (data.ledger_name !== '') {
      params= params.append('ledger_name', data.ledger_name);
    }
    if (data.ledger_ledgertype_id!== 0) {
      params= params.append('ledger_ledgertype_id', data.ledger_ledgertype_id);
    }
    return this.http.get<any>(`${this.url}/pagination`,{params: params})
  }

  getPartyName():Observable<any[]> {
    return this.http.get<any>(this.url + '/party-name');
  }

  getVehicleName():Observable<any[]> {
    return this.http.get<any>(this.url + '/vehicle-name');
  }

  getPartyDetails(id):Observable<any[]> {
    return this.http.get<any>(this.url+`/${id}/challans`);
  }

  getCurrentLedger():Observable<any[]> {
    return this.http.get<any>(this.url+`/update/${this.lid}`);
  }

  updateLedger(id, data)
  {
    return this.http.patch(`${this.url}/${id}`, data)
  }

  doDeleteLedger(id, data) {
    return this.http.patch(`${this.url}/delete/${id}`, data);
  }
}
