import { Component, OnInit } from '@angular/core';
import { UnitsService } from 'src/app/services/units.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-units',
  templateUrl: './view-units.component.html',
  styleUrls: ['./view-units.component.css']
})
export class ViewUnitsComponent implements OnInit {


  showNUnit: FormGroup;
  collection: any = [];
  count = 0;
  status = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private viewUnitService: UnitsService,
    private fb : FormBuilder,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
    this.showNUnit = this.fb.group({
      selectNUnit : ['1']
    })
    this.getUnitData();
  }

  getUnitData() {
    this.viewUnitService.getUnits().subscribe((result) => {
      console.warn(result);
      this.collection = result
    })
  }

  public lcUpdateUnit(item) {
    this.viewUnitService.tid = item;
    this.router.navigateByUrl('/dashboard/updateUnit');
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  // fake data for temporary deletion
  fakeData = {
    com_status: 0,
    com_is_deleted: 1
  }

  public deleteUnit(id) {
    if(confirm("Are you sure to delete ")){
      this.viewUnitService.destroyUnits(id, this.fakeData).subscribe(result => {
        if (result['status']=== 200) {
          this.showSuccess(result['message']);
        }
      }, error => {
        if (error.status === 500) {
          this.showError(error.error.message);
          window.location.reload(); 
        }
        if (error.status === 400) {
          this.showError(error.error);
          window.location.reload(); 
        }
      })
      this.getUnitData();
    }
  }

}
