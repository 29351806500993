<breadcrumb #parent>
  <ol class="breadcrumb">
    <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
      <li *ngIf="!route.terminal" class="breadcrumb-item">
        <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]"
          style="color: black;">&nbsp;{{ route.displayName }}</a>
      </li>
      <li *ngIf="route.terminal" class="breadcrumb-item active">
        {{ route.displayName}}
      </li>
    </ng-template>
  </ol>
</breadcrumb>

<div class="container">
  <!-- <h4>Add Challan</h4> -->

  <!-- success alert message start -->
  <div *ngIf="alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Success</strong> Your data has been entered.
    <button (click)="closeAlert()" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- success alert message end -->

  <div class="container-fluid" style="border: 1px solid lightgrey;">
    <form [formGroup]="addChallan" (ngSubmit)="getValues()">
      <div class="panel panel-primary">
        <div class="panel-body pull-left">
          <mat-icon [inline]="true">library_books</mat-icon>&nbsp; <span style="color: black; font-size: larger;">Add
            Challan</span>
          <hr style="padding-top: 0; margin-top: 0;">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <label id="labelcolor">Challan No.*</label><br>
          <input type="text" class="form-control" formControlName="challan_no" required readonly />
          <span class="error" *ngIf="challan_no.invalid && challan_no.touched">Please enter a valid challan
            number</span>
        </div>

        <div class="form-group col-md-4">
          <label id="labelcolor">Date *</label><br>
          <input class="form-control" id="challan_date" formControlName="challan_date" [minDate]="minDate"
            [maxDate]="maxDate" bsDatepicker #datepickerYMD="bsDatepicker" name="date"
            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" required />
          <!-- <span class="error" *ngIf="challan_date.invalid && challan_date.touched">Please enter a valid
                            challan date</span> -->
        </div>

        <div class="form-group col-md-4">
          <label id="labelcolor">Time *</label><br>
          <input type="time" class="form-control" id="challan_time" formControlName="challan_time" required>
          <!-- <span class="error" *ngIf="challan_time.invalid && challan_time.touched">Please enter a valid
                        challan time</span> -->
        </div>

        <div class="form-group col-md-4" *ngIf="show1">
          <label name="labelcolor">Unit *</label><br>
          <!-- <input type="number" class="form-control" formControlName="unit" required /> -->
          <ng-select class="form-control" formControlName="unit">
            <ng-option value="0" selected>--Select Unit--</ng-option>
            <ng-option id="unit_Name" *ngFor="let unitName of unitNameList" [value]="unitName.com_id">{{
              unitName.com_name }}</ng-option>
          </ng-select>
          <!-- <span class="error" *ngIf="unit.invalid && unit.touched">Please select a valid unit name</span> -->
        </div>

        <div class="form-group col-md-4" *ngIf="show2">
          <label name="labelcolor">Unit *</label><br>
          <!-- <input type="number" class="form-control" formControlName="unit" required /> -->
          <input type="text" class="form-control" formControlName="unit" readonly/>
          <!-- <span class="error" *ngIf="unit.invalid && unit.touched">Please select a valid unit name</span> -->
        </div>

        <div class="form-group col-md-4">
          <label name="labelcolor">Party * <mat-icon class="material-icons" [inline]="true"
              [routerLink]="['../addLedger']">add_circle_outline</mat-icon></label><br>
          <ng-select class="form-control" placeholder="--Select Party Name--" formControlName="selectParty"
            (ngModelChange)="partyCall($event)">
            <ng-option value="" selected>--Select Party Name--</ng-option>
            <!-- <div *ngIf="selectParty.untouched">
              <ng-template ng-multi-label-tmp>
                --Select Party Name--
              </ng-template>
            </div> -->
            <ng-option id="challan_party" *ngFor="let partyName of partyNameList" [value]="partyName.ledger_id">{{
              partyName.ledger_name }}</ng-option>
          </ng-select>
          <span class="error" *ngIf="selectParty.invalid && selectParty.touched">Please select a valid party
            name</span>
        </div>

        <div class="col-md-4"></div>
        <div class="form-group col-md-4">
          <label id="labelcolor">Party Name *</label><br>
          <input type="text" class="form-control" formControlName="party_name" />
        </div>

        <!-- <div class="form-group col-md-4">
          <label>Party Address</label><br>
          <input type="text" class="form-control" formControlName="party_address" />
        </div> -->
        <div class="form-group col-md-4" *ngIf="showAdd">
          <label>Party Address</label><br>
          <input type="text" class="form-control" formControlName="party_address" />
        </div>

        <div class="form-group col-md-4" *ngIf="showAddP">
          <label>Party Address</label><br>
          <ng-select class="form-control" placeholder="--Select Party Address--" formControlName="party_address">
            <ng-option *ngFor="let partyAdd of ledgerAddressList" [value]="partyAdd">{{ partyAdd }}</ng-option>
          </ng-select>
        </div>

        <div class="form-group col-md-4">
          <label>Party Mobile</label><br>
          <input type="tel" class="form-control" formControlName="party_mobile" />
        </div>

        <div class="form-group col-md-4">
          <label>पार्टी का नाम</label><br>
          <input type="text" class="form-control" formControlName="party_name_hindi" />
        </div>

        <div class="form-group col-md-4">
          <label>पार्टी का पता</label><br>
          <input type="text" class="form-control" formControlName="party_address_hindi" />
        </div>
        <div class="col-md-4"></div>
        <div class="form-group col-md-4">
          <label id="labelcolor">Vehicle *<mat-icon class="material-icons" [inline]="true"
              [routerLink]="['../addLedger']">add_circle_outline</mat-icon></label><br>
          <ng-select class="form-control" placeholder="--Select Vehicle--" formControlName="vehicleInfo" required>
            <ng-option value="">--Select Vehicle--</ng-option>
            <!-- <div *ngIf="vehicleInfo.untouched">
              <ng-template ng-multi-label-tmp>
                --Select Vehicle--
              </ng-template>
            </div> -->
            <ng-option id="challan_vehicle" *ngFor="let partyName of vehicleNameList" [value]="partyName.ledger_id">{{
              partyName.ledger_name }}</ng-option>
          </ng-select>
          <span class="error" *ngIf="vehicleInfo.invalid && vehicleInfo.touched">Please select a valid
            vehicle</span>
        </div>

        <div class="form-group col-md-4">
          <label>Driver Name </label><br>
          <input type="text" class="form-control" formControlName="driver_name" />
          <!-- <span class="error" *ngIf="driver_name.invalid && driver_name.touched">Please enter a valid driver
                        name</span> -->
        </div>

        <div class="form-group col-md-4">
          <label>Driver Mobile</label><br>
          <input type="tel" class="form-control" formControlName="driver_mobile" />
          <!-- <span class="error" *ngIf="driver_mobile.invalid && driver_mobile.touched">Please enter a valid
                        mobile number</span> -->
        </div>



        <div class="form-group col-md-12">
          <label id="labelcolor">Product *</label><br>
          <ng-select class="form-control" placeholder="--Select Product--" formControlName="productInfo" required>
            <ng-option value="">--Select Product--</ng-option>
            <!-- <div *ngIf="productInfo.untouched">
              <ng-template ng-multi-label-tmp>
                --Select Product--
              </ng-template>
            </div> -->
            <ng-option id="product_Info" *ngFor="let proName of productNameList" [value]="proName.p_id">{{
              proName.p_name }}</ng-option>
          </ng-select>
          <span class="error" *ngIf="productInfo.invalid && productInfo.touched">Please select a valid product
            name</span>
        </div>

        <div class="form-group col-md-4">
          <label id="labelcolor">QTY (CFT/TON) *</label><br>
          <input type="number" class="form-control" formControlName="qty" required />
          <span class="error" *ngIf="qty.invalid && qty.touched">Please enter a valid quantity</span>
        </div>

        <div class="form-group col-md-4">
          <label id="labelcolor">Rate *</label><br>
          <input type="number" class="form-control" formControlName="rate" required />
          <span class="error" *ngIf="rate.invalid && rate.touched">Please enter a valid rate</span>
        </div>

        <div class="form-group col-md-4">
          <label id="labelcolor">Total Amount *</label><br>
          <input type="number" class="form-control" formControlName="total_amount" required />
        </div>

        <div class="form-group col-md-4">
          <label id="labelcolor">Payment *</label><br>
          <ng-select class="form-control" placeholder="--Select Payment Type--" formControlName="paymentMethod" required>
            <ng-option name="payment" value="">--Select Payment Type--</ng-option>
            <!-- <div *ngIf="paymentMethod.untouched">
              <ng-template ng-multi-label-tmp>
                Select Payment Type
              </ng-template>
            </div> -->
            <ng-option name="payment" value="1">Credit</ng-option>
            <ng-option name="payment" value="2">Cash</ng-option>
            <ng-option name="payment" value="3">Due/Adv.</ng-option>
          </ng-select>
          <span class="error" *ngIf="paymentMethod.invalid && paymentMethod.touched">Please select a valid
            payment method</span>
        </div>

        <div class="form-group col-md-2">
          <label id="labelcolor">Advance Amount *</label><br>
          <input type="number" class="form-control" formControlName="advance_amount" />
          <span class="error" *ngIf="advance_amount.invalid && advance_amount.touched">Please enter a valid
            amount</span>
        </div>

        <div class="form-group col-md-2">
          <label>Balance Amount</label><br>
          <input type="number" class="form-control" formControlName="balance_sheet" />
        </div>

        <div class="form-group col-md-4">
          <label>Remark</label><br>
          <input type="text" class="form-control" formControlName="remark" />
        </div>

        <div class="form-group col-md-12">
          <h5>Challan Details :</h5>
          <hr>
        </div>

        <div class="form-group col-md-4">
          <label>Ravana No.</label><br>
          <input type="number" class="form-control" formControlName="ravana_number" />
          <!-- <span class="error" *ngIf="ravana_number.invalid && ravana_number.touched">Please enter a valid
                        ravana number</span> -->
        </div>

        <div class="form-group col-md-4">
          <label>Weight (MT)</label><br>
          <input type="number" class="form-control" formControlName="Weight" />
          <!-- <span class="error" *ngIf="Weight.invalid && Weight.touched">Please enter a valid weight</span> -->
        </div>

        <div class="form-group col-md-4">
          <label>Royalty Amount</label><br>
          <input type="number" class="form-control" formControlName="Royalty_amount" />
          <!-- <span class="error" *ngIf="Royalty_amount.invalid && Royalty_amount.touched">Please enter a valid
                        amount</span> -->
        </div>
      </div>
      <hr>
      <div class="form-group col-md-12">
        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
          <div class="btn-group" role="group" aria-label="First group">
            <button type="submit" class="btn btn-primary" [disabled]="addChallan.invalid" data-toggle="tooltip" data-placement="top"
              title="">Submit</button> &nbsp; &nbsp;
            <button type="button" [disabled]="addChallan.invalid" class="btn btn-secondary"
              placeholder="Submit & Print" (click)="submitPrint($event)">Submit & Print</button> &nbsp; &nbsp;
            <button type="reset" [disabled]="true" class="btn btn-danger" placeholder="Reset">Reset</button>
          </div>
        </div>
        <small style="color: red;">Submit button will be enabled when all the mandatory fields are filled.</small>
      </div>
    </form>
    <div class="white-space"></div>
  </div>
</div>
