<breadcrumb #parent>
  <ol class="breadcrumb">
      <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <li *ngIf="!route.terminal" class="breadcrumb-item">
            <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]" style="color: black;">&nbsp;{{ route.displayName }}</a>
          </li>
          <li *ngIf = "route.terminal" class="breadcrumb-item active">
              {{ route.displayName}}
          </li>
      </ng-template>
  </ol>
</breadcrumb>

<div class="container">
    <h1 class="mat-h1">Products</h1>
    <p class="mat-caption">All registered Products</p>
    <div style="display: flex; float: right;">
      <form [formGroup]="showNProduct" class="form-group col-xs-2 float-right">
        <!-- <label for="exampleFormControlSelect1">Example select</label> -->
        <select class="form-control" id="showProductNum" formControlName = "selectNProduct">
          <option value="1" >25</option>
          <option value="2">50</option>
          <option value="3">75</option>
          <option value="4">100</option>
        </select>
    </form>
    &nbsp;&nbsp;&nbsp;
    <button mat-flat-button class="btn float-right" style="background-color: coral; height: 40px; border-radius: 10px;" [routerLink]="['../addProduct']">Add Product</button>
    </div>  
    <hr>
    <!-- <button mat-flat-button class="btn float-right" style="background-color: coral;" [routerLink]="['../addProduct']">Add Product</button>
  
  <hr> -->
  
    <table class="table">
      <thead>
        <tr>
          <th scope="col">SNo.</th>
          <th scope="col">Product code</th>
          <th scope="col">Product Name</th>
          <th scope="col">Creation Date</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>
            <form [formGroup]="searchProductCodeForm">
              <div class="form-group col-xs-2">
                <select  class="form-control"  formControlName="productCodeList" style="font-size: small;">
                  <option value="0" selected>All</option>
                  <option id="productList"  *ngFor="let productName of productNameList"  [value]="productName.p_id" >{{ productName.p_name }}</option>
             </select>
              </div>
            </form>
          </td>
          <td>
            <form [formGroup]="searchProductForm">
              <div class="form-group col-xs-2">
                <select  class="form-control"  formControlName="productList" style="font-size: small;">
                  <option value="0" selected>All</option>
                  <option id="productList"  *ngFor="let productName of productNameList"  [value]="productName.p_id" >{{ productName.p_name }}</option>
             </select>
              </div>
            </form>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr *ngFor="let item of collection | paginate : {itemsPerPage:pageSize, currentPage:pageNo, totalItems: count}; let i = index">
          <th scope="row">{{i+1}}</th>
          <td>{{item.p_code}}</td> 
          <td>{{item.p_name}}</td>
          <td>{{item.p_created | date: mmddyyyy}}</td>      
          <th><a (click)="deleteProduct(item.p_id)"><i class="fa fa-trash" style="font: size 30px;color:red;"> &nbsp;&nbsp;
          </i></a><span></span>
          <a (click)="lcUpdateProduct(item.p_id)"><i class="fa fa-edit" style="font: size 30px;color:blue;"></i></a>
        </th>
        </tr>
      </tbody>
      <tr><td colspan="3"><pagination-controls (pageChange) = "handlePageChange($event)"></pagination-controls></td></tr>
    </table>
  </div>