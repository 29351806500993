import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { environment } from '../../environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  uid:any;

  // url = "http://15.206.21.1:3000/users"
  // rooturl = "http://15.206.21.1:3000"
  rooturl = environment.rooturl;
  url = `${this.rooturl}/users`;

  constructor(private http:HttpClient) { }

  saveUsers(data)
  {
    // console.warn(data)
    return this.http.post(this.url+'/add',data);
  }

  public getUsers()
  {
    // console.warn(this.http.get(this.url))
    return this.http.get(this.url);
  }

  getUsersAddress():Observable<any[]> {
    return this.http.get<any>(`${this.url}/address`);
  }

  getUserID():Observable<any[]> {
    return this.http.get<any>(`${this.url}/userid`);
  }

  /*get user name from user table*/
  getUsersName():Observable<any[]> {
    return this.http.get<any>(this.url + '/usernames');
  }

  getCurrentUsers():Observable<any[]> {
    return this.http.get<any>(`${this.url}/update/${this.uid}`);
  }

  getIdAndUsername():Observable<any[]> {
    return this.http.get<any>(`${this.url}/usernames`);
  }

  updateUsers(id, data)
  {
    return this.http.patch(`${this.url}/${id}`, data)
  }
  doDeleteUser(id, data) {
    return this.http.patch(`${this.url}/delete/${id}`, data)
  }

}
