import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UnitsService } from 'src/app/services/units.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  addUsers: FormGroup;
  alert: boolean = false
  public imagePath;
  imgURL: any;
  public message: string;

  companyNamesList:any = [];
  companyAddressList:any = [];

  //for image setting
  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsersService,
    private unitService: UnitsService,
    private toastr: ToastrService) { }

  getValues() {
    // console.warn(this.addUsers.value);
    this.userService.saveUsers(this.addUsers.value).subscribe((result) => {
      // this.alert = true
      if (result['status']=== 200) {
        this.showSuccess(result['message']);
        this.addUsers.reset({})
      }
      if (result['status']=== 208) {
        this.showWarning(result['message']);
      }
      console.log(result)
    }, error => {
      if (error.status === 400) {
        this.showError(error.error);
        window.location.reload(); 
      }
      if (error.status === 500) {
        this.showError(error.error.message);
        window.location.reload(); 
      }
    })
  }
  closeAlert() {
    this.alert = false
  }


  ngOnInit(): void {
    this.addUsers = this.fb.group({
      add_photo: [''],
      user_name: ['', Validators.required],
      company_name: ['0', Validators.required],
      company_address: ['', Validators.required],
      user_login_id: ['', Validators.required],
      user_email: ['', Validators.required],
      password: ['', Validators.required],
      repeat_password: ['', Validators.required],
    })
    this.loadCompanyNames();

  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showWarning(messageNotify: string) {
    this.toastr.warning('Failed!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  loadCompanyNames() {
    this.unitService.getUnitNameAddress().subscribe(data => {
      this.companyNamesList = data;
    })
  }
  companyNamesCall(unitId) {
    this.companyNamesList.forEach(element => {
      if (unitId === element.com_id) {
        this.addUsers.patchValue({
          company_name: element.com_name,
          company_address: element.com_address1
        })
      }
    });
  }


  //for vaildators
  // get add_photo() { return this.addUsers.get('add_photo') };
  get user_name() { return this.addUsers.get('user_name') };
  get company_name() { return this.addUsers.get('company_name') };
  get company_address() { return this.addUsers.get('company_address') };
  get user_login_id() { return this.addUsers.get('user_login_id') };
  get user_email() { return this.addUsers.get('user_email') };
  get password() { return this.addUsers.get('password') };
  get repeat_password() { return this.addUsers.get('repeat_password') };

}
