import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddReceiptComponent } from './add-receipt/add-receipt.component';
import { ViewReceiptComponent } from './view-receipt/view-receipt.component';
import { UpdateReceiptComponent } from './update-receipt/update-receipt.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MaterialModule } from '../material/material.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { BreadcrumbModule } from 'angular-crumbs';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [AddReceiptComponent, ViewReceiptComponent, UpdateReceiptComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    Ng2SearchPipeModule,
    Ng2OrderModule,
    NgxPaginationModule,
    DashboardModule,
    MaterialModule,
    NgSelectModule,
    BreadcrumbModule,
    RouterModule
  ],
  exports:[AddReceiptComponent,
    ViewReceiptComponent,
    UpdateReceiptComponent
  ]
})
export class ReceiptModule { }
