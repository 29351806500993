<breadcrumb #parent>
  <ol class="breadcrumb">
      <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <li *ngIf="!route.terminal" class="breadcrumb-item">
            <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]" style="color: black;">&nbsp;{{ route.displayName }}</a>
          </li>
          <li *ngIf = "route.terminal" class="breadcrumb-item active">
              {{ route.displayName}}
          </li>
      </ng-template>
  </ol>
</breadcrumb>

<div class="container">
  <h4>Add Units</h4>

  <!-- success alert message start -->
  <div *ngIf="alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Success</strong> Your data has been entered.
    <button (click)="closeAlert()" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- success alert message end -->

  <div class="container-fluid" style="border:1px solid rgb(112, 105, 105); border-radius: 2px;">
    <form [formGroup]="addUnits" (ngSubmit)="getValues()">
      <div class="panel panel-primary" style="color: blue;">
        <div class="panel-heading">
          <h3 class="panel-title">Add Units</h3>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-3">
          <label id="labelcolor">Add Photo</label>
        </div>
        <div class="form-group col-md-8">
          <input #file type="file" name="imageUpload" id="imageUpload" class="form-control hide"
            formControlName="add_photo" accept='image/*' (change)="preview(file.files)" />
          <label for="imageUpload" class="btn btn-large">Select file</label>
          <img [src]="imgURL" height="80" *ngIf="imgURL">
          <!-- <span class="error" *ngIf="add_photo.invalid && add_photo.touched"
              >Please enter a valid Photo</span> -->
        </div>

        <!-- <div class="col-md-3">
          <label id="labelcolor">User</label>
        </div>
        <div class="form-group col-md-8">
          <ng-select  class="form-control"  formControlName="user" (ngModelChange)="userNamesCall()" required>
            <ng-option value="" selected>--Select User Name</ng-option>
            <ng-option id="user"  *ngFor="let userName of userNamesList"  [value]="userName.user_id" >{{ userName.user_name }}</ng-option>
          </ng-select>
          <span class="error" *ngIf="user.invalid && user.touched">Please enter a valid user</span>
        </div> -->

        <div class="col-md-3">
          <label id="labelcolor">Unit Name <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="unit_name" required />
          <span class="error" *ngIf="unit_name.invalid && unit_name.touched">Please enter a valid unit name</span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Address-1 <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="address_1" required />
          <!-- <span class="error" *ngIf="address_1.invalid && address_1.touched"
              >Please enter a valid address</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Address-2 <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="address_2" />
          <!-- <span class="error" *ngIf="address_2.invalid && address_2.touched"
              >Please enter a valid address</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Address-3 <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="address_3" />
          <!-- <span class="error" *ngIf="address_3.invalid && address_3.touched"
              >Please enter a valid address</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">City<span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="city" required />
          <span class="error" *ngIf="city.invalid && city.touched">Please enter a valid city</span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">State<span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <ng-select  class="form-control"  formControlName="state" (ngModelChange)="stateNamesCall()" required>
            <ng-option value="" selected>--Select State Name</ng-option>
            <ng-option id="state"  *ngFor="let stateName of stateNamesList"  [value]="stateName.state_id" >{{ stateName.state_name }}</ng-option>
          </ng-select>
          <span class="error" *ngIf="state.invalid && state.touched">Please enter a valid state</span>
        </div>


        <div class="col-md-3">
          <label id="labelcolor">Post Code<span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="postcode" />
          <!-- <span class="error" *ngIf="postcode.invalid && postcode.touched">Please enter a valid postcode</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Email <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="com_email" />
          <!-- <span class="error" *ngIf="com_email.invalid && com_email.touched">Please enter a valid company email</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Contact Person<span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="contact_person" required />
          <span class="error" *ngIf="contact_person.invalid && contact_person.touched">Please enter a valid contact
            person</span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Mobile-1<span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="mobile_1" required />
          <span class="error" *ngIf="mobile_1.invalid && mobile_1.touched">Please enter a valid mobile number</span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Mobile-2<span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="mobile_2" />
          <!-- <span class="error" *ngIf="mobile_2.invalid && mobile_2.touched">Please enter a valid mobile 2</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">PAN No <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="pan_no" />
          <!-- <span class="error" *ngIf="pan_no.invalid && pan_no.touched">Please enter a valid PAN number</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">GST <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="gst" />
          <!-- <span class="error" *ngIf="gst.invalid && gst.touched">Please enter a valid GST number</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Bank Name<span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="bank_name" />
          <!-- <span class="error" *ngIf="bank_name.invalid && bank_name.touched">Please enter a valid Bank name</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Branch Name <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="branch_name" />
          <!-- <span class="error" *ngIf="branch_name.invalid && branch_name.touched">Please enter a valid Branch name</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Account No <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="account_no" />
          <!-- <span class="error" *ngIf="account_no.invalid && account_no.touched">Please enter a valid Account number</span> -->
        </div>
        <div class="col-md-3">
          <label id="labelcolor">IFSC Code <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="ifsc_code" />
          <!-- <span class="error" *ngIf="ifsc_code.invalid && ifsc_code.touched">Please enter a valid IFSC code</span> -->
        </div>

        <div class="col-md-3"></div>
        <div class="form-group col-md-8">
          <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group" role="group" aria-label="First group">
              <button class="btn btn-primary" [disabled]="addUnits.invalid" data-toggle="tooltip" data-placement="top"
                title="">
                Submit
              </button>
              &nbsp; &nbsp;
              <!-- <button type="submit" [disabled]="addUnits.invalid" class="btn btn-secondary"
                    placeholder="Submit & Print">
                    Submit & Print
                  </button>
                  &nbsp; &nbsp; -->
              <button type="reset" [disabled]="false" class="btn btn-danger" placeholder="Reset">
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
