<div class="app-container">
  <mat-toolbar id="au-navbar">
    <div class="sidenavIcon">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon [inline]="true" style="color: white;">menu</mat-icon>
      </button>
    </div>
    <span>
      <h1 style="padding-left: 10px;"><span style="color: coral;">ALANKAR</span>&nbsp;<span
          style="color: white;">UDHYOG</span> </h1>
    </span>

    <span class="toolbar-spacer"></span>
    <button class="nav-item" mat-flat-button [routerLink]="['./dCard']">
      <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon>
      Dashboard
    </button>
    <mat-divider [vertical]="true"></mat-divider>
    <button class="nav-item" mat-flat-button [routerLink]="['./addChallan']">
      <mat-icon class="nav-item-icon" [inline]="true">library_books</mat-icon>
      Add Challan
    </button>
    <mat-divider [vertical]="true"></mat-divider>
    <mat-menu #appMenu="matMenu">
      <ng-template matMenuContent>
        <button mat-menu-item [routerLink]="['./setting']">Settings</button>
        <button mat-menu-item [routerLink]="['./profile']">Profile</button>
        <button mat-menu-item (click)=" logout()">Logout</button>
      </ng-template>
    </mat-menu>

    <button mat-flat-button class="nav-item_myAcc" [matMenuTriggerFor]="appMenu" id="myAccBtn">
      <mat-icon class="nav-item-icon">account_circle</mat-icon>
    </button>

    <!-- <button class="nav-item" mat-flat-button (click)="logout()">
            <mat-icon class="nav-item-icon" [inline]="true">exit_to_app</mat-icon>
            Logout
        </button> -->
    <!-- <a class="nav-item nav-link" style="color: wheat;" (click)="logout()">Logout</a> -->
  </mat-toolbar>
  <mat-sidenav-container class="sidenavcontainer">
    <mat-sidenav #sidenav [mode]="isSide" position="start" [opened]="isShowing">
      <mat-list class="menu">
        <!-- <mat-card-header>
                    <div mat-card-minemgmt class="minemgmt"></div>
                </mat-card-header> -->
        <div class="icon-bg-color">
          <a mat-tab-link [routerLink]="'/'">
            <img src="./assets/logo.png">
          </a>
        </div>
        <div class="menu-list-item">
          <mat-list-item>
            <button class="menu-item" mat-flat-button [routerLink]="['./dCard']">
              <mat-icon>dashboard</mat-icon> &nbsp; &nbsp;
              Dashboard
            </button>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <button class="menu-item" mat-flat-button (click)="toggleMSM()">
              <mat-icon>settings</mat-icon>&nbsp; &nbsp;
              Manage Masters
            </button><span><br></span>
          </mat-list-item>
          <mat-divider></mat-divider>
          <div class="sub-menu" *ngIf="showMaster" style="padding-left: 15px;">
            <mat-list-item class="menu-item">
              <button class="menu-item" mat-flat-button (click)="toggleLSM()">
                <mat-icon>category</mat-icon>
                Ledger
              </button>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div class="sub-menu-item" *ngIf="showLSM" style="padding-left: 25px;">
              <mat-list-item class="nav-sub-menu-item">
                <button class="menu-item" mat-flat-button [routerLink]="['./addLedger']">
                  <mat-icon>category</mat-icon>&nbsp; &nbsp;
                  Add Ledger
                </button>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item class="nav-sub-menu-item">
                <button class="menu-item" mat-flat-button [routerLink]="['./viewLedger']">
                  <mat-icon>category</mat-icon>&nbsp; &nbsp;
                  Edit/View Ledger
                </button>
              </mat-list-item>
              <mat-divider></mat-divider>
            </div>
            <mat-list-item>
              <button class="menu-item" mat-flat-button (click)="togglePSM()">
                <mat-icon>category</mat-icon>
                Product
              </button>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div class="sub-menu-item" *ngIf="showPSM" style="padding-left: 25px;">
              <mat-list-item class="nav-sub-menu-item">
                <button class="menu-item" mat-flat-button [routerLink]="['./addProduct']">
                  <mat-icon>category</mat-icon>&nbsp; &nbsp;
                  Add Product
                </button>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item class="nav-sub-menu-item">
                <button class="menu-item" mat-flat-button [routerLink]="['./viewProduct']">
                  <mat-icon>category</mat-icon>&nbsp; &nbsp;
                  View/Edit Product
                </button>
              </mat-list-item>
              <mat-divider></mat-divider>
            </div>
            <mat-list-item>
              <button class="menu-item" mat-flat-button (click)="toggleUSM()">
                <mat-icon>category</mat-icon>
                User
              </button>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div class="sub-menu-item" *ngIf="showUSM" style="padding-left: 25px;">
              <mat-list-item class="nav-sub-menu-item">
                <button class="menu-item" mat-flat-button [routerLink]="['./addUser']">
                  <mat-icon>category</mat-icon>&nbsp; &nbsp;
                  Add User
                </button>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item class="nav-sub-menu-item">
                <button class="menu-item" mat-flat-button [routerLink]="['./viewUser']">
                  <mat-icon>category</mat-icon>&nbsp; &nbsp;
                  View/Edit User
                </button>
              </mat-list-item>
              <mat-divider></mat-divider>
            </div>
            <mat-list-item>
              <button class="menu-item" mat-flat-button (click)="toggleTSM()">
                <mat-icon>category</mat-icon>
                Unit
              </button>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div class="sub-menu-item" *ngIf="showTSM" style="padding-left: 25px;">
              <mat-list-item class="nav-sub-menu-item">
                <button class="menu-item" mat-flat-button [routerLink]="['./addUnit']">
                  <mat-icon>category</mat-icon>&nbsp; &nbsp;
                  Add Unit
                </button>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item class="nav-sub-menu-item">
                <button class="menu-item" mat-flat-button [routerLink]="['./viewUnit']">
                  <mat-icon>category</mat-icon>&nbsp; &nbsp;
                  View/Edit Unit
                </button>
              </mat-list-item>
              <mat-divider></mat-divider>
            </div>
          </div>
          <mat-list-item>
            <button class="menu-item" mat-flat-button (click)="toggleCSM()">
              <mat-icon>library_books</mat-icon>&nbsp; &nbsp;
              Challan
            </button>
          </mat-list-item>
          <mat-divider></mat-divider>
          <div class="sub-menu-item" *ngIf="showChallan" style="padding-left: 15px;">
            <mat-list-item>
              <button class="menu-item" mat-flat-button [routerLink]="['./addChallan']">
                <mat-icon>library_books</mat-icon>&nbsp; &nbsp;
                Add Challan
              </button>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
              <button class="menu-item" mat-flat-button [routerLink]="['./viewChallan']">
                <mat-icon>library_books</mat-icon>&nbsp; &nbsp;
                Edit/View Challan
              </button>
            </mat-list-item>
            <mat-divider></mat-divider>
          </div>

          <!---------------------receipt menu    ---------------->
          <mat-list-item>
            <button class="menu-item" mat-flat-button (click)="toggleRSM()">
              <mat-icon>receipt</mat-icon>&nbsp; &nbsp;
              Reciept
            </button>
          </mat-list-item>
          <mat-divider></mat-divider>
          <div class="sub-menu-item" *ngIf="showRSM" style="padding-left: 15px;">
            <mat-list-item>
              <button class="menu-item" mat-flat-button [routerLink]="['./addReceipt']">
                <mat-icon>library_books</mat-icon>&nbsp; &nbsp;
                Add Receipt
              </button>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
              <button class="menu-item" mat-flat-button [routerLink]="['./viewReceipt']">
                <mat-icon>library_books</mat-icon>&nbsp; &nbsp;
                Edit/View Receipt
              </button>
            </mat-list-item>
            <mat-divider></mat-divider>
          </div>

          <!---------------------reports/logs menu    ---------------->
          <mat-list-item>
            <button class="menu-item" mat-flat-button (click)="toggleRpSM()">
              <mat-icon>table_view</mat-icon>&nbsp; &nbsp;
              Report/Logs
            </button>
          </mat-list-item>
          <mat-divider></mat-divider>
          <div class="sub-menu-item" *ngIf="showRpSM" style="padding-left: 15px;">
            <mat-list-item>
              <button class="menu-item" mat-flat-button [routerLink]="['./mReport']">
                <mat-icon>library_books</mat-icon>&nbsp; &nbsp;
                Miscellaneous Report
              </button>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
              <button class="menu-item" mat-flat-button [routerLink]="['./vReport']">
                <mat-icon>library_books</mat-icon>&nbsp; &nbsp;
                Vehicle Report
              </button>
            </mat-list-item>
            <mat-divider></mat-divider>
          </div>
        </div>
      </mat-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="main-content">
        <ng-container>
          <router-outlet></router-outlet>
        </ng-container>
      </div>`
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
