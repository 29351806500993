<breadcrumb #parent>
    <ol class="breadcrumb">
        <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
            <li *ngIf="!route.terminal" class="breadcrumb-item">
                <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]" style="color: black;">&nbsp;{{ route.displayName }}</a>
            </li>
            <li *ngIf = "route.terminal" class="breadcrumb-item active">
                {{ route.displayName}}
            </li>
        </ng-template>
    </ol>
</breadcrumb>

<h3>Miscellaneous Reports</h3>
<div class="container">
    <div class="container-fluid" style="border:1px solid lightgrey; border-radius: 20px;">
        <form [formGroup]="mReportForm">
            <!--------------------------form heading------------------------->
            <div class="panel panel-primary">
                <div class="panel-body pull-left">
                    <mat-icon [inline]="true">search</mat-icon>&nbsp; <span style="color: black;">search for the miscellaneous report</span> 
                    <hr style="padding-top: 0; margin-top: 0;">
                </div>
            </div>

            <div class="row">
                <!--------------------Party Name Drop Down------------------->
                <div class="form-group col-md-4">
                    <label name="labelcolor">Party</label><br>
                    <ng-select  class="form-control"  formControlName="partyList">
                        <ng-option value="0" selected>All</ng-option>
                        <ng-option id="partyList"  *ngFor="let partyName of partyNameList"  [value]="partyName.ledger_id" >{{ partyName.ledger_name }}</ng-option>
                   </ng-select>
                   <span class="error" *ngIf="partyList.invalid && partyList.touched">Please select a valid party
                    name</span>
                </div>
                

                <!--------------------Vehicle Name Drop Down------------------->
                <div class="form-group col-md-4">
                    <label name="labelcolor">Vehicle</label><br>
                    <!-- <select class="form-control" formControlName="vehicleList">
                        <option name="vehicle" value="0" selected>All</option>
                        <option *ngFor="let vehicleName of vehicleNameList" [value]="vehicleName.ledger_id">
                            {{ vehicleName.ledger_name }}
                        </option>
                    </select> -->
                    <ng-select  class="form-control"  formControlName="vehicleList">
                        <ng-option value="0" selected>All</ng-option>
                        <ng-option id="vehicleList"  *ngFor="let vehicleName of vehicleNameList"  [value]="vehicleName.ledger_id" >{{ vehicleName.ledger_name }}</ng-option>
                   </ng-select>
                   <span class="error" *ngIf="vehicleList.invalid && vehicleList.touched">Please select a valid vehicle</span>
                </div>

                <!--------------------Product Name Drop Down------------------->
                <div class="form-group col-md-4">
                    <label name="labelcolor">Product</label><br>
                    <!-- <select class="form-control" formControlName="productList">
                        <option name="product" value="0" selected>All</option>
                        <option *ngFor="let productName of productNameList" [value]="productName.p_id">
                            {{ productName.p_name }}
                        </option>
                    </select> -->
                    <ng-select  class="form-control"  formControlName="productList">
                        <ng-option value="0" selected>All</ng-option>
                        <ng-option id="productList"  *ngFor="let productName of productNameList"  [value]="productName.p_id" >{{ productName.p_name }}</ng-option>
                   </ng-select>
                   <span class="error" *ngIf="productList.invalid && productList.touched">Please select a valid vehicle</span>
                </div>

                <!--------------------User Name Drop Down------------------->
                <div class="form-group col-md-4">
                    <label name="labelcolor">User</label><br>
                    <ng-select  class="form-control"  formControlName="userList">
                        <ng-option value="0" selected>All</ng-option>
                        <ng-option id="userList"  *ngFor="let userName of userNameList"  [value]="userName.user_id" >{{ userName.user_name }}</ng-option>
                   </ng-select>
                   <span class="error" *ngIf="userList.invalid && userList.touched">Please select a valid user</span>
                </div>

                <!--------------------Start Date Field------------------->
                <div class="form-group col-md-4">
                    <label id="labelcolor">Start Date</label><br>
                     <input class="form-control" id="startDate" formControlName="startDate"
                         [minDate]="minDate" [maxDate]="maxDate"
                        bsDatepicker #datepickerYMD="bsDatepicker" name="startDate" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                        required />
                </div>
                

                <!--------------------End Date Field------------------->
                <div class="form-group col-md-4">
                    <label id="labelcolor">End Date</label><br>
                     <input class="form-control" id="endDate" formControlName="endDate"
                         [minDate]="minDate" [maxDate]="maxDate"
                        bsDatepicker #datepickerYMD="bsDatepicker" name="endDate" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                        required />
                </div>
                
                <!--------------------Submit and Reset button-------------------->
                <div class="form-group col-md-4">
                    <label for=""></label><br>
                    <button type="submit" class="btn btn-primary" (click)="showReport()">Submit</button>&nbsp;
                    <button type="reset" class="btn btn-danger" placeholder="Reset">Reset</button>
                </div>
            </div>
        </form>
    </div>

    <div class="white-space"></div>

    <div class="container-fluid" style="border:1px solid lightgrey;">

        <div style="padding: 10px;">
            <h1 class="text-right">
                <button class="btn btn-sm" mat-flat-button color="primary" (click)="exportToExcel()">excel</button> &nbsp;
                <button mat-flat-button color="warn">pdf</button>
            </h1>
            <div style="margin-top: 0%;">
                <hr>
            </div>
             
    <table class="table table-bordered table-sm table-striped table-hover table-responsive{-sm|-md|-lg|-xl}">
        <thead class="table-dark">
            <tr>
                <th *ngFor = "let column of columns;">{{column}}</th>
            </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of collection | paginate : {itemsPerPage:1000, currentPage:p}; let i = index">
            <th scope="row">{{i + 1}}</th>
            <td>{{item.c_date}}</td> 
            <td>{{item.c_voucher_no}}</td>
            <td>{{item.c_partyname}}</td>
            <td>{{item.ledger.ledger_name}}</td>
            <td>{{item.product.p_name}}</td>
            <td>{{item.c_qty}}</td>
            <td>{{item.c_rate}}</td>
            <td>
                <ng-template [ngIf]="item.c_payment_type==1">
                <span>{{item.c_total_amt}}</span>
                </ng-template>
                <ng-template [ngIf]="item.c_payment_type==2">
                <span>0</span>
                </ng-template>
                <ng-template [ngIf]="item.c_payment_type==3">
                <span>{{item.c_balance_amt}}</span>
                </ng-template>                
            </td>
            <td>                
                <ng-template [ngIf]="item.c_payment_type==1">
                <span>0</span>
                </ng-template>
                <ng-template [ngIf]="item.c_payment_type==2">
                <span>{{item.c_total_amt}}</span>
                </ng-template>
                <ng-template [ngIf]="item.c_payment_type==3">
                <span>{{item.c_advance_amt}}</span>
                </ng-template> 
            </td>
            <td>{{item.c_remark}}</td>
          </tr>
          <tr>
            <td colspan="6">Total</td>
            <td class="text-right">
                {{totalQty | number : '1.2-4'}}
            </td>
            <td class="text-right">
            </td>
            <td class="text-right">
                {{totalCreditAmt | number : '1.2-4'}}
            </td>
            <td class="text-right">
                {{totalCashAmt | number : '1.2-4'}}
            </td>
            <td></td>
        </tr>
        </tbody>
        <tr><td colspan="12"><pagination-controls (pageChange) = "p = $event"></pagination-controls></td></tr>
      </table>
        </div>

    </div>

    <div class="white-space" style="height: 200px;"></div>
</div>