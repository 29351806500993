import { Component, OnInit } from '@angular/core';
import { ReceiptService } from 'src/app/services/receipt.service';
import { LedgerService } from 'src/app/services/ledger.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UnitsService } from 'src/app/services/units.service';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { ActivatedRoute, Router } from '@angular/router';
import { ReceiptNo } from 'src/models/receiptNo';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-receipt',
  templateUrl: './add-receipt.component.html',
  styleUrls: ['./add-receipt.component.css']
})
export class AddReceiptComponent {

  addReceipt: FormGroup;

  showAdd : boolean = true;
  showAddP : boolean = false;
  showParty: boolean = false;
  alert: boolean = false
  partyDetailsList: any = [];
  unitNameList: any = [];
  partyNameList: any = [];
  vehicleNameList: any = [];
  productNameList: any = [];
  ledgerAddressList:any = [];
  receiptNum: ReceiptNo[];
  receiptNo: string;
  sankhyaatmakReceipt: Array<number> = [];
  // amount:number; //to store the total amount
  // calBal : number; //to store the balance amount

  valuedate = new Date(); // for Date fill
  minDate: Date;    // for datepicker
  maxDate: Date;    // for datepicker

  constructor(
    private fb: FormBuilder,
    private receiptService: ReceiptService,
    // private productName: ProductService,
    // private unitName: UnitsService,
    private ledgerName: LedgerService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.valuedate.setDate(this.valuedate.getDate() - 1);
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 15000);
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.receiptService.getReceiptNo().subscribe((result) => {
      this.receiptNum = result
      this.autoReceiptNo();
    })
    this.addReceipt = this.fb.group({
      receipt_no: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      receipt_date: ['', Validators.required],
      paymode: ['', Validators.required],
      selectParty: ['', Validators.required],
      party_address: [''],
      party_mobile: [''],
      amount: ['', Validators.required],
      remark: ['']
    });
    this.addReceipt.controls.receipt_date.patchValue(this.formatDate(new Date()));  //For date

    this.loadPartyName();

  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showWarning(messageNotify: string) {
    this.toastr.warning('Failed!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  autoReceiptNo() {
    if (this.receiptNum === null) {
      this.addReceipt.patchValue({
        receipt_no: '0001'
      })
    } else if (this.receiptNum.length === 0) {
      this.addReceipt.patchValue({
        receipt_no: '0001'
      })
    } else {
      this.receiptNum.forEach((receiptNo) => {
        this.receiptNo = receiptNo.r_voucher_no;
        this.sankhyaatmakReceipt.push(Number(this.receiptNo));
      })
      this.sankhyaatmakReceipt.sort((a, b) => b - a);
      this.receiptNo = this.sankhyaatmakReceipt[0].toString();
      this.sankhyaatmakReceipt.length = 0;
      switch ((Math.log(Number(this.receiptNo) + 1) * Math.LOG10E + 1 | 0)) {
        case 1:
          this.addReceipt.patchValue({
            receipt_no: `000${(Number(this.receiptNo) + 1)}`
          })
          break;
        case 2:
          this.addReceipt.patchValue({
            receipt_no: `00${(Number(this.receiptNo) + 1)}`
          })
          break;
        case 3:
          this.addReceipt.patchValue({
            receipt_no: `0${(Number(this.receiptNo) + 1)}`
          })
          break;
        default:
          break;
      }
    }
  }

  private formatDate(date) {
    // const d = new Date(date);
    const d = this.valuedate;
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  getValues() {
    this.receiptService.saveReceipt(this.addReceipt.value).subscribe((result) => {
      if (result['status']=== 200) {
        this.showSuccess(result['message']);
        window.location.reload(); 
        // this.addReceipt.reset({});
      }
      if (result['status']=== 208) {
        this.showWarning(result['message']);
        window.location.reload(); 
      }
      console.log(result)
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
        window.location.reload(); 
      }
    })
    
    this.addReceipt.controls.receipt_date.patchValue(this.formatDate(new Date()));
  }
  closeAlert() {
    this.alert = false;
  }

  loadPartyName() {
    this.ledgerName.getPartyName().subscribe((data: any) => {
      this.partyNameList = data;
    })
  }

  partyCall(val: Int16Array) {
    if (!this.addReceipt.controls.selectParty.value) {
      this.showParty = true;
      this.addReceipt.controls.selectParty.invalid;
    } else {
      this.showParty = false;
      this.loadPartyDetails(val);
    }
  }

  loadPartyDetails(id) {
    this.ledgerName.getPartyDetails(id).subscribe((data: any) => {
      this.partyDetailsList = data;
      // this.addChallan.valueChanges.subscribe()
      this.pakdoPartyDetailsKo();
    })
  }

  pakdoPartyDetailsKo(){
    if (this.partyDetailsList !== null) {
      if (this.ledgerAddressList.length>0) {
        this.ledgerAddressList.length = 0;
      }
      if (this.partyDetailsList.ledger_address_line1 !== null) {
        this.ledgerAddressList.push(this.partyDetailsList.ledger_address_line1);        
      }
      if (this.partyDetailsList.ledger_address_line2 !== null) {
        this.ledgerAddressList.push(this.partyDetailsList.ledger_address_line2);        
      }
      if (this.partyDetailsList.ledger_address_line3 !== null) {
        this.ledgerAddressList.push(this.partyDetailsList.ledger_address_line3);        
      }
      if (this.partyDetailsList.ledger_address_line4 !== null) {
        this.ledgerAddressList.push(this.partyDetailsList.ledger_address_line4);        
      }
      if (this.addReceipt.controls.selectParty.value === 1) {
        this.showAddP = false;
        this.showAdd = true;
        this.addReceipt.patchValue({
          party_address: '',
          party_mobile: ''
        })
      } else {
        this.showAdd = false;
        this.showAddP = true;
        this.addReceipt.patchValue({
          party_address: this.partyDetailsList.ledger_address_line1,
          party_mobile: this.partyDetailsList.ledger_mobile,
        })
      }
    }
  }

  get selectParty() { return this.addReceipt.get('selectParty') };
  get paymode() { return this.addReceipt.get('paymode') };

}
