import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddLedgerComponent } from './add-ledger/add-ledger.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ViewLedgerComponent } from './view-ledger/view-ledger.component';
import { UpdateLedgerComponent } from './update-ledger/update-ledger.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [AddLedgerComponent, ViewLedgerComponent, UpdateLedgerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    MaterialModule,
    RouterModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    NgSelectModule
  ]
})
export class LedgerModule { }
