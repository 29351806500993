import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddProductComponent } from './add-product/add-product.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ViewProductComponent } from './view-product/view-product.component';
import { UpdateProductComponent } from './update-product/update-product.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';



@NgModule({
  declarations: [AddProductComponent, ViewProductComponent, UpdateProductComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    RouterModule
  ],
  exports: [
    UpdateProductComponent
  ]
})
export class ProductModule { }
