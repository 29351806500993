import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChallanService } from 'src/app/services/challan.service';
import { UnitsService } from 'src/app/services/units.service';
import { LedgerService } from 'src/app/services/ledger.service';
import { ProductService } from '../../services/product.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-challan',
  templateUrl: './update-challan.component.html',
  styleUrls: ['./update-challan.component.css']
})
export class UpdateChallanComponent implements OnInit {

  alert:boolean=false
  id:any;
  editChallan: FormGroup;
  challanInfo:any = [];
  totalAmt:number; //to store the total amount
  calBal : number; //to store the balance amount


  valuedate = new Date();

  minDate: Date;
  maxDate: Date;
   
  showAdd : boolean = true;
  showAddP : boolean = false;
  unitNameList:any = [];
  partyNameList:any = [];
  vehicleNameList:any = [];
  productNameList:any = [];
  partyDetailsList:any =[];
  ledgerAddressList:any = [];

  constructor(
    private fb: FormBuilder,
    private editChallanService:ChallanService,
    private productName: ProductService,
    private unitName: UnitsService,
    private ledgerName: LedgerService,
    private router: Router,
    private toastr: ToastrService
    ) { 
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 15000);
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
    
    this.editChallan = this.fb.group({
      challan_no: ['',Validators.required],
      challan_date: ['',Validators.required],
      challan_time: [''],
      unit: 0,
      selectParty: ['',Validators.required],
      party_name: [''],
      party_address: [''],
      party_mobile: [''],
      party_name_hindi: [''],
      party_address_hindi: [''],
      vehicleInfo: ['',Validators.required],
      driver_name: [''],
      driver_mobile: [''],
      productInfo: ['',Validators.required],
      qty: ['',[Validators.required,Validators.pattern('[0-9]*')]],
      rate: ['',[Validators.required,Validators.pattern('[0-9]*')]],
      total_amount: 0,
      paymentMethod: ['',Validators.required],
      advance_amount: [0,[Validators.pattern('[0-9]*')]],
      balance_sheet: 0,
      remark: [''],
      ravana_number: 0,
      Weight: 0,
      Royalty_amount: 0
    })
    // console.warn(this.router.snapshot.params.id)
    this.editChallanService.getCurrentChallan().subscribe((result)=>{

      if (result !== null) {
        this.editChallan.setValue({
          challan_no: result['c_voucher_no'],
          challan_date: result['c_date'],
          challan_time: result['c_time'],
          unit: result['c_company_id'],
          selectParty: result['c_party_id'],
          party_name: result['c_partyname'],
          party_address: result['c_partyaddress'],
          party_mobile: result['c_partymobile'],
          party_name_hindi: result['c_partyname_hindi'],
          party_address_hindi: result['c_partyaddress_hindi'],
          vehicleInfo: result['c_vehicle_id'],
          driver_name: result['c_driver_name'],
          driver_mobile: result['c_driver_mobile'],
          productInfo: result['c_product_id'],
          qty: result['c_qty'],
          rate: Number(result['c_rate']),
          total_amount: result['c_total_amt'],
          paymentMethod: result['c_payment_type'],
          advance_amount: Number(result['c_advance_amt']),
          balance_sheet: result['c_balance_amt'],
          remark: result['c_remark'],
          ravana_number: result['c_ravana_no'],
          Weight: result['c_weight'],
          Royalty_amount: result['c_royalty_amt']
        })
        // this.partyCall(result['c_party_id']);
      }
    })
    this.id = this.editChallanService.cid;
    this.loadProductName();
    this.loadPartyName();
    this.loadVehicleName();
    this.loadUnitName();

    //triggers the calculate Amount function on quantity value change
    this.editChallan.controls.qty.valueChanges.subscribe(result=>{
      this.calculateAmt();
    })

    //triggers the calculate Amount function on rate value change
    this.editChallan.controls.rate.valueChanges.subscribe(result=>{
      this.calculateAmt();
    })

    //triggers the calculate Balance function on Advance amount value change
    this.editChallan.controls.advance_amount.valueChanges.subscribe(result=>{
      this.calculateBal();
    })
    
    // this.editChallan.get('challan_date').valueChanges.subscribe(result => {
    //   this.editChallan.patchValue({
    //     challan_date: this.formatDate(this.editChallan.get('challan_date').value)
    //   })
    // })
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  private formatDate(date) {
    const d = new Date(date);
    // const d = date;
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year,month,day].join("-");
  }

  partyCall(val:Int16Array) {
    this.loadPartyDetails(val);
  }
  
  loadPartyDetails(id) {
    this.ledgerName.getPartyDetails(id).subscribe((data:any) => {
      this.partyDetailsList = data;
      this.pakdoPartyDetailsKo();
    })
  }

  pakdoPartyDetailsKo(){
    if (this.partyDetailsList !== null) {
      if (this.ledgerAddressList.length>0) {
        this.ledgerAddressList.length = 0;
      }
      if (this.partyDetailsList.ledger_address_line1 !== null) {
        this.ledgerAddressList.push(this.partyDetailsList.ledger_address_line1);        
      }
      if (this.partyDetailsList.ledger_address_line2 !== null) {
        this.ledgerAddressList.push(this.partyDetailsList.ledger_address_line2);        
      }
      if (this.partyDetailsList.ledger_address_line3 !== null) {
        this.ledgerAddressList.push(this.partyDetailsList.ledger_address_line3);        
      }
      if (this.partyDetailsList.ledger_address_line4 !== null) {
        this.ledgerAddressList.push(this.partyDetailsList.ledger_address_line4);        
      }
      if (this.editChallan.controls.selectParty.value === 1) {
        this.showAddP = false;
        this.showAdd = true;
        this.editChallan.patchValue({
          party_name: '',
          party_address: '',
          party_mobile: ''
        })
      } else {
        this.showAdd = false;
        this.showAddP = true;
        this.editChallan.patchValue({
          party_name: this.partyDetailsList.ledger_name,
          party_address: this.partyDetailsList.ledger_address_line1,
          party_mobile: this.partyDetailsList.ledger_mobile,
        })
      }
    }
  }

    //function to calculate total amount
    calculateAmt(){
      this.totalAmt = this.editChallan.controls.qty.value * this.editChallan.controls.rate.value;
      this.editChallan.patchValue({
        total_amount: this.totalAmt
      })
    }
  
    //Function to calculate balance amount
    calculateBal(){
      this.calBal = this.editChallan.controls.total_amount.value - this.editChallan.controls.advance_amount.value;
      this.editChallan.patchValue({
        balance_sheet: this.calBal
      })
    }

  loadUnitName() {
    this.unitName.getUnitName().subscribe((data:any) => {
      this.unitNameList = data;
    })
  }
  
  loadPartyName() {
    this.ledgerName.getPartyName().subscribe((data:any) => {
      this.partyNameList = data;
    })
  }

  loadVehicleName() {
    this.ledgerName.getVehicleName().subscribe((data:any) => {
      this.vehicleNameList = data;
    })
  }
  

  loadProductName() {
    this.productName.getProductName().subscribe((data:any) => {
      this.productNameList = data;
    })
  }

  taarikhPeCall() {
    if (this.editChallan !== undefined) {
      var d = new Date(this.editChallan.controls.challan_date.value),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    } else {
      var d = new Date();
      var yd = new Date(d.getTime());
      yd.setDate(d.getDate() - 1)
      d = yd;
       var month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
 
      return [year, month, day].join('-');
    }
  }

  updateChallan(){
    
    this.editChallan.patchValue({
      challan_date: this.formatDate(this.editChallan.get('challan_date').value)
    })

    this.editChallanService.updateChallan(this.id, this.editChallan.value).subscribe((result)=>{
      // this.alert=true
      if (result['status'] === 200) {
        this.showSuccess(result['message']);
      }
      console.warn("item", result);
      this.editChallan.reset({});
      this.router.navigateByUrl('/dashboard/viewChallan');
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
      }
      if (error.status === 400) {
        this.showError(error.error);
      }
    })
  }

  closeAlert()
  {
    this.alert=false
  }

  //fetching form control validators status
  get challan_no(){return this.editChallan.get('challan_no')};
  // get challan_date(){return this.addChallan.get('challan_date')};
  // get challan_time(){return this.addChallan.get('challan_time')};
  // get unit(){return this.addChallan.get('unit')};
  get selectParty(){return this.editChallan.get('selectParty')};
  get vehicleInfo(){return this.editChallan.get('vehicleInfo')};
  // get driver_name(){return this.addChallan.get('driver_name')};
  // get driver_mobile(){return this.addChallan.get('driver_mobile')};
  get productInfo(){return this.editChallan.get('productInfo')};
  get qty(){return this.editChallan.get('qty')};
  get rate(){return this.editChallan.get('rate')};
  get paymentMethod(){return this.editChallan.get('paymentMethod')};
  get advance_amount(){return this.editChallan.get('advance_amount')};
  // get ravana_number(){return this.addChallan.get('ravana_number')};
  // get Weight(){return this.addChallan.get('Weight')};
  // get Royalty_amount(){return this.addChallan.get('Royalty_amount')};

}
