<breadcrumb #parent>
  <ol class="breadcrumb">
      <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <li *ngIf="!route.terminal" class="breadcrumb-item">
            <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]" style="color: black;">&nbsp;{{ route.displayName }}</a>
          </li>
          <li *ngIf = "route.terminal" class="breadcrumb-item active">
              {{ route.displayName}}
          </li>
      </ng-template>
  </ol>
</breadcrumb>

<div class="container">
    <h1 class="mat-h1">Receipt</h1>
    <p class="mat-caption">All Receipts</p>

    <button mat-flat-button class="btn float-right" style="background-color: coral;" [routerLink]="['../addReceipt']">Add Receipt</button>
  
  <hr>

  
    <table class="table">
      <thead>
        <tr>
          <th scope="col">SNo.</th>
          <th scope="col">Receipt Voucher</th>
          <th scope="col">Party Name</th>
          <th scope="col">Creater</th>
          <th scope="col">Payment Type</th>
          <th scope="col">Amount</th>
          <th scope="col">Receipt Date</th>
          <th scope="col">Created Date</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of collection ; let i = index">
          <th scope="row">{{i + 1}}</th>
          <td>{{item.r_voucher_no}}</td> 
          <td>{{item.ledger.ledger_name}}</td>
          <td>{{item.user.user_name}}</td> 
          <td>
            <ng-template [ngIf]="item.r_payment_type==1">
              <span>credit</span>
            </ng-template>
            <ng-template [ngIf]="item.r_payment_type==2">
              <span>cash</span>
            </ng-template>
          </td>
          <td>{{item.r_amount}}</td> 
          <td>{{item.r_date}}</td>
          <td>{{item.r_created | date: mmddyyyy}}</td>      
          <th>
            <a (click)="deleteReceipt(item.r_id)"><i class="fa fa-trash" style="font: size 30px;color:red;"></i></a> &nbsp;
        <a (click)="lcUpdateReceipt(item.r_id)" [routerLink] = "['./lcUpdateReceipt']"><i class="fa fa-edit" style="font: size 30px;color:blue;"></i></a>
        </th>
        </tr>
      </tbody>
    </table>
  </div>