import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChallanService } from '../../services/challan.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LedgerService } from 'src/app/services/ledger.service';
import { ProductService } from 'src/app/services/product.service';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-view-challan',
  templateUrl: './view-challan.component.html',
  styleUrls: ['./view-challan.component.css']
})
export class ViewChallanComponent implements OnInit {

  searchPartyForm: FormGroup;
  searchPaymodeForm: FormGroup;
  searchVoucherForm: FormGroup;
  searchVehicleForm: FormGroup;
  searchProductForm: FormGroup;
  searchUserAddForm: FormGroup;
  showNChallan: FormGroup;
  searchValue: string;

  c_voucher_no: any;
  com_name: any;
  c_partyname: any;
  ledger_name: any;
  p_name: any;
  user_company_address: any;
  c_date: any;
  c_time: any;
  
  c_party_id: number = 0;
  c_vehicle_id: number = 0;
  c_product_id: number = 0;
  c_creator_id: number = 0;
  c_payment_type: number = 0;

  collection: any = [];
  productNames: any = [];
  name: any;
  key: string = 'id';
  p: number = 1;
  pageNo: number = 0;
  pageSize: number = 50;
  count: number = 0

  collection2: Array<any> = [];

  partyNameList: any = [];
  vehicleNameList: any = [];
  productNameList: any = [];
  userAddressNameList: any = [];

  pageData = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    c_party_id: this.c_party_id,
    c_vehicle_id: this.c_vehicle_id,
    c_product_id: this.c_product_id,
    c_creator_id: this.c_creator_id,
    c_payment_type: this.c_payment_type
  }


  constructor(
    private viewChallanService: ChallanService,
    private router: Router,
    private fb: FormBuilder,
    private ledgerName: LedgerService,
    private productName: ProductService,
    private userAddressName: UsersService,
    private toastr: ToastrService
  ) {
    this.loadPartyName();
    this.loadVehicleName();
    this.loadProductName();
    this.loadUserAddressName();
  }

  ngOnInit() {

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
    this.showNChallan = this.fb.group({
      selectNChallan: ['50']
    })
    this.searchPartyForm = this.fb.group({
      partyList: ['0']
    })
    this.searchVehicleForm = this.fb.group({
      vehicleList: ['0']
    })
    this.searchProductForm = this.fb.group({
      productList: ['0']
    })
    this.searchVoucherForm = this.fb.group({
      voucherList: ['']
    })
    this.searchUserAddForm = this.fb.group({
      userList: ['0']
    })
    this.searchPaymodeForm = this.fb.group({
      paymentType: ['0']
    })
    this.getChallanData();
  }

  //loading party names in partyList dropdown on component loading
  loadPartyName() {
    this.ledgerName.getPartyName().subscribe((data: any) => {
      this.partyNameList = data;
    })
  }

  //loading vehicle names in vehicleList dropdown on component loading
  loadVehicleName() {
    this.ledgerName.getVehicleName().subscribe((data: any) => {
      this.vehicleNameList = data;
    })
  }

  //loading product names in productList dropdown on component loading
  loadProductName() {
    this.productName.getProductName().subscribe((data) => {
      this.productNameList = data;
    })
  }

  loadUserAddressName() {
    this.userAddressName.getUsersAddress().subscribe((data:any)=> {
      this.userAddressNameList = data;
    })
  }

  //function to format date
  private formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  getChallanData() {
    this.viewChallanService.getPaginatedChallan(this.pageData).subscribe((result) => {
      this.count = result['count']
      this.collection = result['rows'];
      this.collection2 = this.collection.map((value, index) => {
        value.date = this.formatDate(value.c_created)
        return value
      })
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
        this.router.navigateByUrl('/dashboard/addChallan'); 
      }
    })
  }

  //Search function
  Search(name) {
    if (this.name == "") {
      this.ngOnInit();
    } else {
      this.collection2 = this.collection2.filter(res => {
        return res.name.toLocaleLowerCase().match(this.name.toLocaleLowerCase());
      })
    }
  }

  SearchV() {
    if (this.c_voucher_no == "") {
      this.ngOnInit();
    } else {
      this.collection2 = this.collection2.filter(res => {
        return res.c_voucher_no.toLocaleLowerCase().match(this.c_voucher_no.toLocaleLowerCase());
      })
    }
  }

  //Search Company
  SearchC() {
    if (this.com_name == "") {
      this.ngOnInit();
    } else {
      this.collection2 = this.collection2.filter(res => {
        return res.com_name.toLocaleLowerCase().match(this.com_name.toLocaleLowerCase());
      })
    }
  }

  //Search Party Name
  SearchP() {
    if (this.c_partyname == "") {
      this.ngOnInit();
    } else {
      this.collection2 = this.collection2.filter(res => {
        return res.c_partyname.toLocaleLowerCase().match(this.c_partyname.toLocaleLowerCase());
      })
    }
  }

  //Search Ledger Name
  SearchL() {
    if (this.ledger_name == "") {
      this.ngOnInit();
    } else {
      this.collection2 = this.collection2.filter(res => {
        return res.ledger_name.toLocaleLowerCase().match(this.ledger_name.toLocaleLowerCase());
      })
    }
  }

  //Search Product Name
  SearchPd() {
    if (this.p_name == "") {
      this.ngOnInit();
    } else {
      this.collection2 = this.collection2.filter(res => {
        return res.p_name.toLocaleLowerCase().match(this.p_name.toLocaleLowerCase());
      })
    }
  }

  //Sorting Function
  // sort(key){
  //   this.key = key;
  //   this.reverse =!this.reverse;
  // }

  public lcprintChallan(item) {
    this.viewChallanService.cPrintId = item;
    console.log(this.viewChallanService.cPrintId);
    localStorage.setItem('challan_id', JSON.stringify(this.viewChallanService.cPrintId));
  }

  public lcUpdateChallan(item) {
    this.viewChallanService.cid = item;
    this.router.navigateByUrl('/dashboard/updateChallan')
  }

  partyCall(val:Int16Array) {
    this.pageData.c_party_id = Number(val);
    this.getChallanData()
  }

  vehicleCall(val:Int16Array) {
    this.pageData.c_vehicle_id = Number(val);
    this.getChallanData()
  }

  productCall(val:Int16Array) {
    this.pageData.c_product_id = Number(val);
    this.getChallanData()
  }

  userCall(val: Int16Array) {
    this.pageData.c_creator_id = Number(val);
    this.getChallanData()
  }

  paymentTypeCall(val:Int16Array) {
    this.pageData.c_payment_type = Number(val);
    this.getChallanData()
  }

  //onClick pagination function
  handlePageChange(event) {
    console.log(event)
    this.pageNo = event;
    this.pageData.pageNo = this.pageNo - 1;
    this.getChallanData();
  }

  handlePageSize(val:Int16Array) {
    this.pageSize = Number(val);
    this.pageData.pageSize = this.pageSize;
    this.getChallanData();
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  // fake data for temporary deletion
  fakeData = {
    c_status: 0,
    c_is_deleted: 1
  }

  // deleting a challan
  deleteChallan(c_id) {
    if (confirm("Are you sure to delete ")) {
      this.viewChallanService.doDeleteChallan(c_id, this.fakeData).subscribe(result => {
        if (result['status']=== 200) {
          this.showSuccess(result['message']);
        }
      }, error => {
        if (error.status === 500) {
          this.showError(error.error.message);
          window.location.reload(); 
        }
        if (error.status === 400) {
          this.showError(error.error);
          window.location.reload(); 
        }
      })
      this.getChallanData();
    }

  }

}
