import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services';
import { ChallanService } from 'src/app/services/challan.service';
import { ProfileService } from 'src/app/services/profile.service';
import { AuthInterface } from 'src/models/authInterface';

@Component({
  selector: 'app-d-card',
  templateUrl: './d-card.component.html',
  styleUrls: ['./d-card.component.css']
})
export class DCardComponent implements OnInit {

  showChallan: FormGroup;
  authDetails: AuthInterface;
  userName:string = '';
  
  totalChallan : number = 0;
  totalCash : number = 0;
  totalCredit : number = 0;
  totalDueAdv : number = 0;
  collection: any[];

  constructor( 
    private fb: FormBuilder, 
    private challanService: ChallanService,
    private authService: AuthenticationService,
    private profileService: ProfileService) { }

  ngOnInit(): void {
    this.showChallan = this.fb.group({
      selectChallan : ['2']
    })
    this.authDetails = this.authService.authDetails;
    // this.userName = this.authDetails.user.user_name;
    this.profileService.getCurrentUsers().subscribe(result=> {
      this.userName = result['user_name'];
    })
    this.getChallan();

    this.showChallan.controls.selectChallan.valueChanges.subscribe(result=>{
      // this.getChallan();
      let data = this.showChallan.controls.selectChallan.value
      if (data === '1') {
        this.challanService.getTotalCountOfChallans(data).subscribe(result => {
          console.log(result);
          if (result.length > 0) {
            this.collection = result;
            let sum: number = 0;
            for (let i = 0; i < this.collection.length; i++) {
              sum += this.collection[i].All;            
            }
            this.totalChallan = sum;
            this.totalCash = this.collection[1].Total;
            this.totalCredit = this.collection[0].Total;
            this.totalDueAdv = this.collection[2].Total; 
          }
        })        
      } else {
        this.challanService.getTotalCountOfChallans(this.formatDate()).subscribe(result=>{
          console.log(result)
          if (result.length > 0) {
            this.collection = result;
            let sum: number = 0;
            for (let i = 0; i < this.collection.length; i++) {
              sum += this.collection[i].All;
              if (this.collection[i].c_payment_type===1) {
                this.totalCredit = this.collection[i].Total
              }
              if (this.collection[i].c_payment_type===2) {
                this.totalCash = this.collection[i].Total
              }
              if (this.collection[i].c_payment_type===3) {
                this.totalDueAdv = this.collection[i].Total
              }        
            }
            this.totalChallan = sum;
          }
        })
      }
    })
  }

  private getChallan(){
    if (this.showChallan.controls.selectChallan.value !== 0){
      let data = this.showChallan.controls.selectChallan.value
      if (data === '1') {
        this.challanService.getTotalCountOfChallans(data).subscribe(result => {
          console.log(result);
          if (result.length > 0) {
            this.collection = result;
            console.log(this.collection);
            let sum: number = 0;
            for (let i = 0; i < this.collection.length; i++) {
              sum += this.collection[i].All;            
            }
            this.totalChallan = sum;
            this.totalCash = this.collection[1].Total;
            this.totalCredit = this.collection[0].Total;
            this.totalDueAdv = this.collection[2].Total;
          }
        })        
      } else {
        this.challanService.getTotalCountOfChallans(this.formatDate()).subscribe(result=>{
          console.log(result)
          this.collection = result;
          if (this.collection.length > 0) {
            let sum: number = 0;
            for (let i = 0; i < this.collection.length; i++) {
              sum += this.collection[i].All;
              if (this.collection[i].c_payment_type===1) {
                this.totalCredit = this.collection[i].Total
              }
              if (this.collection[i].c_payment_type===2) {
                this.totalCash = this.collection[i].Total
              }
              if (this.collection[i].c_payment_type===3) {
                this.totalDueAdv = this.collection[i].Total
              }
            }
            this.totalChallan = sum;
          }
        })
      }
    } 
  }

  private formatDate() {
    const d = new Date();
    // d.setDate(d.getDate()-1)
    // const d = date;
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year,month,day].join("-");
  }

}
