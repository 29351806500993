<breadcrumb #parent>
  <ol class="breadcrumb">
    <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
      <li *ngIf="!route.terminal" class="breadcrumb-item">
        <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]"
          style="color: black;">&nbsp;{{ route.displayName }}</a>
      </li>
      <li *ngIf="route.terminal" class="breadcrumb-item active">
        {{ route.displayName}}
      </li>
    </ng-template>
  </ol>
</breadcrumb>

<div class="container">
  <h4>Add User</h4>

  <!-- success alert message start -->
  <div *ngIf="alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Success</strong> Your data has been entered.
    <button (click)="closeAlert()" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- success alert message end -->

  <div class="container-fluid" style="border:1px solid rgb(112, 105, 105); border-radius: 2px;">
    <form [formGroup]="addUsers" (ngSubmit)="getValues()">
      <div class="panel panel-primary" style="color: blue;">
        <div class="panel-heading">
          <h3 class="panel-title">Add Users</h3>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-3">
          <label id="labelcolor">Add Photo</label>
        </div>
        <div class="form-group col-md-8">
          <input #file type="file" name="imageUpload" id="imageUpload" class="form-control hide"
            formControlName="add_photo" accept='image/*' (change)="preview(file.files)" />
          <label for="imageUpload" class="btn btn-large">Select file</label>
          <img [src]="imgURL" height="80" *ngIf="imgURL">
          <!-- <span class="error" *ngIf="add_photo.invalid && add_photo.touched"
              >Please enter a valid Photo</span> -->
        </div>

        <div class="col-md-3">
          <label id="labelcolor">User Name <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="user_name" required />
          <span class="error" *ngIf="user_name.invalid && user_name.touched">Please enter a valid user name</span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Company Name <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <!-- <input type="text" class="form-control" formControlName="company_name" required /> -->
          <ng-select  class="form-control"  formControlName="company_name" (ngModelChange)="companyNamesCall($event)" required>
            <ng-option value="0" selected>--Select company Name</ng-option>
            <ng-option id="company_name"  *ngFor="let companyName of companyNamesList"  [value]="companyName.com_id">{{ companyName.com_name }}</ng-option>
          </ng-select>
          <span class="error" *ngIf="company_name.invalid && company_name.touched">Please enter a valid company
            name</span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Company Address <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="company_address" required />
          <span class="error" *ngIf="company_address.invalid && company_address.touched">Please enter a valid company
            address</span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">User LoginID <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="user_login_id" required />
          <span class="error" *ngIf="user_login_id.invalid && user_login_id.touched">Please enter a valid user login
            id</span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">User Email <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="text" class="form-control" formControlName="user_email" required />
          <span class="error" *ngIf="user_email.invalid && user_email.touched">Please enter a valid user email</span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Password <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="password" class="form-control" pattern=".{6,}" required title="6 characters minimum"
            formControlName="password" required />

          <span class="error" *ngIf="password.invalid && password.touched">
            <span *ngIf="password.errors.required"> Password is required. </span>
            <span *ngIf="password.errors.pattern"> Must contain at least 6 or more characters.</span>
          </span>
        </div>

        <div class="col-md-3">
          <label id="labelcolor">Repeat Password <span id="spancolor">*</span></label>
        </div>
        <div class="form-group col-md-8">
          <input type="password" class="form-control" formControlName="repeat_password" pattern="{{ password.value }}"
            required />

          <span class="error" *ngIf=" repeat_password.invalid && repeat_password.touched">
            <span *ngIf="repeat_password.errors.required"> Confirm password is required. </span>
            <span *ngIf="repeat_password.errors.pattern"> Password & Confirm Password does not match.</span>
          </span>
        </div>

        <div class="col-md-3"></div>
        <div class="form-group col-md-4">
          <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group" role="group" aria-label="First group">
              <button class="btn btn-primary" [disabled]="addUsers.invalid" data-toggle="tooltip" data-placement="top"
                title="">
                Submit
              </button>
              &nbsp; &nbsp;
              <!-- <button type="submit" [disabled]="addUsers.invalid" class="btn btn-secondary"
                    placeholder="Submit & Print">
                    Submit & Print
                  </button>
                  &nbsp; &nbsp; -->
              <button type="reset" [disabled]="false" class="btn btn-danger" placeholder="Reset">
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
