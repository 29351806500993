import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MReportComponent } from './m-report/m-report.component';
import { VReportComponent } from './v-report/v-report.component';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MtableComponent } from './mtable/mtable.component';
import { VtableComponent } from './vtable/vtable.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { BreadcrumbModule } from 'angular-crumbs';


@NgModule({
  declarations: [
    MReportComponent, 
    MtableComponent, 
    VReportComponent, 
    VtableComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    BreadcrumbModule
  ]
})
export class ReportsModule { }
