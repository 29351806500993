import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  alert: boolean = false
  profile: FormGroup;

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    this.profile = this.fb.group({
      user_name: ['', Validators.required],
      user_email: ['', Validators.required],
    });

    this.getValues();
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  getValues() { 
    // alert('not matched correctly');
    this.profileService.getCurrentUsers().subscribe(result => {
      this.profile.setValue({
        user_name: result['user_name'],
        user_email: result['user_email']
      })
    }, error=> {
      if (error.status === 500) {
        this.showError(error.error.message);
      }
    })
    // this.router.navigateByUrl('login');
  }

  updateValues() {
    this.profileService.updateCurrentUserDetails(this.profile.value).subscribe(result => {
      if (result['status']=== 200) {
        this.showSuccess(result['message']);
      }
    }, error => {
      if (error.status === 400) {
        this.showError(error.error);
      }
      if (error.status === 500) {
        this.showError(error.error.message);
      }
    })
  }

  closeAlert() {
    this.alert = false;
  }


  //for vaildation
  get user_name() { return this.profile.get('user_name') };
  get user_email() { return this.profile.get('user_email') };


}
