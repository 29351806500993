<breadcrumb #parent>
  <ol class="breadcrumb">
    <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
      <li *ngIf="!route.terminal" class="breadcrumb-item">
        <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]"
          style="color: black;">&nbsp;{{ route.displayName }}</a>
      </li>
      <li *ngIf="route.terminal" class="breadcrumb-item active">
        {{ route.displayName}}
      </li>
    </ng-template>
  </ol>
</breadcrumb>

<div class="container">
  <h1 class="mat-h1">Challan</h1>
  <!-- <p class="mat-caption">All registered challan</p> -->
  <small style="padding-top:0;">All registered Challan</small>
  <div style="display: flex; float: right;">
    <form [formGroup]="showNChallan" class="form-group col-xs-2 float-right">
      <!-- <label for="exampleFormControlSelect1">Example select</label> -->
      <select class="form-control" id="showChallanNum" formControlName="selectNChallan" (ngModelChange)="handlePageSize($event)">
        <option value="50" selected>50</option>
        <option value="100">100</option>
        <option value="500">500</option>
        <option value="1000">1000</option>
      </select>
    </form>
    &nbsp;&nbsp;&nbsp;
    <button mat-flat-button class="btn float-right" style="background-color: coral; height: 40px; border-radius: 10px;"
      [routerLink]="['../addChallan']">Add Challan</button>
  </div>
  <hr>

  <!-- <div class="form-group-row">
    <label for="search" class="col-sm-2 col-form-label" style="text-align: right;">search</label>
    <div class="col-sm-6">
      <input type="text" [(ngModel)]="searchValue" class="form-control" id="search">

    </div>
  </div> -->
  <table class="table table-sm table-hover table-responsive{-sm|-md|-lg|-xl}">
    <thead class="thead-light">
      <tr>
        <th scope="col">SNo.</th>
        <th scope="col" style="width: 75px;">Voucher</th>
        <th scope="col">Company</th>
        <th scope="col" style="width: 120px;">Party Name</th>
        <th scope="col">Vehicle Name</th>
        <th scope="col">Product Name</th>
        <th scope="col">Area</th>
        <th scope="col">Payment Type</th>
        <th scope="col">Amount</th>
        <th scope="col">Challan Date</th>
        <th scope="col">Creation Date</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr style="height: 30px; justify-content: center; text-align: center; background-color: whitesmoke;">
        <td></td>
        <td>
          <form [formGroup]="searchVoucherForm">
            <div class="form-group col-xs-2">
              <div class="col-xs-2">
                <input type="text" class="form-control input-sm" formControlName="voucherList" name="c_voucher_no" [(ngModel)]="c_voucher_no"
                  (ngModelChange)="SearchV()" placeholder="Search">
              </div>
            </div>
          </form>
        </td>
        <td>
          <!-- <div class="form-group">
            <div class="col-xs-2">
              <input type="text" class="form-control input-sm" name="com_name" [(ngModel)]="com_name"
                (ngModelChange)="SearchC()" placeholder="search">
            </div>
          </div> -->
        </td>
        <td>
          <form [formGroup]="searchPartyForm">
            <div class="form-group col-xs-2">
              <select class="form-control" formControlName="partyList" (ngModelChange)="partyCall($event)" style="font-size: x-small;">
                <option value="0" selected>All</option>
                <option id="partyList" *ngFor="let partyName of partyNameList" [value]="partyName.ledger_id">{{
                  partyName.ledger_name }}</option>
              </select>
            </div>
          </form>
        </td>
        <td>
          <form [formGroup]="searchVehicleForm">
            <div class="form-group col-xs-2">
              <select class="form-control" formControlName="vehicleList" (ngModelChange)="vehicleCall($event)" style="font-size: x-small;">
                <option value="0" selected>All</option>
                <option id="vehicleList" *ngFor="let vehicleName of vehicleNameList" [value]="vehicleName.ledger_id">{{
                  vehicleName.ledger_name }}</option>
              </select>
            </div>
          </form>

        </td>
        <td>
          <form [formGroup]="searchProductForm">
            <div class="form-group col-xs-2">
              <select class="form-control" formControlName="productList" (ngModelChange)="productCall($event)" style="font-size: x-small;">
                <option value="0" selected>All</option>
                <option id="productList" *ngFor="let productName of productNameList" [value]="productName.p_id">{{
                  productName.p_name }}</option>
              </select>
            </div>
          </form>
        </td>
        <td>
          <form [formGroup]="searchUserAddForm">
            <div class="form-group col-xs-2">
              <select class="form-control" formControlName="userList" (ngModelChange)="userCall($event)" style="font-size: x-small;">
                <option value="0" selected>All</option>
                <option id="productList" *ngFor="let userName of userAddressNameList" [value]="userName.user_id">{{
                  userName.user_company_address }}</option>
              </select>
            </div>
          </form>
        </td>
        <td>
          <form [formGroup]="searchPaymodeForm">
            <div class="form-group col-xs-2">
              <select class="form-control" formControlName="paymentType" (ngModelChange)="paymentTypeCall($event)" style="font-size: x-small;">
                <option value="0" selected>All</option>
                <option name="payment" value="1">Credit</option>
                <option name="payment" value="2">Cash</option>
                <option name="payment" value="3">Due/Adv.</option>
              </select>
            </div>
          </form>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr
        *ngFor="let item of collection2 | paginate : {itemsPerPage:pageSize, currentPage:pageNo, totalItems: count}; let i = index;">
        <th scope="row">{{i + 1 }}</th>
        <td>{{item.c_voucher_no}}</td>
        <td>
          <ng-template [ngIf]="item.company===null">
            <span>--</span>
          </ng-template>
          <ng-template [ngIf]="item.company!==null">
            <span>{{item.company.com_name}}</span>
          </ng-template>
        </td>
        <td>{{item.c_partyname}}</td>
        <td>{{item.ledger.ledger_name}}</td>
        <td>
          <div *ngIf="item.product != null; else pname">
            <span>{{item.product.p_name}}</span>
          </div>
          <ng-template #pname>
            <span>--</span>
          </ng-template>
        </td>
        <td>
          <ng-template [ngIf]="item.user===null">
            <span>--</span>
          </ng-template>
          <ng-template [ngIf]="item.user!==null">
            <span>{{item.user.user_company_address}}</span>
          </ng-template>
        </td>
        <td>
          <ng-template [ngIf]="item.c_payment_type==1">
            <span>credit</span>
          </ng-template>
          <ng-template [ngIf]="item.c_payment_type==2">
            <span>cash</span>
          </ng-template>
          <ng-template [ngIf]="item.c_payment_type==3">
            <span>Adv./Due</span>
          </ng-template>
        </td>
        <td>{{item.c_total_amt}}</td>
        <td>{{item.c_date | date: mmddyyyy}}</td>
        <td>{{item.date | date: mmddyyyy}}</td>

        <th>
          <a (click)="deleteChallan(item.c_id)"><i class="fa fa-trash" style="font: size 30px;color:red;"></i></a>
          &nbsp;
          <a (click)="lcUpdateChallan(item.c_id)" [routerLink]="['./updateChallan']"><i class="fa fa-edit"
              style="font: size 30px;color:blue;"></i></a>&nbsp;
          <a (click)="lcprintChallan(item.c_id)" [routerLink]="['/invoice']" target="_blank"><i class="fa fa-print"
              style="font: size 30px;color:blue;"></i></a>
        </th>
      </tr>
    </tbody>
    <tr>
      <td colspan="12">
        <pagination-controls (pageChange)="handlePageChange($event)"></pagination-controls>
      </td>
    </tr>
  </table>
</div>
