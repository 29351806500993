<breadcrumb #parent>
    <ol class="breadcrumb">
        <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
            <li *ngIf="!route.terminal" class="breadcrumb-item">
                <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]" style="color: black;">&nbsp;{{ route.displayName }}</a>
            </li>
            <li *ngIf = "route.terminal" class="breadcrumb-item active">
                {{ route.displayName}}
            </li>
        </ng-template>
    </ol>
</breadcrumb>

<div class="container">
  <!-- <h4>Add Receipt</h4> -->

<!-- success alert message start -->
<div *ngIf="alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Success</strong> Your data has been entered.
    <button (click)="closeAlert()" type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<!-- success alert message end -->

<div class="container-fluid" style="border: 1px solid lightgrey;">
  <form [formGroup]="addReceipt" (ngSubmit)="getValues()">
        <!--------------------------form heading------------------------->
        <div class="panel panel-default">
            <div class="panel-body pull-left">
                <!-- <button mat-flat-button disabled btn-block style="background-color: white; color: black;">
                    <mat-icon>search</mat-icon>&nbsp; search for the mislleneous report
                </button> -->
                <mat-icon [inline]="true">receipts</mat-icon><span style="color: gray; font-size: larger;">Add Receipt</span> 
                <hr style="padding-top: 0; margin-top: 0;">
                <!-- <h3 class="panel-title">search for the mislleneous report</h3> -->
            </div>
        </div>
          <div class="row">
                <div class="form-group col-md-4">
                    <label id="labelcolor">Receipt No.*</label><br>
                    <input type="text" class="form-control" formControlName="receipt_no" required readonly/>
                    <!-- <span class="error" *ngIf="receipt_no.invalid && receipt_no.touched">Please enter a valid receipt
                        number</span> -->
                </div>

                <div class="form-group col-md-4">
                    <label id="labelcolor">Date *</label><br>
                    <input class="form-control" id="receipt_date" formControlName="receipt_date"
                        placeholder="{{valuedate | date: 'YYYY-MM-DD'}}" [minDate]="minDate" [maxDate]="maxDate"
                        bsDatepicker #datepickerYMD="bsDatepicker" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                        required />
                        <!-- <span class="error" *ngIf="challan_date.invalid && challan_date.touched">Please enter a valid
                            challan date</span> -->
                </div>

                <div class="form-group col-md-4">
                    <label id="labelcolor">Paymode *</label><br>
                    <select class="form-control" formControlName="paymode" required>
                        <option name="" value="">Select Payment Type</option>
                        <option name="paymode" value="1">Credit</option>
                        <option name="paymode" value="2">Cash</option>
                        <option name="paymode" value="3">Due/Adv.</option>
                    </select>
                    <span class="error" *ngIf="paymode.invalid && paymode.touched">Please select a valid
                        payment method</span>
                </div>

                <div class="form-group col-md-4">
                    <label name="labelcolor">Party *</label><br>
                    <ng-select  class="form-control"  formControlName="selectParty" (ngModelChange)="partyCall($event)">
                        <ng-option value="" selected>--Select Party Name</ng-option>
                        <ng-option id="challan_party"  *ngFor="let partyName of partyNameList"  [value]="partyName.ledger_id" >{{ partyName.ledger_name }}</ng-option>
                   </ng-select>
                    <span class="error" *ngIf="selectParty.invalid && selectParty.touched">Please select a valid party
                        name</span>
                </div>

                <!-- <div class="form-group col-md-4">
                    <label>Party Address</label><br>
                     <input type="text" class="form-control" formControlName="party_address" />
                </div> -->

                <div class="form-group col-md-4" *ngIf="showAdd">
                    <label>Party Address</label><br>
                    <input type="text" class="form-control" formControlName="party_address" />
                </div>
          
                <div class="form-group col-md-4" *ngIf="showAddP">
                    <label>Party Address</label><br>
                    <ng-select class="form-control" placeholder="--Select Party Address--" formControlName="party_address">
                        <ng-option *ngFor="let partyAdd of ledgerAddressList" [value]="partyAdd">{{ partyAdd }}</ng-option>
                    </ng-select>
                </div>

                <div class="form-group col-md-4">
                    <label>Party Mobile</label><br>
                    <input type="tel" class="form-control" formControlName="party_mobile" />
                </div>

                <div class="form-group col-md-4">
                  <label id="labelcolor">Total Amount *</label><br>
                  <input type="text" class="form-control" formControlName="amount" required />
                </div>

                <div class="form-group col-md-8">
                    <label id="labelcolor">Remark <span id="spancolor">*</span></label><br>
                    <input type="text" class="form-control" formControlName="remark" required />
                </div>

                <div class="form-group col-md-4">
                  <button class="btn btn-primary" [disabled]="addReceipt.invalid">Submit</button>
                  <button type="reset" class="btn btn-danger" placeholder="Reset">Reset</button>
              </div>
            </div>
        </form>
    </div>
    </div>
