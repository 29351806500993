<breadcrumb #parent>
  <ol class="breadcrumb">
      <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <li *ngIf="!route.terminal" class="breadcrumb-item">
            <mat-icon class="nav-item-icon" [inline]="true">dashboard</mat-icon><a href="" [routerLink]="[route.url]" style="color: black;">&nbsp;{{ route.displayName }}</a>
          </li>
          <li *ngIf = "route.terminal" class="breadcrumb-item active">
              {{ route.displayName}}
          </li>
      </ng-template>
  </ol>
</breadcrumb>

<div class="container">
  <h1 class="mat-h1">Users</h1>
  <p class="mat-caption">All registered Users</p>
  <div style="display: flex; float: right;">
    <form [formGroup]="showNUser" class="form-group col-xs-2 float-right">
      <!-- <label for="exampleFormControlSelect1">Example select</label> -->
      <select class="form-control" id="showUserNum" formControlName = "selectNUser">
        <option value="1" >25</option>
        <option value="2">50</option>
        <option value="3">75</option>
        <option value="4">100</option>
      </select>
  </form>
  &nbsp;&nbsp;&nbsp;
  <button mat-flat-button class="btn float-right" style="background-color: coral; height: 40px; border-radius: 10px;" [routerLink]="['../addUser']">Add User</button>
  </div>  
  <hr>
  <!-- <button mat-flat-button class="btn float-right" style="background-color: coral;" [routerLink]="['../addUser']">Add User</button>
  
  <hr> -->

  <table class="table table-hover table-responsive{-sm|-md|-lg|-xl}">
    <thead class="thead-light">
      <tr>
        <th scope="col">SNo.</th>
        <th scope="col">User Name</th>
        <th scope="col">Login ID</th>
        <th scope="col">Email</th>
        <th scope="col">Sign-up Date</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of collection; let i = index">
        <th scope="row">{{i + 1}}</th>
        <td>{{item.user_name}}</td>
        <td>{{item.user_loginid}}</td>
        <td>{{item.user_email}}</td>
        <td>{{item.user_created | date : ddmmyyyy}}</td>

        <th><a (click)="deleteUser(item.user_id)"><i class="fa fa-trash" style="font: size 30px;color:red;">
          </i> </a>&nbsp;&nbsp;
          <a (click)="lcUpdateUser(item.user_id)"><i class="fa fa-edit" style="font: size 30px;color:blue;"></i></a>&nbsp;&nbsp;
        </th>
      </tr>
    </tbody>
  </table>
</div>
