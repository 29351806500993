import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { LedgerService } from 'src/app/services/ledger.service';
import { ReportData } from 'src/models/reportData';
import { ChallanService } from 'src/app/services/challan.service';
import { VExcelService } from 'src/models/vexcel-service';
import { VehReportChallan } from 'src/models/vehicleReport';

@Component({
  selector: 'app-v-report',
  templateUrl: './v-report.component.html',
  styleUrls: ['./v-report.component.css']
})
export class VReportComponent implements OnInit {

  collection: any = [];
  columns: any[];
  footerData: any[][] = [];
  totalWeight = 0;
  totalTotalAmt = 0;
  totalQty = 0;

  c_voucher_no: any;
  com_name: any;
  c_partyname: any;
  ledger_name: any;
  p_name: any;
  user_company_address: any;
  c_payment_type: any;
  c_date: any;
  c_time: any;
  p: number = 1;

  vReportForm: FormGroup;
  partyDetailsList: any = [];
  partyNameList: any = [];
  vehicleNameList: any = [];
  productNameList: any = [];

  collection2: any = [];
  excelFieldNames: any = [];
  myObject2: VehReportChallan[] = [];

  valueDate = new Date;
  startfDate = new Date();
  endfDate = new Date();

  minDate: Date;
  maxDate: Date;

  constructor(
    private rf: FormBuilder,
    private productName: ProductService,
    private ledgerName: LedgerService,
    private viewChallanService: ChallanService,
    private excelService: VExcelService
  ) {
    this.loadPartyName();
    this.loadVehicleName();
    this.loadProductName();

    this.startfDate.setDate(this.valueDate.getDate() - 1);
    this.endfDate.setDate(this.valueDate.getDate());
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 15000);
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit(): void {
    this.vReportForm = this.rf.group({
      partyList: ['0', Validators.required],
      vehicleList: ['0', Validators.required],
      productList: ['0', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required]
    })

    this.vReportForm.controls.startDate.patchValue(this.formatDate(this.startfDate));
    this.vReportForm.controls.endDate.patchValue(this.formatDate(this.endfDate));


    this.columns = ['s_no', 'Date', 'C.N.', 'Vehicle', 'Qty(CFT)', 'Ravana No', 'Weight (MT)', 'Total Amount'];
    this.excelFieldNames = ['s_no', 'Date', 'C.N.', 'Vehicle', 'Qty(CFT)', 'Ravana No', 'Weight (MT)', 'Total Amount'];
  }

  //loading party names in partyList dropdown on component loading
  loadPartyName() {
    this.ledgerName.getPartyName().subscribe((data: any) => {
      this.partyNameList = data;
    })
  }

  //loading vehicle names in vehicleList dropdown on component loading
  loadVehicleName() {
    this.ledgerName.getVehicleName().subscribe((data: any) => {
      this.vehicleNameList = data;
    })
  }

  //loading product names in productList dropdown on component loading
  loadProductName() {
    this.productName.getProductName().subscribe((data) => {
      this.productNameList = data;
    })
  }

  //format Date function
  private formatDate(findDate: Date) {
    const d = new Date(findDate);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  //Export to Excel Function
  exportToExcel() {
    this.vReportForm.patchValue({
      startDate: this.formatDate(this.vReportForm.get('startDate').value),
      endDate: this.formatDate(this.vReportForm.get('endDate').value)
    })
    this.excelService.exportAsExcelFile('::SHRI::', 'ALANKAR UDHYOG', this.excelFieldNames, this.myObject2, this.footerData, 'AU', 'Sheet1');
  }

  fakeReportData(): VehReportChallan {
    return {
      serial_no: 0,
      c_date: '',
      c_voucher_no: '',
      ledger_name: '',
      c_qty: 0,
      c_ravana_no: 0,
      c_weight: 0,
      c_total_amt: 0,
    }
  }

  frd = this.fakeReportData()

  rd: ReportData = {
    partyId: 0,
    vehicleId: 0,
    productId: 0,
    userId: 0,
    startDate: "",
    endDate: ""
  };

  showReport() {
    this.viewChallanService.getChallanVehReport(this.jaRahaHuTableKePaas()).subscribe(result => {
      this.collection = result
      this.collection2 = this.collection.map((value, index) => {
        value.serial_no = index + 1
        return value
      })

      if (this.myObject2.length !== 0) {
        this.myObject2 = [];
      }

      for (let i = 0; i < this.collection2.length; i++) {
        let element = this.collection2[i];
        this.frd.serial_no = element.serial_no
        this.frd.c_date = element.c_date
        this.frd.c_voucher_no = element.c_voucher_no
        this.frd.ledger_name = element.ledger.ledger_name
        this.frd.c_qty = Number(element.c_qty)
        this.frd.c_ravana_no = element.c_ravana_no
        this.frd.c_weight = Number(element.c_weight)
        this.frd.c_total_amt = Number(element.c_total_amt)
        this.myObject2.push(Object.assign({}, this.frd));
      }
      this.collection2 = [];

      if (this.totalWeight !== 0 || this.totalTotalAmt !== 0 || this.totalQty !== 0) {
        this.totalWeight = 0;
        this.totalTotalAmt = 0; 
        this.totalQty = 0;
      }

      for (let i = 0; i < this.collection.length; i++) {
        this.totalWeight += Number(this.collection[i].c_weight)
        this.totalTotalAmt += Number(this.collection[i].c_total_amt)
        this.totalQty += Number(this.collection[i].c_qty)
      }
      this.footerData.length = 0;
      this.footerData.push(['Total', '', '', '', this.totalQty, '', this.totalWeight, this.totalTotalAmt]);
      // this.myObject2 = []
    })
  }

  jaRahaHuTableKePaas() {
    this.rd.partyId = Number(this.vReportForm.controls.partyList.value);
    this.rd.vehicleId = Number(this.vReportForm.controls.vehicleList.value);
    this.rd.productId = Number(this.vReportForm.controls.productList.value);
    this.rd.startDate = this.formatDate(this.vReportForm.controls.startDate.value);
    this.rd.endDate = this.formatDate(this.vReportForm.controls.endDate.value);
    return this.rd;
  }

  get partyList() { return this.vReportForm.get('partyList') };
  get vehicleList() { return this.vReportForm.get('vehicleList') };
  get productList() { return this.vReportForm.get('productList') };
  get startDate() { return this.vReportForm.get('startDate') };
  get endDate() { return this.vReportForm.get('endDate') };

}

