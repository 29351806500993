import { SettingService } from '../services/setting.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services';


@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  alert: boolean = false
  setting: FormGroup;


  constructor(
    private fb: FormBuilder,
    private settingService: SettingService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    this.setting = this.fb.group({
      old_password: ['', Validators.required],
      new_password: ['', Validators.required],
      repeat_password: ['', Validators.required],
    });
  }

  getValues() { 
    this.authService.changePassword(this.setting.value).subscribe(result => {
      console.log(result);
      if (result['status'] === 200) {
        alert(result['message']);
        this.router.navigateByUrl('login');
      }
    })
  }

  closeAlert() {
    this.alert = false;
  }


  //for vaildation
  get old_password() { return this.setting.get('old_password') };
  get new_password() { return this.setting.get('new_password') };
  get repeat_password() { return this.setting.get('repeat_password') };

}
