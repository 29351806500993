import { Pipe, PipeTransform } from '@angular/core';
import { ChallanDetails } from 'src/models/challanDetails';


@Pipe({
  name: 'searchfilter'
})
export class SearchfilterPipe implements PipeTransform {

  transform(challanD: ChallanDetails[], searchValue: string): ChallanDetails[] {

    if (!challanD || !searchValue) {
      return challanD;
    }
    return challanD.filter(challanDetails => 
      challanDetails.c_voucher_no.toLocaleLowerCase().includes(searchValue.toLocaleUpperCase()));
  }

}
