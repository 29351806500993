import { PrintService } from '../../services/print.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { ChallanService } from 'src/app/services/challan.service';




@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent {
  message: any[] = [];
  subscription: Subscription;

  challanId: number;
  dataForPrint: Array<any> = [];
  collection: any;

  c_voucher_no: string = '';
  date: string = '';
  time: string = '';
  partyName: string = '';
  partyNameHindi: string = '';
  partyAddress: string = '';
  partyAddressHindi: string = '';
  unit: string = '';
  qty: number = 0;
  due: number = 0.00;
  amt: number = 0.00;
  vehicleNumber: string = '';
  driverName: string = '';
  productName: string = '';
  c_moblie: string ='';

  constructor(private router: Router, private challanService: ChallanService) { }

  printcomponent() {

    let printContents = document.getElementById("component1").innerHTML;
    //let originalContents = document.body.innerHTML;
    //document.body.innerHTML = printContents;
    window.document.write(printContents);
    window.print()
    //document.body.innerHTML = originalContents;
    //window.focus();
    window.close();
    this.router.navigateByUrl('/dashboard/viewChallan')
    localStorage.removeItem('challan_id');
    // window.location.reload();
    // WindowPrt.document.write(printContents);
    // // WindowPrt.document.close();
    // //WindowPrt.focus();
    // WindowPrt.print();
    // WindowPrt.close();
    //
  }
  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    this.printData();
    const source = interval(1000);
    // this.subscription = source.subscribe(val => this.printcomponent());

  }

  ngOnDestroy() {
    // For method 1
    this.subscription && this.subscription.unsubscribe();
  }

  printData() {
    this.challanId = this.challanService.cid;
    console.log(this.challanId)
    this.challanService.getCurrentanotherChallan().subscribe(result => {
      console.log(result)
      this.collection = result;
      if (this.collection !== undefined || this.collection !== null) {

        this.c_voucher_no = this.collection.c_voucher_no;
        this.date = this.collection.c_date;
        this.time = this.collection.c_time;
        this.qty = this.collection.c_qty;
        this.due = this.collection.c_balance_amt;
        this.amt = this.collection.c_total_amt;
        this.unit = this.collection.company !== null ? this.collection.company.com_name : '';
        this.vehicleNumber = this.collection.ledger.ledger_name;
        this.driverName = this.collection.ledger.ledger_drivername;
        this.productName = this.collection.product.p_name;
        this.partyName = this.collection.c_partyname;
        this.partyAddress = this.collection.c_partyaddress;
        this.c_moblie = this.collection.c_partymobile;
      }
    }, error=> {
      console.log(error);
    })
  }

}
