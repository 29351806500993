import { Component, OnInit } from '@angular/core';
import { ChallanService } from 'src/app/services/challan.service';
import { VReportComponent } from '../v-report/v-report.component';
import { ReportData } from 'src/models/reportData';

@Component({
  selector: 'app-vtable',
  templateUrl: './vtable.component.html',
  styleUrls: ['./vtable.component.css']
})
export class VtableComponent implements OnInit {
  collection: any = [];
  c_voucher_no: any;
  com_name: any;
  c_partyname: any;
  ledger_name: any;
  p_name: any;
  user_company_address: any;
  c_payment_type: any;
  c_date: any;
  c_time: any;
  p: number = 1;

  constructor(
    private viewChallanService: ChallanService,
    private vReportComponent: VReportComponent
  ) { }

  rd: ReportData;


  ngOnInit(): void {
    this.viewChallanService.getChallan().subscribe((result) => {
      console.warn(result);
      this.collection = result;
    })
    // this.rd = this.mReportComponent.jaRahaHuTableKePaas();
    // console.log(this.rd.partyId)
    // this.viewChallanService.getChallanReport(this.rd).subscribe(result => {
    //   console.log(result)
    // })

  }

}
