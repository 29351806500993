import { Component, OnInit } from '@angular/core';
import { ReceiptService } from 'src/app/services/receipt.service';
import { HeaderComponent } from 'src/app/dashboard/header/header.component';
import { UpdateReceiptComponent } from '../update-receipt/update-receipt.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-receipt',
  templateUrl: './view-receipt.component.html',
  styleUrls: ['./view-receipt.component.css']
})
export class ViewReceiptComponent implements OnInit {

  collection:any = [];
  count = 0;

  constructor(
    private viewReceiptService:ReceiptService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
    this.getReceiptData();
  }

  getReceiptData() {
    this.viewReceiptService.getReceipt().subscribe((result)=> {
      // console.warn(result);
      this.collection = result
    })
  }

  public lcUpdateReceipt(item){
    this.viewReceiptService.rid= item;
    this.router.navigateByUrl('/dashboard/updateReceipt');    
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  // fake data for temporary deletion
  fakeData = {
    r_status: 0,
  }


  // deleting a Receipt
  deleteReceipt(rid) {
    if(confirm("Are you sure to delete ")){
      this.viewReceiptService.doDeleteReceipt(rid, this.fakeData).subscribe(result => {
        if (result['status']=== 200) {
          this.showSuccess(result['message']);
        }
      }, error => {
        if (error.status === 500) {
          this.showError(error.error.message);
          window.location.reload(); 
        }
        if (error.status === 400) {
          this.showError(error.error);
          window.location.reload(); 
        }
      })
      this.getReceiptData();
    }
    
  }

}
