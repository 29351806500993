import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UnitsService {

  tid:any;

  // url = "http://15.206.21.1:3000/companies"
  // rooturl = "http://15.206.21.1:3000"
  rooturl = environment.rooturl;
  url = `${this.rooturl}/companies`;

  constructor(private http:HttpClient) { }

  saveUnits(data)
  {
    // console.warn(data)
    return this.http.post(this.url+'/add',data)  
  }

  public getUnits()
  {
    // console.warn(this.http.get(this.url))
    return this.http.get(this.url)  
  }

  getStatesName():Observable<any[]> {
    return this.http.get<any>(this.rooturl + '/states');
  }

  getUnitName():Observable<any[]> {
    return this.http.get<any>(this.url + '/unit-name');
  }

  getUnitById(id):Observable<any[]> {
    return this.http.get<any>(this.url + `/${id}`);
  }

  getUnitNameAddress():Observable<any[]> {
    return this.http.get<any>(this.url + '/unit-name-address');
  }

  getUnitIdByName(data):Observable<any[]> {
    let params = new HttpParams();
    params = params.append('comname', data);
    return this.http.get<any>(this.url + `/unit-id-byname`, {params: params});
  }

  getCurrentUnit():Observable<any[]> {
    return this.http.get<any>(`${this.url}/update/${this.tid}`)
  }

  updateUnits(id, data)
  {
    return this.http.patch(`${this.url}/${id}`, data)
  }

  destroyUnits(id, data) {
    return this.http.patch(`${this.url}/delete/${id}`, data);
  }
}
