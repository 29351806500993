<div class="container">
    <h1 class="mat-h1">Dashboard</h1>
    <p class="mat-caption">Welcome {{userName}}</p>
    <!-- <div class="cOption"> -->
    <form [formGroup]="showChallan" class="form-group col-xs-2 float-right">
        <!-- <label for="exampleFormControlSelect1">Example select</label> -->
        <select class="form-control" id="showChallanAmount" formControlName = "selectChallan">
          <!-- <option value="0">Show</option> -->
          <option value="1">All</option>
          <option value="2">Today</option>
        </select>
    </form>
      <!-- </div> -->
    <div>
        <hr>
    </div>

    <div class="d-inline-flex flex-row justify-content-center">
        <div class="row">
            <div class="col-md-6">
                <mat-card class="db-card">
                    <mat-card-header>
                        <mat-card-title>
                            <p>Total Challan</p> 
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="db-card-content">
                        <a><img src="./assets/icons/challan_icon1.png" alt="Add Challan"></a>
                    </mat-card-content>
                    <mat-card-content class="db-card-footer">
                        {{totalChallan}}
                    </mat-card-content>
                </mat-card>
            </div>
            <mat-divider></mat-divider>
            <div class="col-md-6">
                <mat-card class="db-card">
                    <mat-card-header>
                        <mat-card-title>
                            <p>Total Cash</p>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="db-card-content">
                        <a><img src="./assets/icons/challan_icon1.png" alt="Add Challan"></a>
                    </mat-card-content>
                    <mat-card-content class="db-card-footer">
                        {{totalCash}}
                    </mat-card-content>
                </mat-card>
            </div>
            <mat-divider></mat-divider>
            <div class="col-md-6">
                <mat-card class="db-card">
                    <mat-card-header>
                        <mat-card-title>
                            <p>Total Credit</p>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="db-card-content">
                        <a><img src="./assets/icons/challan_icon1.png" alt="Add Challan"></a>
                    </mat-card-content>
                    <mat-card-content class="db-card-footer">
                        {{totalCredit}}
                    </mat-card-content>
                </mat-card>
            </div>
            <mat-divider></mat-divider>
            <div class="col-md-6">
                <mat-card class="db-card">
                    <mat-card-header>
                        <mat-card-title>
                            <p>Total Due/Adv.</p>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="db-card-content">
                        <a><img src="./assets/icons/challan_icon1.png" alt="Add Challan"></a>
                    </mat-card-content>
                    <mat-card-content class="db-card-footer">
                        {{totalDueAdv}}
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
            
    </div>