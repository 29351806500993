import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { HeaderComponent } from '../../dashboard/header/header.component';
import { UpdateProductComponent } from '../update-product/update-product.component'
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit {

  showNProduct: FormGroup;
  searchProductCodeForm: FormGroup;
  searchProductForm: FormGroup;
  collection: any = [];
  pageNo:number = 0;
  pageSize:number = 25;
  count:number = 0
  productNameList: any = [];
  
  pageData = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }

  constructor(
    private viewProductService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private fb : FormBuilder,
    private toastr: ToastrService) { 
      this.loadProductName();
    }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
    this.showNProduct = this.fb.group({
      selectNProduct : ['1']
    })
    this.searchProductCodeForm = this.fb.group({
      productCodeList : ['0']
    })
    this.searchProductForm = this.fb.group({
      productList : ['0']
    })
    this.getProductData();
  }

  //loading product names in productList dropdown on component loading
  loadProductName() {
    this.viewProductService.getProductName().subscribe((data) => {
      this.productNameList = data;
    })
  }

  getProductData() {
    this.viewProductService.getPaginatedProduct(this.pageData).subscribe((result) => {
      this.count = result['count']
      this.collection = result['rows'];
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
        this.router.navigateByUrl('/dashboard/addProduct');
      }
    })
  }

  public lcUpdateProduct(item) {
    this.viewProductService.pid = item;
    this.router.navigateByUrl('/dashboard/updateProduct');
  }

  //onClick pagination function
  handlePageChange(event) {
    console.log(event)
    this.pageNo = event;
    this.pageData.pageNo = this.pageNo-1;
    this.getProductData();
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  // fake data for temporary deletion
  fakeData = {
    p_status: 0,
    p_is_deleted: 1
  }
    
  // deleting a Product
  deleteProduct(pid) {
    if(confirm("Are you sure to delete ")){
      this.viewProductService.doDeleteProduct(pid, this.fakeData).subscribe(result => {
        if (result['status']=== 200) {
          this.showSuccess(result['message']);
        }
      }, error => {
        if (error.status === 500) {
          this.showError(error.error.message);
          window.location.reload(); 
        }
        if (error.status === 400) {
          this.showError(error.error);
          window.location.reload(); 
        }
      })
      this.getProductData();
    }
    
  }

}
