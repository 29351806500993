


<div class="app-container" style="border:1px solid black; border-radius: 5px;background-image: url(./assets/indexbg.jpg);">
<mat-toolbar id="au-navbar">
    <span>
        <h1 style="padding-left: 10px;"><img src="./assets/logo.png"></h1>
          <!-- <img src="/assets/logo.png"> -->
    </span>
  </mat-toolbar>
  <div *ngIf="error" class="alert alert-danger">{{error}}</div>
    <div class="row">
    <div class="col-12">
    <form  [formGroup]="loginForm"  (ngSubmit)="onSubmit()">
          <div id="form_title">
                <h3>USER LOGIN</h3>
          </div>
          <div class="form-group">
              <input type="text" formControlName="username" placeholder="Login ID" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.user_name.errors }" />
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">Username is required</div>
              </div>
          </div>
            
          <div class="form-group">
              <input type="password" formControlName="password" placeholder="Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.user_password.errors }" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
              </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                  <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox"  formControlName="rememberMe" id="rememberMe" />
                        <label class="form-check-label" id="remember" for="rememberMe">Remember Me</label>
                  </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" id="btn" >
                <button [disabled]="loading" class="btn btn-danger">Log In</button>
              </div>
            </div>
          </div>
         

    </form>
</div>
</div>
</div>
