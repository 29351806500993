<html>

<body>
  <button (click)="printcomponent()">Print</button><br><br>
  <div id="component1">

    <div class="container" style="top:0; left:0; max-height:100vh; width:auto">
      <div style="width: 150mm; float:left; font-weight: bold; ">
        <div style="width: 100%; float: left;padding-left: 11px; padding-right: 11px;">
          <div style="width: 100%; border:1px solid #000; float:left; line-height: 17px;">
            <div style="padding: 3px 3px 0 3px; width:35%; float: left;font-size: 14px">GSTIN: 08AAMPJ3622P1Z9</div>
            <div style="padding: 3px 3px 0 3px;width:30%; float: left;font-size: 14px;text-align: center; ">
              ||<img id="img1" style="width:20px; height:17px; margin:0 1px 0 1px;" src="/assets/shree.png">|| <br>
              <div style="font-size:10px;padding-top:5px; font-weight: bold; text-decoration: underline;">DELIVERY
                CHALLAN
              </div>
            </div>

            <div style="padding: 3px 3px 0 3px;  float: right;font-size: 14px; text-align: justify;">
              <div style=" float:left;"><img src="assets/phone.png" style="width:15px; height:35px;">
              </div>
              <div style=" margin-left:5px; float: left;"> 081048-73800 <br>0291-2740093</div>
            </div>

            <div style="padding: 7px 3px 5px 3px; width:100%; float: left;font-size: 21px; text-align: center;">ALANKAR
              UDYOG</div>
            <div
              style="padding: 5px 3px 5px 3px; width:100%; float: left;font-size: 15px;border-bottom: 1px solid #000;">
              <div style="width: 48%; float: left;">H.O. : OPP AIIMS, BASNI,JODHPUR</div>
              <div style="width: 3%; float: left; text-align: right;"><img style="width:15px; height:15px;"
                  src="/assets/dot.png"> </div>
              <div style="width: 47%; float: right;">B.O.: SAWARI BHAKARI,JODHPUR</div>
            </div>

            <div style=" width:100%; float: left;line-height: 30px; font-size: 16px">
              <div style="width:50%; float: left;border-right: 1px solid #000;">
                <div style="padding: 3px 0 0 4px; width:98%; float: left;border-bottom: 1px solid #000;">No. :
                  {{c_voucher_no}} </div>
                <div style="padding: 3px 0 0 4px; width:98%; float: left;border-bottom: 1px solid #000;">Date :
                  {{date}}</div>
                <div style="padding: 3px 0 0 4px; width:98%; float: left;">Time : {{time}}</div>
              </div>

              <div style="padding: 3px 3px 0 3px; width:45%;  float: left;">
                <div style="padding: 3px 3px 0 4px; width:100%; float: left;"> Total Amount : {{ amt }}</div>
                <div style="padding: 3px 3px 0 4px; width:100%; float: left;"> Total Due : {{ due }}</div>
              </div>

              <div style=" width:100%; float: left;border-top: 1px solid #000;line-height: 35px;">
                <div style="width:95%; float: left;font-size: 15px; text-align: right; padding-right: 20px;">
                  UNIT : {{unit}}
                </div>
              </div>

              <div style=" width:100%; float: left;border-top: 1px solid #000;line-height: 35px;font-size: 18px">
                <div style="width:100%; float: left; padding: 0px 7px; text-align: center;"> {{partyName}} </div>
              </div>
              <div style=" width:100%; float: left;border-top: 1px solid #000;line-height: 35px;font-size: 18px">
                <div style="width:100%; float: left; padding: 0px 7px; text-align: center;">
                  {{partyAddress}}
                </div>
              </div>

              <div style=" width:100%; float: left;border-top: 1px solid #000;line-height: 35px;font-size: 18px">
                <div style="width:100%; float: left; padding: 0px 7px; text-align: center;">&nbsp;
                  Mo. {{c_moblie}}
                </div>
              </div>

              <div style=" width:100%; float: left;border-top: 1px solid #000;line-height: 35px;font-size: 18px">
                <div style="width:100%; float: left; padding: 0px 7px; text-align: center;">&nbsp;

                </div>
              </div>

              <!-- <div style=" width:100%; float: left;border-top: 1px solid #000;line-height: 35px;font-size: 18px">
                <div style="width:100%; float: left; padding: 0px 7px; text-align: center;">&nbsp;

                </div>
              </div> -->

              <div style=" width:100%; float: left;border-top: 1px solid #000;line-height: 35px;font-size: 14px">
                <div style="width:100%; float: left;">
                </div>
              </div>
              <div style=" width:100%; border-top: 1px solid #000;font-size: 16px">
                <div style="width: 49%; float:left; border-right: 1px solid #000;">
                  <div style="width: 100%; border-bottom: 1px solid #000;">
                    Qty.(Cft)/Ton <span style="margin-left: 30%; font-size: 18px;">{{qty}}</span>
                  </div>
                  <div style="width: 100%;  border-bottom: 1px solid #000;">
                    VEN. NO. <span style="margin-left: 30%; font-size: 18px;">{{vehicleNumber}}</span>
                  </div>
                </div>
                <div style="width: 50%; float: right;">
                  <div style="width:100%; border-bottom: 1px solid #000;">
                    SIZE <span style="margin-left: 30%; font-size: 18px;">{{productName}}</span>
                  </div>
                  <div style="width:100%; border-bottom: 1px solid #000;">
                    DRV <span style="margin-left: 30%; font-size: 18px;">{{driverName}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style=" width:100%; float: left;border-top: 1px solid #000;line-height: 20px;">
              <div style="width:100%; float: left;font-size: 14px;padding: 0px 4px 0px 4px;">
                <div style="width: 33%;float: left; text-align: center;font-size: 14px">
                  BANK DETAILS</div>

                <div style="width: 33%;float: left;text-align: center;font-size: 14px">
                  QR</div>
                <div style="width: 33%;float: left;text-align: center;font-size: 14px">UPI ID/Number</div>
              </div>
            </div>
            <div style=" width:100%; float: left;border-top: 1px solid #000;line-height: 30px;">
              <div style="width:100%; float: left;font-size: 14px;padding: 0px 4px 0px 0px;">
                <div style="width: 39%;float: left; text-align: center;border-right: 1px solid #000;">
                  <h6 style="margin:5px 0px 0px 0px;  font-weight: 600; font-size: 13px; line-height: 14px;">UCO BANK, MIA, BASNI, JODHPUR</h6>
                  <h6 style="margin:0px 0px 0px 0px; font-weight: 600; font-size: 13px; line-height: 14px;">A/C No. : 10940200000387</h6>
                  <h6 style="margin:0px 0px 5px 0px; font-weight: 600; font-size: 13px; line-height: 14px;">IFSC : UCBA0001094</h6>
                  <div
                    style="width: 100%;float: left; text-align: center;border-top: 1px solid #000;padding-top: 18px;">
                    <div style="width: 100%;float: left; text-align: center;font-size: 14px">
                      Receiver Signature</div>
                  </div>
                </div>

                <div style="width: 20%;float: left;text-align: center;font-size: 14px;">
                  <img style="width:100px; height:100px; " src="/assets/qr_code.jpeg">
                </div>
                <div style="width: 39%;float: right;text-align: center;border-left: 1px solid #000;">
                  <h6 style="margin:5px 0px 0px 0px;font-weight: 600; font-size: 13px;line-height: 15px;">UPI : 9414127711@hdfc</h6>
                  <h6 style="margin:0px 0px 0px 0px;font-weight: 600; font-size: 13px;line-height: 15px;">Number : 9928891188</h6>
                  <h6 style="margin:0px 0px 5px 0px;font-weight: 600; font-size: 13px;line-height: 15px;">GST 5% EXTRA</h6>
                  <div
                    style="width: 100%;float: left; text-align: center;border-top: 1px solid #000;padding-top: 18px;">
                    <div style="width: 100%;float: left; text-align: center;font-size: 14px">
                      Signature</div>
                  </div>
                </div>
              </div>
            </div>
            <div style=" width:100%; float: left;border-top: 1px solid #000;line-height: 20px;">
              <div style="width:100%; float: left;font-size: 14px; padding: 5px 4px 5px 4px;">
                <div style="width: 33%;float: left; text-align: center;font-size: 16px">
                  पूरा माल</div>

                <div style="width: 33%;float: left;text-align: center;font-size: 16px">
                  FIXED RATE</div>
                <div style="width: 33%;float: left;text-align: center;font-size: 16px">समय पर माल</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>