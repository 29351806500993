import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LedgerService } from 'src/app/services/ledger.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-ledger',
  templateUrl: './update-ledger.component.html',
  styleUrls: ['./update-ledger.component.css']
})
export class UpdateLedgerComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;

  updateLedger: FormGroup;
  show:boolean = false;
  showl:boolean = false;

  alert: boolean = false
  id: any;
  imgURL: any;

  constructor(
    private ledgerService: LedgerService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService
  ) { }
  
  ngOnInit(): void {
    // console.warn(this.router.snapshot.params.id)
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
    this.updateLedger = this.fb.group({
      add_photo: [''],
      ledger_name: ['', Validators.required],
      ledger_type: ['', Validators.required],
      address_1: [''],
      address_2: [''],
      address_3: [''],
      address_4: [''],
      driver_name: [''],
      mobile: ['', [Validators.pattern('[0-9]*')]],
      mobile2: ['', [Validators.pattern('[0-9]*')]],
      mobile3: ['', [Validators.pattern('[0-9]*')]],
      mobile4: ['', [Validators.pattern('[0-9]*')]],
      gstin: [''],
    })
    this.ledgerService.getCurrentLedger().subscribe((result) => {
      if(result !== null) {
        this.updateLedger.setValue({
          add_photo: result['ledger_image'],
          ledger_name: result['ledger_name'],
          ledger_type: result['ledger_ledgertype_id'],
          address_1: result['ledger_address_line1'],
          address_2: result['ledger_address_line2'],
          address_3: result['ledger_address_line3'],
          address_4: result['ledger_address_line4'],
          driver_name: result['ledger_drivername'],
          mobile: result['ledger_mobile'],
          mobile2: result['ledger_mobile2'],
          mobile3: result['ledger_mobile3'],
          mobile4: result['ledger_mobile4'],
          gstin: result['ledger_gstin']
        })
      }
    })
    this.id = this.ledgerService.lid;

    this.updateLedger.controls.ledger_type.valueChanges.subscribe(result=>{
      if (this.updateLedger.controls.ledger_type.value == 2){
        this.show = true;
        this.showl = false;
      } else {
        this.show = false;
        this.showl = true;
      }
    })
  }

  // toastr code for notifications
  showSuccess(messageNotify: string) {
    this.toastr.success('Success!', messageNotify);
  }
  showError(messageNotify: string) {
    this.toastr.error('Error!', messageNotify);
  }
  //end of toastr code for notifications

  //for image setting
  preview(event) {
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      // reader.readAsDataURL(file);
      // reader.onload = () => {
      //   this.addLedger.get('add_photo').setValue()
      // };
      console.log(file)
      this.updateLedger.get('add_photo').setValue(file)
    }
  }


  getValues() {
    // console.warn("item", this.updateProduct.value)
    this.ledgerService.updateLedger(this.id, this.updateLedger.value).subscribe((result) => {
      // this.alert = true
      if (result['status'] === 200) {
        this.showSuccess(result['message']);
      }
      this.updateLedger.reset({})
      this.router.navigateByUrl('/dashboard/viewLedger');
    }, error => {
      if (error.status === 500) {
        this.showError(error.error.message);
      }
      if (error.status === 400) {
        this.showError(error.error);
        this.router.navigateByUrl('/dashboard/viewLedger');
      }
    })
  }

  closeAlert() {
    this.alert = false
  }

  //for vaildators
  // get add_photo() { return this.updateLedger.get('add_photo') };
  get ledger_name() { return this.updateLedger.get('ledger_name') };
  get ledger_type() { return this.updateLedger.get('ledger_type') };
  // get address_1() { return this.updateLedger.get('address_1') };
  // get address_2() { return this.updateLedger.get('address_2') };
  // get driver_name() { return this.updateLedger.get('driver_name') };
  get mobile() { return this.updateLedger.get('mobile') };
  // get gstin() { return this.updateLedger.get('gstin') };

}
